export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isValidURL = (u) => {
  var res = u.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Gets the file extension from a url
 * https://stackoverflow.com/questions/6997262/how-to-pull-url-file-extension-out-of-url-string-using-javascript/20865806
 * @param {*} url
 * @returns
 */
export const getUrlExtension = (url) => {
  try {
    return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1];
  } catch (ignored) {
    return false;
  }
};

/**
 * Converts the value to a social string (1,123 = > 1.23k, etc)
 * @param {*} value The value to convert
 * @param {*} extensionOnly True if just want extension (k instead of 1.23k)
 * @param {*} numberOnly True if want just number (1234 instead of 1.2k)
 */
export function toSocialString(
  value,
  extensionOnly = false,
  numberOnly = false
) {
  let number = Number(value);
  let thousands = number / 1000;
  if (thousands < 1) {
    return numberOnly ? number : extensionOnly ? "" : String(number);
  } else if (thousands < 1000) {
    return numberOnly
      ? round(thousands, 0)
      : extensionOnly
      ? "k"
      : String(round(thousands, 0)) + "k";
  } else {
    thousands = thousands / 1000;
    return numberOnly
      ? thousands
      : extensionOnly
      ? "m"
      : parseInt(String(thousands), 10) + "m";
  }
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function fullName(first, last) {
  return `${first}${last && last.length > 0 ? " " : ""}${last}`;
}

export function firstLastFromFull(fullName) {
  const parts = fullName.split(" ");
  const first = parts[0];
  let last = parts;
  last.splice(0, 1);
  return { first: capitalize(first), last: capitalize(last.join(" ")) };
}

export function convertDurationtoSeconds(duration) {
  if (!duration) return 0;
  const [hours, minutes, seconds] = duration.split(":");
  return parseInt(
    Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds),
    10
  );
}
