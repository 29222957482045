/**
 * Main APP
 */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MomentUtils from "@date-io/moment";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// Firebase
import "./firebase";

// Hulk CSS
import "./lib/hulkCss";
// App Conatiner
import App from "./container/App";

// Store
import { store } from "./store";

function MainApp() {
  // console.log("🚨 rendering MainApp ");
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default MainApp;
