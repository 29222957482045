/*
 * App Routes
 *
 */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Box } from "@mui/material";

// redux action
import { hulkLogoutUserFirebase } from "actions";

class Logout extends Component {
  state = {
    isLoggedOut: false,
  };

  componentDidMount() {
    this.logoutUser();
  }

  /*
   * Logout User
   */
  logoutUser = () => {
    this.props.hulkLogoutUserFirebase();
    this.setState({
      isLoggedOut: true,
    });
  };

  render() {
    const { isLoggedOut } = this.state;

    return <Box>{isLoggedOut ? <Route path={`/`} /> : null}</Box>;
  }
}

const mapStateToProps = ({ settings, authUser }) => {
  const { firstName, lastName, email } = authUser;
  return { settings, firstName, lastName, email };
};

export default withRouter(
  connect(mapStateToProps, {
    hulkLogoutUserFirebase,
  })(Logout)
);
