/**
 * Superpost sound meta data model for the database
 * Represents a sound that can be used in a superpost such as a trending tiktok/reels/shorts sound
 */
export class SuperpostSoundMetaData {
  id: string = "";
  platform: string = "";
  title: string = "";
  author: string = "";
  detail?: string;
  url: string = "";
  image_url?: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    const { id, detail, author, title, url, platform, image_url } = obj;
    this.id = id;
    this.title = title;
    this.url = url;
    this.platform = platform;
    this.image_url = image_url;
    this.author = author;

    //construct detail from the provided data
    this.detail = detail;
  }
}
