import { ContentAsset } from ".";

export class AvatarGeneration {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   *
   */
  avatar_id: string;
  /**
   * The profile of the avatar (ours / supercreator / kre8.tv)
   */
  avatar_profile_id: string;
  /**
   * The user that uploaded it
   */
  user_id?: string;
  /**
   * URL to download the generation
   */
  url: string;
  /**
   * The size of this file in bytes
   */
  size?: string;
  /**
   * Duration of the generation
   */
  duration?: string;
  /**
   * By default, this is almost always mp4.
   */
  type: string;

  /**
   * gif | image | video | audio
   */
  layer: ContentLayerType;

  /**
   * Describes the processing status of the video.
   */
  status: AvatarGenerationStatus;

  /**
   * The script id this is related to
   */
  script_id: string;

  /**
   * The index of the sene
   */
  scene_index?: number;

  /**
   * The scene id this is related to
   */
  scene_id: string;

  /**
   * Last updated date
   */
  last_updated: number;
  /**
   *
   */
  last_updated_by_user_id: string;
  /**
   * Created on date
   */
  created_on: number;

  /**
   * Metadata object with additional params
   */
  metadata: {};

  /**
   * Is this generation deleted
   */
  is_deleted = false;

  /**
   * Foreground preview url - should be a photo of the avatar
   */
  foreground_preview_url?: string;

  /**
   * background preview id - if exists, should be an id leading to the background asset
   */
  background_asset_id?: string;

  /**
   * The preview text for the scene (usually the text on screen attribute of the script scene object)
   */
  preview_text?: string;

  //@local only
  background_asset?: ContentAsset;

  static fromJSON(obj?: any) {
    if (!obj) {
      return;
    }

    const id = obj.id;
    const user_id = obj.user_id;
    const avatar_id = obj.avatar_id;
    const avatar_profile_id = obj.avatar_profile_id;
    const scene_id = obj.scene_id;
    const script_id = obj.script_id;
    const status = obj.status;
    const duration = obj.duration;
    const type = obj.type;
    const url = obj.url;
    const size = obj.size;
    const layer = obj.layer;
    const metadata = obj.metadata;

    const now = new Date().getTime();
    const created_on = obj.created_on ?? now;
    const last_updated = obj.last_updated ?? now;
    const last_updated_by_user_id = obj.user_id;

    const foreground_preview_url = obj.foreground_preview_url;
    const background_asset_id = obj.background_asset_id;
    const preview_text = obj.preview_text;
    const scene_index = obj.scene_index;

    let generation = new AvatarGeneration(
      id,
      user_id,
      avatar_id,
      avatar_profile_id,
      scene_id,
      script_id,
      status,
      duration,
      last_updated,
      last_updated_by_user_id,
      created_on,
      type,
      url,
      size,
      layer,
      metadata,
      foreground_preview_url,
      background_asset_id,
      preview_text,
      scene_index
    );
    return generation;
  }

  constructor(
    id: string,
    user_id: string,
    avatar_id: string,
    avatar_profile_id: string,
    scene_id: string,
    script_id: string,
    status: AvatarGenerationStatus,
    duration: string,
    last_updated: number,
    last_updated_by_user_id: string,
    created_on: number,
    type: string,
    url: string,
    size: string,
    layer: ContentLayerType,
    metadata?: any,
    foreground_preview_url?: string,
    background_asset_id?: string,
    preview_text?: string,
    scene_index?: number
  ) {
    this.id = id;
    this.user_id = user_id;
    this.avatar_id = avatar_id;
    this.avatar_profile_id = avatar_profile_id;
    this.scene_id = scene_id;
    this.script_id = script_id;
    this.status = status;
    this.duration = duration;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;
    this.created_on = created_on;
    this.url = url;
    this.size = size;
    this.type = type;

    this.layer = layer;
    this.metadata = metadata;

    this.foreground_preview_url = foreground_preview_url;
    this.background_asset_id = background_asset_id;
    this.preview_text = preview_text;

    this.scene_index = scene_index;
  }

  videoFileName(): string | undefined {
    if (!this.url) return undefined;
    return `${this.script_id}_scene${
      this.scene_index !== undefined ? "_" + this.scene_index : ""
    }`;
  }

  assetName(): string | undefined {
    if (!this.background_asset) return undefined;
    return `${this.script_id}_scene_asset${
      this.scene_index !== undefined ? "_" + this.scene_index : ""
    }`;
  }
}
