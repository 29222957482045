import firebase from "firebase";
import { ContentAsset, ScriptScene } from "kre8tv/model";
import { store } from "store";
import { cleanObject } from "util/SCUtils";
import { DB_SCENE_ASSETS } from "./DBConstants";

/**
 * A class directly interfacing with the firebase database for content assets
 */
export class AssetManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  newDocumentID() {
    return this.firestore.collection(DB_SCENE_ASSETS).doc().id;
  }

  /**
   * Saves and updates the script
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async saveOrUpdateAsset(asset: ContentAsset) {
    //make sure to update properties
    const user_id = firebase.auth().currentUser?.uid;
    asset.last_updated = new Date().getTime();
    asset.user_id = user_id;

    let cleaned = cleanObject(asset);
    console.log("attempting save script asset - ", cleaned);
    let doc;

    //new scene, no existing id (add one)
    if (!asset.id) {
      //save
      doc = this.firestore.collection(DB_SCENE_ASSETS).doc();
      cleaned.id = doc.id;
      cleaned.created_on = new Date().getTime();
      cleaned.user_id = user_id;
      cleaned.last_updated_by_user_id = user_id;
    } else {
      //existing id - update the document
      doc = this.firestore.collection(DB_SCENE_ASSETS).doc(asset.id);
      cleaned.last_updated_by_user_id = user_id;
    }

    try {
      // delete cleaned.isSaving;
      const update = await doc.set({ ...cleaned }, { merge: true });
      console.log("save asset success - ", update);
      return asset;
    } catch (error) {
      console.error("save asset error - ", error);
      return false;
    }
  }

  /**
   * Fetch asset from database by id
   * @param assetId The asset id to fetch
   * @returns ContentAsset object if exists, null if not/error
   */
  async fetchAsset(assetId: string) {
    const db = this.firestore.collection(DB_SCENE_ASSETS);
    try {
      const get = await db.doc(assetId).get();
      const entity = get.exists ? get.data() : undefined;
      return ContentAsset.fromJSON(entity);
    } catch (error) {
      console.log("Error getting script: ", error);
      return null;
    }
  }

  async fetchAssetsForScene(sceneId: string) {
    const db = this.firestore.collection(DB_SCENE_ASSETS);

    try {
      const snapshot = await db.where("scene_id", "==", sceneId).get();
      let layers = new Map();
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        let asset = ContentAsset.fromJSON(doc.data());
        if (!asset) {
          return layers;
        }
        if (asset.layer === "audio") {
          layers.set("sound", asset);
        } else if (asset.layer === "foreground") {
          layers.set("foreground", asset);
        } else if (asset.layer === "background") {
          layers.set("background", asset);
        }
      });
      return layers;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  }

  async fetchUserUploadedAssets() {
    try {
      const db = this.firestore.collection(DB_SCENE_ASSETS);
      const userId = store.getState().authUser.user_id;
      if (!userId) {
        return null;
      }
      const snapshot = await db
        .where("user_id", "==", userId)
        .where("user_uploaded", "==", true)
        .get();
      let items: ContentAsset[] = [];
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        let asset = ContentAsset.fromJSON(doc.data());
        if (asset) {
          items.push(asset);
        }
      });
      // console.log("Fetching user uploaded items: ", items);
      return items;
    } catch (error) {
      console.error("Error Fetching user uploaded items: ", error);
      return null;
    }
  }
}
