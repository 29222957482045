import { IEntity } from "./interfaces/entity.interface";

export class SocialAccount implements IEntity {
  id: string = "";
  user_id: string = "";
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  platform: string = "";
  public_url?: string;

  username: string = "";
  display_name: string = "";
  profile_picture_url: string = "";

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    const {
      id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      user_id,
      platform,
      public_url,
      display_name,
      profile_picture_url,
      username,
    } = obj;

    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.user_id = user_id;

    this.platform = platform;
    this.public_url = public_url;
    this.display_name = display_name;
    this.profile_picture_url = profile_picture_url;
    this.username = username;
  }

  static iconNameFromPlatform(platform: string): string {
    switch (platform) {
      case "instagram":
        return "fab fa-instagram";
      case "twitter":
        return "fab fa-twitter-square";
      case "facebook":
        return "fab fa-facebook-square";
      case "youtube":
        return "fab fa-youtube";
      case "tiktok":
        return "fab fa-tiktok";
      default:
        return "";
    }
  }
}
