import { IEntity } from "./interfaces/entity.interface";

export class InspirationSourceItem implements IEntity {
  id: string = "";
  user_id: string = ""; //who added it
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  author_name: string = "";
  author_url?: string;
  source_name: string = "";
  source_url?: string;
  platform: string = "";
  removed = false;
  title: string = "";
  item_date: number = 0; //the actual date of the item (could be in past)

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    const {
      id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      user_id,
      source_name,
      source_url,
      platform,
      removed,
      title,
      author_name,
      author_url,
      item_date,
    } = obj;

    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.user_id = user_id;

    this.source_name = source_name;
    this.source_url = source_url;
    this.platform = platform;
    this.title = title;
    this.removed = removed ?? false;

    this.author_name = author_name;
    this.author_url = author_url;
    this.item_date = item_date;
  }

  createdOnString(): string {
    let date = new Date(this.item_date);
    return `${date.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} ${date.toLocaleTimeString("en-us", {
      timeStyle: "medium",
    })}`;
  }

  authorString(): string {
    return `${this.usernamePrefix()}${this.author_name}`;
  }

  usernamePrefix(): string {
    const prefix =
      this.platform === "instagram" ||
      this.platform === "twitter" ||
      this.platform === "tiktok"
        ? "@"
        : "";
    return prefix;
  }
}
