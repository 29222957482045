import {
  IS_FETCHING_ORGANIZATION,
  DID_FETCH_ORGANIZATION,
  FETCHED_USER_ORGANIZATIONS,
  DID_UPDATE_ORGANIZATION,
  LOGOUT_USER,
} from "actions/Types";

//Local storage / caching

const saveOrganizationsToLocalStorage = (organizations) => {
  localStorage.setItem("organizations", JSON.stringify(organizations));
};

const loadOrganizationsFromLocalStorage = () => {
  let orgs = localStorage.getItem("organizations");
  if (orgs) {
    return JSON.parse(orgs);
  }
  return {};
};

const saveCurrentOrganizationToLocalStorage = (organization) => {
  localStorage.setItem("current_organization", JSON.stringify(organization));
};

const loadCurrentOrganizationFromLocalStorage = () => {
  let org = localStorage.getItem("current_organization");
  if (org) {
    return JSON.parse(org);
  }
  return null;
};

//org object is organization_id : {organization: Organization, user_resource_permission_scopes : UserResourcePermissionScope[], invitations:ResourcePermissionInvitation[]}
/**
 * Initial auth user
 */
const INIT_STATE = {
  // organizations: loadOrganizationsFromLocalStorage(),
  organizations: {},
  teams: [],
  current_organization: loadCurrentOrganizationFromLocalStorage(),
  is_fetching: false,
  most_recently_updated_organizations: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_FETCHING_ORGANIZATION: {
      return {
        ...state,
        is_fetching: true,
      };
    }
    case DID_FETCH_ORGANIZATION: {
      let orgs = state.organizations;
      orgs[action.payload.organization.id] = action.payload;
      saveCurrentOrganizationToLocalStorage(action.payload);
      return {
        ...state,
        is_fetching: false,
        organizations: { ...orgs },
        current_organization: action.payload,
      };
    }
    case FETCHED_USER_ORGANIZATIONS: {
      // let orgs = state.organizations;
      // action.payload.forEach((org) => {
      //   orgs[org.id] = org;
      // });
      // saveOrganizationsToLocalStorage(orgs);
      return {
        ...state,
        teams: action.payload ?? [],
      };
    }
    case DID_UPDATE_ORGANIZATION: {
      if (action.payload.organization && action.payload.organization.id) {
        let orgs = state.organizations;
        let newOrg = orgs[action.payload.organization.id].organization;
        newOrg.organization = action.payload.organization;
        saveCurrentOrganizationToLocalStorage(newOrg);
        orgs[action.payload.organization.id] = newOrg;
        return {
          ...state,
          organizations: { ...orgs },
          current_organization: newOrg,
        };
      } else {
        return {
          ...state,
          current_organization: null,
        };
      }
    }
    case LOGOUT_USER:
      //remove all from local storage
      localStorage.removeItem("organizations");
      localStorage.removeItem("current_organization");
    default:
      return state;
  }
};
