import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Recipe } from "kre8tv/model";
import { AIAbility } from "kre8tv/model/AIAbility";

export function VLColumnsForTable(type: string) {
  switch (type) {
    case "creations":
      return TableColumnsCreations;
    default:
      break;
  }
  return columns;
}
export function VLRowsForTable(data: any) {
  return rows;
}

/*Table Headers */

const TableColumnsCreations: GridColDef[] = [
  { field: "id", headerName: "ID", width: 250 },
  // {
  //   field: 'created_on',
  //   headerName: 'Created on ts',
  //   type: 'dateTime',
  //   width: 0,
  // },
  {
    field: "created_on_pretty",
    headerName: "Created on",
    type: "string",
    width: 250,
  },
  // {
  //   field: 'released_on',
  //   headerName: 'Released on',
  //   type: 'dateTime',
  //   width: 0,
  // },
  {
    field: "released_on_pretty",
    headerName: "Released on",
    type: "string",
    width: 250,
  },
  { field: "data_type", headerName: "Type", width: 150 },
  {
    field: "visibility_type_nice",
    headerName: "Visibility",
    width: 150,
  },
  {
    field: "preview_url",
    headerName: "Thumbnail",
    width: 250,
    renderCell: (params: GridCellParams) => {
      return (
        <img
          alt="thumbnail"
          src={String(params.value ?? "")}
          style={{ width: "150px", height: "150px", objectFit: "cover" }}
        ></img>
      );
    },
    valueGetter: (params: GridValueGetterParams) => `${params.row.thumb_url}`,
  },
  { field: "title", headerName: "Caption", width: 250 },
  { field: "desc", headerName: "Description", width: 350 },
  { field: "likes_count", headerName: "Likes", width: 150, type: "number" },
  { field: "views_count", headerName: "Views", width: 150, type: "number" },
  {
    field: "comments_count",
    headerName: "Comments",
    width: 150,
    type: "number",
  },
];
export interface ITableRowsCreations {
  id?: string;
  created_on?: number;
  released_on?: number;
  data_type?: string;
  title?: string;
  desc?: string;
  visibility_type?: number;
  likes_count?: number;
  views_count?: number;
  comments_count?: number;
  thumb_url?: string;
  created_on_pretty?: string;
  released_on_pretty?: string;
  creation_payload?: string;
}

export class TableRowsCreations implements ITableRowsCreations {
  id = "";
  created_on = 0;
  released_on = 0;
  data_type = "";
  title = "";
  desc = "";
  visibility_type = 0;
  likes_count = 0;
  views_count = 0;
  comments_count = 0;
  thumb_url = undefined;
  created_on_pretty = "";
  released_on_pretty = "";
  creation_payload? = "";
}

interface IBaseUser {
  id: string;
  last_updated: number;
  profile_id?: string;
  first_name?: string;
  last_name?: string;
  title?: string;
  profile_picture_url?: string;
  categories: string[];
}

export class CreatorRow implements IBaseUser {
  id = "";
  last_updated = 0;
  profile_id?: string = undefined;
  first_name?: string = undefined;
  last_name?: string = undefined;
  title?: string = undefined;
  profile_picture_url?: string = undefined;
  categories: string[] = [];

  constructor(data: any) {
    this.id = data.get("id");
    this.last_updated = data.get("last_updated");
    this.profile_id = data.get("profile_id");
    this.first_name = data.get("first_name");
    this.last_name = data.get("last_name");
    this.title = data.get("title");
    this.profile_picture_url = data.get("profilePictureURL");
    this.categories = String(data.get("category_id")).trim().split(","); //create array of categories from category_id
  }

  toContactGridItem(): IUIContactGrid {
    let obj: IUIContactGrid = new IUIContactGrid();
    obj.id = this.id;
    obj.profile_id = this.profile_id;
    obj.address = "Israel";
    obj.first_name = this.first_name;
    obj.last_name = this.last_name;
    obj.name =
      this.first_name +
      `${this.first_name && this.first_name!.length > 0 ? " " : ""}` +
      this.last_name;
    obj.designation = this.title;
    obj.image = this.profile_picture_url;
    obj.categories = this.categories;
    return obj;
  }
}

export class HomepageCategory {
  id = "";
  title: string;
  title_hebrew: string;
  header_title: string;
  subtitle: string;
  jumbo_image_url: string;
  imageURL: string;
  videoURL: string;
  is_active: boolean;
  rank: number;
  creators: IBaseUser[] = [];

  constructor(data: any) {
    this.id = data.get("id");
    this.title = data.get("title");
    this.title_hebrew = data.get("title_hebrew");
    this.header_title = data.get("header_title");
    this.subtitle = data.get("subtitle");
    this.jumbo_image_url = data.get("jumbo_image_url");
    this.imageURL = data.get("imageURL");
    this.videoURL = data.get("videoURL");
    this.is_active = data.get("is_active");
    this.rank = data.get("rank");
  }
}

export class AIAbilityCreation {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * Input type
   */
  input: AbilityInputType[] = ["text"];
  /**
   * Output type
   */
  output: AbilityInputType[] = ["text"];
  /**
   * The ability id (superpower)
   */
  ability_id = ""; //superpower id
  /**
   * Recipe id
   */
  recipe_id?: string;
  /**
   * Raw user input
   */
  raw_input = "";
  /**
   * Last updated date
   */
  last_updated?: number;
  /**
   * Created on date
   */
  created_on?: number;
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * id of sequence
   */
  sequence_id?: string;

  /**
   * Creation result
   */
  result?: any;

  /**
   * Constructor
   * @param data (airtable record)
   */
  constructor(data?: any) {
    //id
    if (!data) {
      return;
    }
    this.id = data["id"];

    this.input = data["input"];
    this.output = data["output"];

    this.ability_id = data["ability_id"];
    this.recipe_id = data["recipe_id"];
    this.raw_input = data["raw_input"];

    this.last_updated = data["last_updated"];
    this.created_on = data["created_on"];
    this.user_id = data["user_id"];

    //output parsing (if exists)
    this.result = data["result"];
  }

  static fromSequenceRun(
    superpower: AIAbility,
    recipe: Recipe,
    instructions: string,
    result: any
  ) {
    const creation = new AIAbilityCreation();
    creation.input = superpower.input;
    creation.output = superpower.output;

    creation.ability_id = superpower.id;
    creation.recipe_id = recipe.id;
    creation.raw_input = instructions;

    creation.last_updated = new Date().getTime();
    creation.created_on = new Date().getTime();
    // creation.user_id = data["user_id"];

    //output parsing (if exists)
    creation.result = result;
    return creation;
  }
}

//MARK: UI Interfaces

/**
 * ContactGridItem.js
 */
export class IUIContactGrid {
  id?: string = undefined;
  image?: string = undefined;
  first_name?: string = undefined;
  last_name?: string = undefined;
  name?: string = undefined;
  profile_id?: string = undefined;
  address?: string = undefined;
  designation?: string = undefined;
  email?: string = undefined;
  phoneNo?: string = undefined;
  type?: string = undefined;
  categories?: string[] = [];
}

//MARK: Airtable to general dat-grid row
export function MapRecordToRow(record: any): TableRowsCreations {
  let obj = new TableRowsCreations();
  obj.id = record.get("id");
  obj.created_on = record.get("created_on");
  obj.released_on = record.get("released_on") ?? obj.created_on;
  obj.data_type = record.get("data_type");
  obj.title = record.get("title");
  obj.desc = record.get("desc");
  obj.visibility_type = parseInt(record.get("visibility_type"));
  obj.likes_count = record.get("likes_count");
  obj.views_count = record.get("views_count");
  obj.comments_count = record.get("comments_count");
  obj.thumb_url = record.get("thumb_url");

  let pretty = new Date(obj.created_on);
  obj.created_on_pretty = pretty.toLocaleString();

  pretty = new Date(obj.released_on);
  obj.released_on_pretty = pretty.toLocaleString();

  const creation_payload = JSON.stringify(obj);
  obj.creation_payload = creation_payload;

  return obj;
}

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName ?? "First"} ${params.row.lastName ?? "Last"}`,
  },
  { field: "created_on", headerName: "Created on", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Franssces", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  { id: 10, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 11, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 12, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 13, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 14, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 15, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 16, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 17, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 18, lastName: "Melisandre", firstName: null, age: 150 },
];
export function AbilityInputTypeToString(type: AbilityInputType) {
  switch (type) {
    case "text":
      return "text";
    case "file_url":
      return "file url";
    case "video":
      return "video";
    case "audio":
      return "audio";
    default:
      break;
  }
}
