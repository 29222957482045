/**
 * Abilities Reducer
 */
//action types
import {
  GET_CREATORS,
  FAILED_GET_CREATORS,
  GET_CREATOR_AVATARS,
  FAILED_GET_CREATOR_AVATARS,
} from "actions/Types";

const INITIAL_STATE = {
  creators: JSON.parse(localStorage.getItem("creators") ?? "[]").map((o) => {
    // console.log(o);
    return o;
  }),
  isFetchingCreators: true,
  isErrorFetchingCreators: false,
  avatars: JSON.parse(localStorage.getItem("avatars") ?? "[]").map((o) => {
    // console.log(o);
    return o;
  }),
  isFetchingAvatars: true,
  isErrorFetchingAvatars: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREATORS:
      //save to local store
      localStorage.setItem("creators", JSON.stringify(action.payload));
      return {
        ...state,
        creators: action.payload,
        isFetchingCreators: false,
        isErrorFetchingCreators: false,
      };
    case FAILED_GET_CREATORS:
      return {
        ...state,
        creators: [],
        isFetchingCreators: false,
        isErrorFetchingCreators: true,
      };
    case GET_CREATOR_AVATARS:
      //save to local store
      localStorage.setItem("avatars", JSON.stringify(action.payload));
      return {
        ...state,
        avatars: action.payload,
        isFetchingAvatars: false,
        isErrorFetchingAvatars: false,
      };
    case FAILED_GET_CREATOR_AVATARS:
      return {
        ...state,
        avatars: [],
        isFetchingAvatars: false,
        isErrorFetchingAvatars: true,
      };
    default:
      return { ...state };
  }
};
