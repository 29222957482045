export default class BaseTracker {
  constructor() {
    ["init", "event"].forEach((method) => {
      if (!this[method])
        throw new Error(
          `Missing method '${method}' on '${this.constructor.name}'`
        );
    });
  }
}
