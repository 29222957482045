/**
 * Hulk Page Loader
 */

import CircularProgress from "@mui/material/CircularProgress";

const HulkPageLoader = () => (
  <div className="hk-page-loader">
    <CircularProgress />
  </div>
);

export { HulkPageLoader };
