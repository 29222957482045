/**
 * Chat App Actions
 */
import { GET_SUPERCREATORS } from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch all supercreators (Synthetic users)
 */
export const getSupercreators = () => (dispatch) => {
  airtable.fetchSuperheroes((supercreators, error) => {
    if (supercreators) {
      dispatch({
        type: GET_SUPERCREATORS,
        payload: supercreators,
      });
    } else {
      dispatch({
        type: GET_SUPERCREATORS,
        payload: error,
      });
    }
  });
};
