import { fetchCurrentUserAccountDetails } from "actions/AccountDetailsActions";
import { analytics } from "analytics/analytics";
import firebase from "firebase";
import { AccountDetails, Organization, User } from "kre8tv/model";

import { ScriptScene } from "kre8tv/model/script-scene.model";
import { Superscript } from "kre8tv/model/super-script.model";
import { Recipe } from "kre8tv/model/VLRecipe";
import {
  saveOrUpdateSuperScript,
  generateScene,
  generateScript,
  duplicateScript,
} from "services/superscripts.service";
import { store } from "store";
import { cleanObject } from "util/SCUtils";
import { DB_ACCOUNT_DETAILS, DB_ORGANIZATIONS, DB_USERS } from "./DBConstants";
import { ScriptSceneManager } from "./VLScriptSceneManager";

const sceneManager = new ScriptSceneManager();

export class UserManager {
  base?: any;
  firestore = firebase.firestore();
  unsubscribe_account_details: any = null;
  unsubscribe_user: any = null;
  constructor() {}

  /**
   * Deletes the superscript for the user
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async fetchUser(userId: string): Promise<{ user: User }> {
    try {
      let doc = this.firestore.collection(DB_USERS).doc(userId);
      const result = await doc.get();
      return { user: new User(result.data()) };
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Deletes the superscript for the user
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async fetchUserWithEmail(email: string) {
    try {
      let db = this.firestore.collection(DB_USERS);
      const snapshot = await db.where("email", "==", email).limit(1).get();
      let user = null;
      snapshot.forEach((doc) => {
        //only store if generations is a valid object
        user = new User(doc.data());
      });
      return { user: user };
    } catch (error: any) {
      throw new Error(error);
    }
  }

  //alias
  async fetchTeam(organizationId: string) {
    return this.fetchOrganization(organizationId);
  }

  /**
   * Fetches the organization from and organization id
   * @param organizationId The organization id to fetch
   * @returns An organization object or throws an error
   */
  async fetchOrganization(
    organizationId: string
  ): Promise<{ organization: Organization }> {
    try {
      let db = this.firestore.collection(DB_ORGANIZATIONS);
      const snapshot = await db.doc(organizationId).get();
      if (snapshot.exists) {
        return { organization: new Organization(snapshot.data()) };
      } else {
        throw new Error("Organization not found");
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Fetche the most recently updated users
   * @param limit The limit to fetch of users
   * @returns
   */
  async fetchRecentlyConnectedUsers(limit = 10): Promise<User[]> {
    //validate current user is admin before allowing to run this method
    try {
      let db = this.firestore.collection(DB_USERS);
      const snapshot = await db
        .orderBy("last_updated", "desc")
        .limit(limit)
        .get();
      let users: User[] = [];
      const docs = snapshot.docs;
      docs.forEach((doc) => {
        //only store if generations is a valid object
        const user = new User(doc.data());
        users.push(user);
      });
      return users;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Fetches the current user's account details (once)
   * @param
   * Note - will throw error if unsuccesfull
   */
  async fetchCurrentUserAccountDetails(): Promise<AccountDetails> {
    const userId = store.getState().authUser.user_id;
    try {
      let doc = this.firestore.collection(DB_ACCOUNT_DETAILS).doc(userId);
      const result = await doc.get();
      if (result.exists) {
        return new AccountDetails(result.data());
      } else {
        //create them...
        let doc = this.firestore.collection(DB_ACCOUNT_DETAILS).doc(userId);
        let ad = {
          id: userId,
          created_on: new Date().getTime(),
          last_updated: new Date().getTime(),
          last_updated_by_user_id: userId,
        };
        //set
        const set = await doc.set(ad);
        //fetch the newly created account details
        return await this.fetchCurrentUserAccountDetails();
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Fetches the current user's account details (ongoing)
   */
  listenToCurrentUserAccountDetails(callback: any) {
    const userId = store.getState().authUser.user_id;
    this.unsubscribe_account_details = this.firestore
      .collection(DB_ACCOUNT_DETAILS)
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          try {
            callback(new AccountDetails(doc.data()));
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  listenToCurrentUserDetails(callback: any) {
    const userId = store.getState().authUser.user_id;
    this.unsubscribe_user = this.firestore
      .collection(DB_USERS)
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          try {
            callback(new User(doc.data()));
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  /**
   * Removes the listener for the current user's account details
   */
  removeCurrentUserAccountDetailsListener() {
    if (this.unsubscribe_account_details) {
      this.unsubscribe_account_details();
      this.unsubscribe_account_details = null;
    }
  }

  /**
   * Removes the listener for the current user's account details
   */
  removeCurrentUserDetailsListener() {
    if (this.unsubscribe_user) {
      this.unsubscribe_user();
      this.unsubscribe_user = null;
    }
  }

  /**
   * Deletes the superscript for the user
   * @param milestone - the key of milestone to update
   * Note - will throw error if unsuccesfull
   */
  async updateMilestone(milestone: string) {
    const userId = store.getState().authUser.user_id;
    //use existing milestones
    let ts = Date.now();
    let ts_in_seconds = Math.round(ts / 1000);
    const fields: any = {};
    fields[milestone] = ts;
    try {
      let update = await this.firestore
        .collection(DB_ACCOUNT_DETAILS)
        .doc(userId)
        .update(fields);
      analytics.setCustomUserAttribute(
        "completed_onboarding_at",
        ts_in_seconds
      );
      store.dispatch(fetchCurrentUserAccountDetails());
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
