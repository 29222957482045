import { objToJSON } from "util/SCUtils";
import { FormModelAnswer } from "./form-model-answer.model";
import { FormModelQuestion } from "./form-model-question.model";

export class FormModel {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * title of script
   */
  title = "";
  /**
   * information about the script
   */
  info = "";
  /*
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id = "";

  /**
   * Last updated date
   */
  last_updated = 0;
  /**
   * Created on date
   */
  created_on = 0;

  /**
   * id of function this form uses - for example "advanced_script_creation" function (optional)
   */
  function_id?: string;

  /**
   * Is video deleted
   */
  is_deleted = false;

  /**
   * Is video archived
   */
  archived?: boolean;

  //Main data
  questions: FormModelQuestion[] = [];
  output: AbilityInputType[] = ["script", "script_recipe"];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.title = obj.title;
    this.info = obj.info;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;
    this.function_id = obj.function_id;

    //details
    this.is_deleted = obj.is_deleted ?? false;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;

    //extra
    this.archived = obj.archived;

    //primary data
    if (obj.questions) {
      this.questions = obj.questions.map((q: any) => new FormModelQuestion(q));
    }
    //set the designated output types
    this.output = obj.output ?? ["script", "script_recipe"];
  }

  /// Get value from question id
  /// Values is either null, or an array of answers. If there are multiple answers, it means that the question is a multiple choice question
  getValueFromQuestionId(questionId: string): FormModelAnswer[] {
    const question = this.questions.find((q) => q.id === questionId);
    if (!question) {
      return [];
    }
    return question.answer;
  }

  getAPIAnswerFromQuestionId(questionId: string): FormModelAnswer | null {
    const question = this.questions.find((q) => q.id === questionId);
    if (!question) {
      return null;
    }
    return question.getAPIAnswer();
  }

  populateWithAnswers(questionsWithAnswers: FormModelQuestion[]): FormModel {
    //for each question, find the question in the form and populate it with the answers
    questionsWithAnswers.forEach((questionWithAnswers) => {
      const question = this.questions.find(
        (q) => q.id === questionWithAnswers.id
      );
      if (question) {
        question.updateAnswers(questionWithAnswers.answer);
      }
    });

    return this;
  }

  updateQuestionOptions(question_id: string, options: FormModelAnswer[]) {
    const question = this.questions.find((q) => q.id === question_id);
    if (question) {
      question.options = options;
    }
  }

  updateQuestionAnswer(question_id: string, answer: FormModelAnswer[]) {
    const question = this.questions.find((q) => q.id === question_id);
    if (question) {
      question.answer = answer;
    }
  }

  toJson() {
    const result = objToJSON(this);
    return result;
  }
}
