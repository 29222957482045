import { EditorManager } from "kre8tv/managers/VLEditorManager";
import { AIAbility } from "./AIAbility";

export class Sequence {
  id = "";
  title: string;
  description: string;
  path: string;
  tags: string[];
  status: RecipeStatus = "none";
  last_updated: number;
  created_on: number;

  superpowers_ids: string[] = []; //1d array of superpowers_ids for sequence

  //memory loaded superpowers
  superpowers: AIAbility[] = [];

  airtable_record_id: undefined;

  primaryValues: any[] = [];
  secondaryValues: any[] = [];

  /**
   * If the sequence should immediately fetch the creation result.
   * Note - only works if a default value exists
   */
  autoFetchResult = false;

  constructor(data?: any) {
    this.airtable_record_id = data?.id;
    this.id = data?.get("id") ?? "";
    this.title = data?.get("title") ?? "";
    this.description = data?.get("description") ?? "";
    this.path = data?.get("path") ?? "";
    this.tags = data?.get("tags") ?? [];
    this.status = data?.get("status") ?? "none";
    this.last_updated = data?.get("last_updated") ?? 0;
    this.created_on = data?.get("created_on") ?? 0;

    //custom parsing due to annoying airtable format
    this.superpowers_ids =
      String(data?.get("superpowers_ids") ?? "")
        ?.split(",")
        ?.map((i) => i.trim()) ?? [];
  }

  /**
   * Gets the primary value for a superpower
   * @param superpowerIndex The superpower index
   * @returns
   */
  primaryValue(superpowerIndex: number) {
    if (this.primaryValues.length > superpowerIndex) {
      return this.primaryValues[superpowerIndex];
    } else {
      return null;
    }
  }

  /**
   * Gets the secondary value for a superpower
   * @param superpowerIndex The superpower index
   * @returns
   */
  secondaryValue(superpowerIndex: number) {
    if (this.secondaryValues.length > superpowerIndex) {
      return this.secondaryValues[superpowerIndex];
    } else {
      return null;
    }
  }

  /**
   * Gets the secondary value for a superpower
   * @param superpowerIndex The superpower index
   * @returns
   */
  additionalProps(superpowerIndex: number) {
    if (this.superpowers.length > superpowerIndex) {
      let sp = this.superpowers[superpowerIndex];
      return {
        superhero_image_url: sp.superhero_image_url,
        superhero_tone_name: sp.superhero_tone_name,
        superhero_tone_title: sp.superhero_tone_title,
      };
    } else {
      return null;
    }
  }

  /**
   * Creates a temporary sequence with default values
   * @param title The title of the sequence
   * @returns
   */
  static tempSequence(title?: string) {
    const obj = new Sequence(undefined);
    obj.id = `temp_${new Date().getTime()}`;
    obj.title = title ?? "New Sequence";
    obj.description = "A new sequence of superpowers";
    obj.last_updated = new Date().getTime();
    obj.created_on = new Date().getTime();
    return obj;
  }
}

export const TEMP_SEQUENCE_PREFIX = "Sequence";
