import { ContentFormatEpisode } from "./content-format-episode.model";
import { IEntity } from "./interfaces";

export class ContentFormat implements IEntity {
  id: string = "";
  user_id: string = "";
  title: string = "";
  info?: string = "";
  thumbnail_url?: string;

  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  tags: string[] = [];
  episodes: ContentFormatEpisode[] = [];

  is_archived = false;

  //eventually can add metadata and/or quantifiers
  total_episodes = 0;
  episodes_with_scripts = 0;
  episodes_with_videos = 0;

  constructor(obj?: any) {
    if (!obj || !obj.user_id) {
      return;
    }
    const {
      id,
      user_id,
      title,
      thumbnail_url,
      info,
      created_on,
      last_updated,
      last_updated_by_user_id,
      tags,
      is_archived,
      total_episodes,
      episodes_with_scripts,
      episodes_with_videos,
    } = obj;
    this.id = id;
    this.user_id = user_id;

    this.title = title;
    this.info = info;
    this.thumbnail_url = thumbnail_url;
    this.tags = tags ?? [];
    this.is_archived = is_archived ?? false;

    //dates
    const now = new Date().getTime();
    this.created_on = created_on ?? now;
    this.last_updated = last_updated ?? now;
    this.last_updated_by_user_id = last_updated_by_user_id;

    //quantifiers
    this.total_episodes = total_episodes ?? 0;
    this.episodes_with_scripts = episodes_with_scripts ?? 0;
    this.episodes_with_videos = episodes_with_videos ?? 0;
  }
}
