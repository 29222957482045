import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { ResourceFolder } from "kre8tv/model";

/**
 * Saves a user asset to the server
 * @param asset The asset to save
 * @returns The saved asset
 */
export const createResourceFolder = async (
  name: string
): Promise<{ resourceFolder: ResourceFolder }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourceFolders`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "create_resource_folder",
      resource_folder: {
        name: name,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("createResourceFolder fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const savedResourceFolder = new ResourceFolder(result.resource_folder);
    if (!savedResourceFolder || !savedResourceFolder.id) {
      console.error(
        "resource folder - failed go get valid response from server. Result = ",
        result
      );
      throw new Error("error creating resourceFolder");
    }

    return { resourceFolder: savedResourceFolder };
  } catch (error: any) {
    console.error("error creating resourceFolder = ", error);
    throw new Error(error);
  }
};

//updateResourceFolder
export const updateResourceFolder = async (
  resource_folder_id: string,
  name: string
): Promise<{ resourceFolder: ResourceFolder }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourceFolders`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "update_resource_folder",
      resource_folder: {
        id: resource_folder_id,
        name: name,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("createResourceFolder fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const updatedResourceFolder = new ResourceFolder(result.resource_folder);
    if (!updatedResourceFolder || !updatedResourceFolder.id) {
      throw new Error("error updating resourceFolder");
    }

    return { resourceFolder: updatedResourceFolder };
  } catch (error: any) {
    console.error("error updating resourceFolder= ", error);
    throw new Error(error);
  }
};

//deleteResourceFolder
export const deleteResourceFolder = async (
  resource_folder_id: string
): Promise<{ resourceFolder: ResourceFolder }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourceFolders`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "delete_resource_folder",
      resource_folder: {
        id: resource_folder_id,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("deleteResourceFolder fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const updatedResourceFolder = new ResourceFolder(result.resource_folder);
    if (!updatedResourceFolder || !updatedResourceFolder.id) {
      throw new Error("Failed deleting resourceFolder");
    }

    return { resourceFolder: updatedResourceFolder };
  } catch (error: any) {
    console.error("Failed deleting resourceFolder = ", error);
    throw new Error(error);
  }
};
