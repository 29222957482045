import { objToJSON } from "util/SCUtils";
import { IEntity } from "./interfaces";
import { SuperpostSoundMetaData } from "./super-post-sound-metadata.model";
import { SuperpostSection } from "./super-post-section.model";

export class SCSuperpostPlatform {
  id: string;
  sections: SuperpostSection[];

  constructor(id: string, sections: SuperpostSection[]) {
    this.id = id;
    this.sections = sections;
  }
}

export class Superpost implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";

  /**
   * user id of who ran it
   */
  user_id = "";

  /**
   * Last updated by user id
   */
  last_updated_by_user_id = "";

  /**
   * Last updated date
   */
  last_updated = 0;
  /**
   * Created on date
   */
  created_on = 0;
  /**
   * id of data
   */
  data_id?: string;
  /**
   * child_data_id of data (content format episode id for example)
   */
  child_data_id?: string;
  /**
   * type of data
   * for now, we are only supporting superposts for short_form_videos
   * Might remove script + data_type alltogether in future just fyi
   */
  data_type?: "script" | "short_form_video";

  /**
   * sections of data
   */
  sections?: SuperpostSection[];

  //dictionary for platform to array of superpost section
  sections_with_platform: SuperpostSection[] = [];

  //array of sections that were added by the user (only stored in DB)
  custom_sections: SuperpostSection[] = [];

  sounds: SuperpostSoundMetaData[] = [];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;

    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;

    //details
    this.data_type = obj.data_type;
    this.data_id = obj.data_id;
    this.child_data_id = obj.child_data_id;

    //parse sections
    this.sections =
      obj.sections &&
      obj.sections.map((section: any) => new SuperpostSection(section));
    this.custom_sections =
      obj.custom_sections &&
      obj.custom_sections.map((section: any) => new SuperpostSection(section));

    //parse sounds
    this.sounds =
      obj.sounds &&
      obj.sounds.map((sound: any) => new SuperpostSoundMetaData(sound));

    this.sections_with_platform = (obj.sections_with_platform ?? []).map(
      (section: any) => new SuperpostSection(section)
    );
  }

  createdOnString(): string {
    const date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    const date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  toJson() {
    const result = objToJSON(this);
    return result;
  }

  platformSections(): SCSuperpostPlatform[] {
    let platforms: SCSuperpostPlatform[] = [];
    let allSections = Array.from(this.sections_with_platform);
    // create titles
    let titles: string[] = [];

    for (let section of allSections) {
      let platform = section.platform;
      if (!titles.includes(platform)) {
        titles.push(platform);
      }
    }

    for (let title of titles) {
      let sections = allSections.filter(
        (section) => section.platform === title
      );
      if (sections.length > 0) {
        platforms.push(new SCSuperpostPlatform(title, sections));
      }
    }

    return platforms;
  }

  platformTitles(): string[] {
    return this.platformSections().map((platformSection) => platformSection.id);
  }

  sectionsForPlatform(platform: string): SuperpostSection[] {
    let platformSection = this.platformSections().find(
      (section) => section.id === platform
    );
    if (!platformSection) {
      return [];
    }
    let sections = platformSection.sections.filter(
      (section) => !section.isEmpty()
    );
    return Array.from(sections);
  }

  platformCounts(): number[] {
    let counts: number[] = [];
    for (let platform of this.platformTitles()) {
      counts.push(this.sectionsForPlatform(platform).length);
    }
    return counts;
  }
}
