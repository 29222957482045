/**
 * Editor reducer
 */
//action types
import {
  POWER_UP_CONTENT,
  PICK_SUPERPOWER_FOR_SELECTED_CONTENT,
  DISMISS_POWER_UP_CONTENT,
  PICK_SUPERHERO_FOR_SELECTED_CONTENT,
} from "actions/Types";

const INITIAL_STATE = {
  contentToPowerup: null,
  pickSuperpowerForSelectedContent: false,
  showSuperpowerPicker: false,
  showSuperheroPicker: false,
  sceneToPowerup: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POWER_UP_CONTENT:
      return { ...state, contentToPowerup: action.payload.content };
    case PICK_SUPERPOWER_FOR_SELECTED_CONTENT:
      return {
        ...state,
        showSuperpowerPicker: true,
        contentToPowerup: action.payload.content,
        sceneToPowerup: action.payload.scene,
      };
    case PICK_SUPERHERO_FOR_SELECTED_CONTENT:
      return {
        ...state,
        showSuperheroPicker: true,
        contentToPowerup: action.payload.content,
        sceneToPowerup: action.payload.scene,
      };
    case DISMISS_POWER_UP_CONTENT:
      return {
        ...state,
        showSuperheroPicker: false,
        showSuperpowerPicker: false,
        pickSuperpowerForSelectedContent: false,
        sceneToPowerup: null,
        contentToPowerup: null,
      };
    default:
      return { ...state };
  }
};
