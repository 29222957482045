// Customerio.tracker.js
import { isProduction } from "util/SCUtils";
import BaseTracker from "./base.tracker";
import moment from "moment";

const TAG = "CustomerIOTracker: ";
const SESSION_KEY = "supercreatorSession";

// Define the AnalyticsPropertyKey enum
const AnalyticsPropertyKey = {
  PROFILE_ID: "profileId",
  FULL_NAME: "fullName",
  EMAIL: "email",
  BUILD_VERSION: "build_version",
  USER_EMAIL: "user_email",
  MEDIA_SOURCE: "install_media_source",
  CAMPAIGN: "install_campaign",
  AFFILIATE_REFERRAL_CODE: "affiliate_referral_code",
};

export class CustomerIOTracker extends BaseTracker {
  constructor() {
    super();
    // Initialize the user ID as null initially
    this.userId = null;
    this.body = {};
  }

  init() {
    return this;
  }

  event(name, properties = {}) {
    if (isProduction() === false) {
      return;
    }
    // Implement the logic to send events to Customer.io here
    // console.log(TAG, "Sending [ ", name, " ] to Customer.io");
    // console.log(TAG, "Properties: ", properties);
    // console.log(TAG, "User ID: ", this.userId);
    if (window._cio) {
      // Check if window._cio exists
      window._cio.track(name, properties);
      // console.log(TAG, "Sending [ ", name, " ] to Customer.io");
    } else {
      console.error(
        TAG,
        `Customer.io is not initialized - could not send event. User ID: ${this.userId} and window._cio: ${window._cio}`
      );
    }
  }

  setUserProperty(name, property) {
    if (isProduction() === false) {
      return;
    }
    //print both stae of user id and window._cio
    // console.log(TAG, "setUserProperty: this.userId: ", this.userId);
    // console.log(TAG, "setUserProperty: window._cio: ", window._cio);
    // Implement the logic to set user properties in Customer.io here
    if (window._cio) {
      let payload = {};
      if (this.userId) {
        payload[name] = property;
        payload["id"] = this.userId;
      } else {
        payload[name] = property;
      }
      window._cio.identify(payload);

      // console.log(TAG, "identify payload: ", payload);
    }
    return this;
  }

  setUserId = (userId) => {
    this.userId = userId;
    if (isProduction() === false) {
      return;
    }
    if (window._cio) {
      window._cio.identify({ id: this.userId });
    } else {
      console.error(
        TAG,
        "Customer.io is not initialized - could not set user ID"
      );
    }
  };

  setFullName = (fullName) => this.setUserProperty("name", fullName);
  setFirstName = (firstName) => this.setUserProperty("firstName", firstName);
  setLastName = (lastName) => this.setUserProperty("lastName", lastName);
  setEmail = (email) => this.setUserProperty("email", email);

  logout = () => {
    if (window._cio) {
      window._cio.reset();
    }
  };
}
