import { cleanObject } from "util/SCUtils";
import { ContentAsset } from "./content-asset.model";
import { IEntity } from "./interfaces/entity.interface";

export class ScriptRecipe implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id = "";

  /**
   * Last updated date
   */
  last_updated = 0;
  /**
   * Created on date
   */
  created_on = 0;

  /**
   * urls of sources
   */
  source_urls: string[] = [];

  /**
   * source text
   */
  source_text?: string;

  /**
   * summary
   */
  summary?: string;

  /**
   * topic
   */
  topic?: string;

  /**
   * id of format (optional)
   */
  format_id?: string;

  /**
   * format title (optional)
   */
  format_name?: string;

  /**
   * script title
   */
  title?: string;

  /**
   * script promise
   */
  promise?: string;

  /**
   * script body
   */
  body?: string;

  /**
   * script payoff (closing/present)
   */
  payoff?: string;

  /**
   * script payoff (closing/present)
   */
  call_to_action?: string;

  /**
   * Is script deleted
   */
  is_deleted = false;

  /**
   * Goal type
   */
  goal_type:
    | "create_script"
    | "create_format"
    | "create_script_from_listicle"
    | "create_script_from_listicle_v2"
    | "create_script_from_slices" = "create_script";

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //script details
    this.summary = obj.summary;
    this.source_urls = obj.source_urls ?? [];
    this.source_text = obj.source_text;
    this.topic = obj.topic;
    this.format_id = obj.format_id;
    this.format_name = obj.format_name;
    this.title = obj.title;
    this.promise = obj.promise;
    this.body = obj.body;
    this.payoff = obj.payoff;
    this.call_to_action = obj.call_to_action;

    this.is_deleted = obj.is_deleted ?? false;
    this.goal_type = obj.goal_type ?? "create_script";

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
  }

  createdOnString(): string {
    const date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    const date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  wizardTitle(): string {
    switch (this.goal_type) {
      case "create_script":
        return "Create Story";
      case "create_format":
        return "Create series";
      case "create_script_from_listicle":
        return "Create story from Listicle";
      case "create_script_from_listicle_v2":
        return "Create story from Listicle";
      case "create_script_from_slices":
        return this.title && this.title.length === 0
          ? "Create Story"
          : this.title ?? "Create Story";
      default:
        return "Create Story";
    }
  }

  isRecipeForCreatingScript(): boolean {
    return (
      this.goal_type === "create_script" ||
      this.goal_type === "create_script_from_listicle" ||
      this.goal_type === "create_script_from_listicle_v2" ||
      this.goal_type === "create_script_from_slices"
    );
  }

  isRecipeForCreatingFormat(): boolean {
    return this.goal_type === "create_format";
  }
}
