/** Database name for IndexedDB */
const DB_NAME = "AssetSearchDB";
/** Store name for recent asset searches */
const ASSET_SEARCH_RESULTS_STORE_NAME = "RecentSearches";

/** Opens the IndexedDB and creates the object store if needed */
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(ASSET_SEARCH_RESULTS_STORE_NAME, { keyPath: "id" });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

/** Saves recent asset searches to IndexedDB */
export const saveRecentAssetSearchesToIndexedDB = async (searches) => {
  const db = await openDB();
  const transaction = db.transaction(
    ASSET_SEARCH_RESULTS_STORE_NAME,
    "readwrite"
  );
  const store = transaction.objectStore(ASSET_SEARCH_RESULTS_STORE_NAME);

  searches.forEach((search) => {
    // console.log("car - saveRecentAssetSearchesToIndexedDB", search);
    store.put(search);
  });

  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => resolve(true);
    transaction.onerror = (event) => reject(event.target.error);
  });
};

/** Loads recent asset searches from IndexedDB */
export const loadRecentAssetSearchesFromIndexedDB = async () => {
  const db = await openDB();
  const transaction = db.transaction(
    ASSET_SEARCH_RESULTS_STORE_NAME,
    "readonly"
  );
  const store = transaction.objectStore(ASSET_SEARCH_RESULTS_STORE_NAME);
  const request = store.getAll();

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      // Sort results by timestamp, newest first
      const sortedResults = request.result.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      resolve(sortedResults);
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

/** Clears recent asset searches from IndexedDB */
export const clearRecentAssetSearchesFromIndexedDB = async () => {
  const db = await openDB();
  const transaction = db.transaction(
    ASSET_SEARCH_RESULTS_STORE_NAME,
    "readwrite"
  );
  const store = transaction.objectStore(ASSET_SEARCH_RESULTS_STORE_NAME);
  store.clear();
};
