import { cleanObject } from "util/SCUtils";
import { ContentAsset } from "./content-asset.model";
import { IEntity } from "./interfaces/entity.interface";

/**
 * A scene object used in scripts
 */
export class ScriptScene implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * title of script
   */
  title = "";
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";

  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;

  /**
   * id of script (optional)
   */
  script_id?: string;

  /**
   * if this scene is deleted
   */
  is_deleted = false;

  /**
   * Type of script - undefined, promise, process, payoff, call to action, etc
   */
  type?: string;

  /**
   * index of scene (0 - infinite)
   */
  scene_index?: number;

  /**
   * What to say
   */
  text_speech?: string;

  /**
   * What to show on screen
   */
  text_visual?: string;

  /**
   * Additional info / instructions
   */
  additional_info?: string;

  /**
   * Is active
   */
  is_active: boolean = true;

  // New asset model (each scene can have only one of each)
  foreground_asset_id?: string;
  foreground_asset?: ContentAsset | null;
  background_asset_id?: string;
  background_asset?: ContentAsset | null;
  audio_asset_id?: string;
  audio_asset?: ContentAsset | null;

  // Temp audio asset (not yet uploaded) - should not be saved to backend
  temp_audio_asset_url?: string;

  //for when there are multiple options for a content asset
  content_asset_options: ContentAsset[] = [];
  //for when there are multiple options for text speech values
  text_speech_options: string[] = [];

  /**
   * A pointer to the currently selected text content asset value
   * Note - on init, this is the background asset (if it exists)
   */
  selected_content_asset?: ContentAsset | null;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.title = obj.title;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //details
    this.script_id = obj.script_id;
    this.is_deleted = obj.is_deleted;
    this.type = obj.type;
    this.scene_index = obj.scene_index;
    this.text_speech = obj.text_speech;
    this.text_visual = obj.text_visual;
    this.additional_info = obj.additional_info;
    this.is_active = obj.is_active;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
    this.last_updated_by_user_id = obj.user_id;

    //main assets
    this.temp_audio_asset_url = obj.temp_audio_asset_url;

    //v2 - assets
    this.background_asset_id = obj.background_asset_id;
    this.background_asset = ContentAsset.fromJSON(obj.background_asset);
    this.audio_asset_id = obj.audio_asset_id;
    this.audio_asset = ContentAsset.fromJSON(obj.audio_asset);
    this.foreground_asset_id = obj.foreground_asset_id;
    this.foreground_asset = ContentAsset.fromJSON(obj.foreground);

    const content_asset_options = obj.content_asset_options ?? [];
    this.content_asset_options = [];
    content_asset_options.forEach((element: any) => {
      const asset = ContentAsset.fromJSON(element);
      if (asset) {
        this.content_asset_options.push(asset);
      }
    });

    const text_speech_options = obj.text_speech_options ?? [];
    this.text_speech_options = text_speech_options;
    this.selected_content_asset = this.background_asset;
  }

  createdOnString(): string {
    let date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    let date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  updateTypeBasedOnIndex() {
    this.type = this.scene_index === 0 ? "promise" : "process";
  }

  /**
   * Update the selected asset
   * NOTE - this will update both the background asset and the selected asset
   * @param asset The asset to update with
   * @param optionIndex The specific option index to update (if applicable)
   */
  updateWithSelectedAsset(asset: any, optionIndex = -1) {
    // 1. make sure to create options if they don't exist
    if (this.hasAssetOptions()) {
      //nop
    } else {
      //create options from background asset
      this.content_asset_options = [];
    }

    //2. remove any asset from content_asset_options that has same id as asset
    this.content_asset_options = this.content_asset_options.filter((a) => {
      return a.id !== asset.id;
    });

    // 3. if there is a different background asset, add it to the options
    if (this.background_asset && this.background_asset.id !== asset.id) {
      this.content_asset_options.push(this.background_asset);
    }

    // 4. update the selected asset
    this.background_asset = asset;
    this.selected_content_asset = asset;
  }

  removeAsset(asset: ContentAsset) {
    if (this.background_asset?.id === asset.id) {
      this.background_asset = null;
    }
    if (this.foreground_asset?.id === asset.id) {
      this.foreground_asset = null;
    }
    if (this.audio_asset?.id === asset.id) {
      this.audio_asset = null;
    }
    this.content_asset_options = this.content_asset_options.filter((a) => {
      return a.id !== asset.id;
    });

    if (this.selected_content_asset?.id === asset.id) {
      this.selected_content_asset = null;
      //set the first option as the selected asset
      if (this.content_asset_options.length > 0) {
        this.selected_content_asset = this.content_asset_options[0];
      }
    }
  }

  /**
   * Check if this scene has any asset options
   * @returns true if has options
   */
  hasAssetOptions(): boolean {
    return this.content_asset_options.length > 0;
  }

  /**
   * Array of all assets (selected background + options)
   * @returns
   */
  fullBackgroundAssetOptions(): ContentAsset[] {
    let options = [];
    if (this.background_asset) {
      options.push(this.background_asset);
    }
    this.content_asset_options.forEach((asset) => {
      if (asset.id !== this.background_asset?.id) {
        options.push(asset);
      }
    });
    return options;
  }

  /**
   * Get the index of the selected asset in the options
   * @returns -1 if not found, otherwise index
   */
  // indexOfSelectedAsset(): number {
  //   if (this.hasAssetOptions() && this.selected_content_asset) {
  //     const selected_id = this.selected_content_asset.id;
  //     return this.content_asset_options.findIndex(
  //       (asset) => asset.id === selected_id
  //     );
  //   } else {
  //     return -1;
  //   }
  // }

  jsonify() {
    const copy = this;
    //@ts-ignore
    if (this.background_asset?.id)
      copy.background_asset = cleanObject(
        Object.assign({}, this.background_asset)
      );
    else {
      copy.background_asset = null;
    }
    //@ts-ignore
    if (this.foreground_asset?.id)
      copy.foreground_asset = cleanObject(
        Object.assign({}, this.foreground_asset)
      );
    else {
      copy.foreground_asset = null;
    }
    //@ts-ignore
    if (this.audio_asset?.id)
      copy.audio_asset = cleanObject(Object.assign({}, this.audio_asset));
    else {
      copy.audio_asset = null;
    }
    return copy;
  }

  toJSON(): any {
    var json: any = {};
    json["id"] = this.id;
    json["user_id"] = this.user_id;
    json["title"] = this.title;
    json["script_id"] = this.script_id;
    json["text_speech"] = this.text_speech;
    json["text_visual"] = this.text_visual;
    json["additional_info"] = this.additional_info;
    json["scene_index"] = this.scene_index;
    json["is_deleted"] = this.is_deleted;
    // json["content_asset_id"] = this.contentAsset?.id
    json["background_asset_id"] = this.background_asset?.id;
    json["background_asset"] = this.background_asset?.toJSON();
    json["audio_asset_id"] = this.audio_asset?.id;
    json["foreground_asset_id"] = this.foreground_asset?.id;
    json["text_speech_options"] = this.text_speech_options;
    json["content_asset_options"] = this.content_asset_options.map((asset) =>
      asset.toJSON()
    );
    return json;
  }
}
