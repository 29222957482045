import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { ContentAsset, CreationResult, RecipeIngredient } from "kre8tv/model";
import { AIAbility } from "kre8tv/model/AIAbility";
import { IAssetSearchCallbackResult } from "kre8tv/model/interfaces/assets-callback-result.interface";

import { VLError, parseVLError } from "kre8tv/model/VLError";
import { Recipe } from "kre8tv/model/VLRecipe";
import { store } from "store";

export const adjustTextWithMethod = async (
  text: AIAbility,
  method: "expand" | "shrink" | "rephrase",
  numberOfResults = 1
): Promise<string | null> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  const action = `${method}_text`;
  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      method: method,
      action: action,
      input: text,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000, //10 seconds
    };

    // console.log("fetching creation result = ", payload);

    const fetchResults = await axios.post(url, payload, config);

    const { data } = fetchResults;
    const options: string[] = data.results;

    return options && options.length > 0 ? options[0] : null;
  } catch (error: any) {
    console.error("error adjustTextWithMethod ", error);
    throw new Error(error.response.data.error.message);
  }
};

/**
 * Attempts to create "creation results" from a superpower, recipe, and ingredients
 * @param superpower The superpower to use
 * @param recipe The recipe to use
 * @param ingredients The ingredients use to create the end result
 * @returns Array of creation results or null if failure
 */
export const createResultFromSuperpower = async (
  superpower: AIAbility,
  ingredients: RecipeIngredient[],
  recipe?: Recipe,
  tone?: string,
  numberOfResults?: number
): Promise<CreationResult[]> => {
  const params = ingredients.map((i) => {
    return {
      id: i.id,
      value: i.value,
    };
  });

  return createResultsFromParams(
    superpower,
    params,
    recipe!.id,
    tone,
    numberOfResults
  );
};

/**
 * Attempts to create "creation results" from a superpower, recipe, and ingredients
 * @param superpower The superpower to use
 * @param recipe The recipe to use
 * @param ingredients The ingredients use to create the end result
 * @returns Array of creation results or null if failure
 */
export const createResultsFromParams = async (
  superpower: AIAbility,
  params: any[],
  recipe_id?: string,
  tone?: string,
  numberOfResults?: number
): Promise<CreationResult[]> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  const user_id = firebase.auth().currentUser?.uid;

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, //45 seconds
    };

    const payload = {
      action: "create",
      superpower_id: superpower.id,
      recipe_id: recipe_id,
      ingredients: params,
      user_id: user_id,
      number_of_outputs: numberOfResults ?? 1,
    };

    // console.log("fetching creation result = ", payload);

    const fetchResults = token
      ? await axios.post(url, payload, config)
      : await axios.post(url, payload);

    // console.log("fetch result = ", fetchResults);

    const { data } = fetchResults;

    return data.results ?? [];
  } catch (error: any) {
    console.error("error superpower ", error);
    throw new Error(error.response.data.error.message);
  }
};

export const localSuperpowerFromId = (id: string) => {
  const superpowers: AIAbility[] =
    store.getState().abilitiesReducer.abilities ?? [];
  return superpowers.find((sp) => sp.id === id);
};

/* ASSETS */
/**
 * Attempts to create "creation results" from a superpower, recipe, and ingredients
 * @param superpower The superpower to use
 * @param recipe The recipe to use
 * @param ingredients The ingredients use to create the end result
 * @returns Array of creation results or null if failure
 */
export const fetchAssets = async (
  query: string,
  scene_id?: string,
  limit_to_result_types?: string[]
): Promise<IAssetSearchCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  const user_id = firebase.auth().currentUser?.uid;

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, //45 seconds
    };

    let payload: any = {
      action: "fetch_assets",
      search: query,
      user_id: user_id,
      scene_id: scene_id,
    };

    if (limit_to_result_types && limit_to_result_types.length > 0) {
      payload["limit_to_result_types"] = limit_to_result_types;
    }

    // console.log("fetching creation result = ", payload);

    const fetchResults = token
      ? await axios.post(url, payload, config)
      : await axios.post(url, payload);

    // console.log("fetch result = ", fetchResults);

    const { data } = fetchResults;
    const results = data.results;

    return { assets: results, finalQuery: data.finalQuery } ?? [];
  } catch (error: any) {
    console.error("error superpower ", error);
    throw new Error(error.response.data.error.message);
  }
};

export const createAIAssets = async (
  text: string,
  proportion: "square" | "portrait" | "landscape" = "square",
  amount: number = 1,
  with_background: boolean = false,
  model_id?: string,
  scene_id?: string
): Promise<IAssetSearchCallbackResult> => {
  try {
    const url = API_BASE_URL + "superpowers";
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const user_id = firebase.auth().currentUser?.uid;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: "web",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, // 45 seconds
    };

    const payload = {
      action: "create_ai_images_v2",
      text: text,
      user_id: user_id,
      scene_id: scene_id,
      proportion: proportion,
      amount: amount,
      with_background: with_background,
      model_id: model_id,
    };

    const response = token
      ? await axios.post(url, payload, config)
      : await axios.post(url, payload);

    const { data } = response;
    const results = data.results;

    let assets = [];
    for (const subJson of results) {
      const asset = ContentAsset.fromJSON(subJson);
      if (asset) {
        assets.push(asset);
      } else {
        // error parsing asset
      }
    }

    return { assets: assets, finalQuery: data.finalQuery };
  } catch (error: any) {
    console.error("error createAIAssets", error);
    throw new Error(error.response.data.error.message);
  }
};

/* Summarization */
/**
 * Get's link metadata
 * @param urlToParse The url to parse
 * @returns Array of creation results or null if failure
 */
export const getLinkMetadata = async (urlToParse: string): Promise<any> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const params = {
      action: "get_url_info",
      url: urlToParse,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, //45 seconds
      params: params,
    };

    // console.log("fetching creation result = ", payload);

    const fetchResults = await axios.get(url, config);

    const { data } = fetchResults;
    const results = data.results;

    console.log(`url metadata for ${url} result = `, fetchResults);

    return { metadata: results } ?? {};
  } catch (error: any) {
    console.error("error superpower ", error);
    throw new Error(error.response.data.error.message);
  }
};

/* Summarization */
/**
 * Attempts to create "creation results" from a superpower, recipe, and ingredients
 * @param superpower The superpower to use
 * @param recipe The recipe to use
 * @param ingredients The ingredients use to create the end result
 * @returns Array of creation results or null if failure
 */
export const getSummary = async (
  method: "text" | "url",
  urlToParse?: string,
  longText?: string
): Promise<any> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      method: method,
      action: "get_summary_from_source",
      long_text: longText,
      url: urlToParse,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, //45 seconds
    };

    // console.log("fetching creation result = ", payload);

    const fetchResults = await axios.post(url, payload, config);

    const { data } = fetchResults;
    const summary = data.results;

    console.log(`url metadata for ${url} result = `, fetchResults);

    return { summary: summary } ?? {};
  } catch (error: any) {
    console.error("error superpower ", error);
    throw new Error(error.response.data.error.message);
  }
};

/* Content Creators */
/**
 * Attempts to create "creation results" from a superpower, recipe, and ingredients
 * @param superpower The superpower to use
 * @param recipe The recipe to use
 * @param ingredients The ingredients use to create the end result
 * @returns Array of creation results or null if failure
 */
export const createContentFromTweet = async (
  tweet_url: string
): Promise<boolean> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superpowers`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "create_content_from_url",
      url: tweet_url,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000, //45 seconds
    };

    // console.log("fetching creation result = ", payload);

    const fetchResults = await axios.post(url, payload, config);

    const { data } = fetchResults;
    const success = data.results;

    console.log(`url metadata for ${url} result = `, success);

    return success;
  } catch (error: any) {
    console.error("error superpower ", error);
    throw new Error(error.response.data.error.message);
  }
};

export const autoCreateAssetForScene = async (
  scene_id: string,
  scene_text: string,
  method: "search" | "create" = "search",
  model_id?: string,
  prompt_prefix?: string
): Promise<IAssetSearchCallbackResult> => {
  try {
    const url = API_BASE_URL + "superpowers";
    const token = await firebase.auth().currentUser?.getIdToken(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: "web",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000, // 30 seconds max
    };

    const payload = {
      action: "auto_create_asset_for_scene",
      scene_id: scene_id,
      scene_text: scene_text,
      method: method,
      model_id: model_id,
      prompt_prefix: prompt_prefix,
    };

    const response = token
      ? await axios.post(url, payload, config)
      : await axios.post(url, payload);

    const { data } = response;
    const results = data.results;
    const assets_json = results;

    let assets = [];
    for (const subJson of assets_json) {
      const asset = ContentAsset.fromJSON(subJson);
      if (asset) {
        assets.push(asset);
      } else {
        // error parsing asset
      }
    }

    return { assets: assets };
  } catch (error: any) {
    console.error("error createAIAssets", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

/**
 * Create assets for multiple scenes using the backend service.
 * @param scenes Array of scenes with scene_id and scene_text.
 * @param method Method to use ('create' or 'search').
 * @param model_id Optional model identifier.
 * @param prompt_prefix Optional prefix for the prompt.
 * @returns Promise resolving to an array of objects with scene_id and content_asset.
 */
export const createAssetsForScenes = async (
  scenes: { scene_id: string; scene_text: string }[],
  method: "create" | "search",
  model_id?: string,
  prompt_prefix?: string
): Promise<{ scene_id: string; content_asset: ContentAsset | null }[]> => {
  try {
    const url = `${API_BASE_URL}/superpowers`;
    const token = await firebase.auth().currentUser?.getIdToken(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const payload = {
      action: "create_assets_for_scenes",
      scenes: scenes,
      method: method,
      model_id: model_id,
      prompt_prefix: prompt_prefix,
    };
    const response = await axios.post(url, JSON.stringify(payload), {
      headers,
    });

    // print response
    console.log("createAssetsForScenes response", response);

    const json_results = response.data.results ?? [];

    const results = json_results.map((item: any) => ({
      scene_id: item.scene_id,
      content_asset: item.content_asset
        ? ContentAsset.fromJSON(item.content_asset)
        : null,
    }));
    return results;
  } catch (error: any) {
    console.error("error createAssetsForScenes", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const createTitleForScript = async (
  script_text: string,
  script_id: string
): Promise<string> => {
  try {
    const user_id = firebase.auth().currentUser?.uid;

    const url = `${API_BASE_URL}/superpowers`;
    const token = await firebase.auth().currentUser?.getIdToken(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const payload = {
      action: "create_script_title",
      input_text: script_text,
      script_id: script_id,
      user_id: user_id,
    };

    console.log("createTitleForScript called with payload", payload);

    const response = await axios.post(url, JSON.stringify(payload), {
      headers,
    });

    if (response.data.result) {
      const title = response.data.result;
      if (title.length > 0) {
        return title;
      } else {
        throw new Error("Empty title returned");
      }
    }
    return "";
  } catch (error: any) {
    console.error("error createTitleForScript", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

/**
 * Retrieves a prompt based on the provided scene text.
 * @param scene_text - The text of the scene to generate a prompt from.
 * @param script_id - The ID of the script associated with the scene.
 * @returns A promise that resolves to the generated prompt.
 */
export const getPromptFromSceneText = async (
  scene_text: string,
  script_id: string
): Promise<string> => {
  try {
    const user_id = firebase.auth().currentUser?.uid;

    const url = `${API_BASE_URL}/superpowers`;
    const token = await firebase.auth().currentUser?.getIdToken(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const payload = {
      action: "get_prompt_from_scene_text",
      scene_text: scene_text,
      script_id: script_id,
      user_id: user_id,
    };

    // console.log("getPromptFromSceneText called with payload", payload);

    const response = await axios.get(url, {
      params: payload,
      headers,
    });

    // console.log("getPromptFromSceneText response", response);

    if (response.data.result) {
      const prompt = response.data.result;
      if (prompt.length > 0) {
        return prompt;
      } else {
        throw new Error("Empty prompt returned");
      }
    }
    return "";
  } catch (error: any) {
    console.error("error getPromptFromSceneText", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
