import { DEFAULT_PROFILE_PICTURE_URL } from "util/SCUtils";
import { IEntity } from "./interfaces";
import { UserToResourcePermissionScope } from "services/resource-permission-scope.service";

export class Organization implements IEntity {
  id: string = "";
  user_id = ""; //this will be the user who created the organization

  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  name: string = "";
  is_deleted = false;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    const {
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      name,
      is_deleted,
    } = obj;

    this.id = id;
    this.user_id = user_id;

    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;
    this.name = name ?? "";
    this.is_deleted = is_deleted ?? false;
  }

  static fromJSON(obj: any): Organization | null {
    if (!obj || !obj.id) {
      return null;
    }

    const {
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,

      name,
      is_deleted,
    } = obj;

    return new Organization({
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      name,
      is_deleted,
    });
  }
}
/**
 * Map an array of user_resource_permission_scope to an array of people
 * @param members The array of user_resource_permission_scope (contains user's and their permissions)
 * @returns An array of people
 */
export const mapUsersToPeople = (members: UserToResourcePermissionScope[]) => {
  return members.map((user_resource_permission_scope) => {
    let user = user_resource_permission_scope.user;
    let name = user.first_name + " " + user.last_name;
    let email = user.email;
    let profilePictureURL =
      user.profilePictureURL ?? DEFAULT_PROFILE_PICTURE_URL;
    const id = user.id;
    return {
      id,
      name,
      email,
      profilePictureURL,
      editingLevel:
        user_resource_permission_scope.resource_permission_scope.permission,
      permissionScopeId:
        user_resource_permission_scope.resource_permission_scope.id,
    };
  });
};
