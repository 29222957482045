/**
 * Forget Password
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

// redux action
import { deleteUser } from "actions";

//analytics
import { analytics } from "analytics/analytics";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.deleteUser();
    analytics.logEvent("web - flow - account - did show delete account");
  }

  render() {
    console.log("DeleteAccountNew props", this.props);

    return (
      <Box backgroundColor="white" p={3} height="100vh">
        <Box
          height={1}
          style={{ background: "#f2f3f8", borderRadius: "30px" }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          textAlign={"center"}
          position="relative"
        >
          <Box
            fontSize="18px"
            fontWeight={"bold"}
            sx={{ marginTop: "50px" }}
            display="flex"
            alignItems="center"
          >
            Your account is being deleted
          </Box>
          <Box>
            <CircularProgress
              thickness={5}
              size={20}
              sx={{
                marginTop: "20px",
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps, { deleteUser })(DeleteAccount);
