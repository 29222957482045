/**
 * Chat App Actions
 */
import { GET_ABILITIES } from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { NotificationManager } from "react-notifications";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch all abilities
 */
export const getAbilities = () => (dispatch) => {
  airtable.fetchAbilities((abilities, error) => {
    if (abilities) {
      dispatch({
        type: GET_ABILITIES,
        payload: abilities,
      });
    } else {
      dispatch({
        type: GET_ABILITIES,
        payload: error,
      });
    }
  });
};
