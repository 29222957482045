/**
 * Router service file
 */
import Ecommerce from "routes/Ecommerce";
import Dashboard from "routes/Dashboard";
import Blog from "routes/Blog";
import Pages from "routes/Pages";
import UiComponents from "routes/UiComponents";
import Tables from "routes/Tables";
import FullPageMenu from "routes/FullPageMenu";

// Async component
import {
  AsyncMailComponent,
  AsyncChatComponent,
  AsyncInvoiceComponent,
  AsyncCalendarComponent,
  AsyncUserSettingsComponent,
  AsyncVideoPlayerComponent,
  AsyncAbilitiesComponent,
  AsyncEditorComponent,
  AsyncCreateSequenceComponent,
  AsyncCreationsSequenceComponent,
  AsyncMissionsComponent,
  AsyncFullPageAboutUsUrlsComponent,
  AsyncSuperheroesComponent,
  AsyncDemosComponent,
  AsyncPricingV1Component,
  AsyncScriptsComponent,
  AsyncGenerationsComponent,
  AsyncScheduleComponent,
  AsyncHomeComponent,
  AsyncCommunityComponent,
  AsyncAdminComponent,
  AsyncReferralDashboardComponent,
} from "routes";
import HomeWithPricing from "routes/Wrappers/HomeWithPricing";

export default [
  {
    path: "fullpagemenu",
    component: FullPageMenu,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "home",
    component: AsyncHomeComponent,
  },
  {
    path: "settings",
    component: AsyncHomeComponent,
  },
  {
    path: "ecommerce",
    component: Ecommerce,
  },
  {
    path: "chat",
    component: AsyncChatComponent,
  },
  {
    path: "email",
    component: AsyncMailComponent,
  },
  {
    path: "blog",
    component: Blog,
  },
  {
    path: "pages",
    component: Pages,
  },
  {
    path: "uicomponents",
    component: UiComponents,
  },
  {
    path: "invoice",
    component: AsyncInvoiceComponent,
  },
  {
    path: "calendar",
    component: AsyncScheduleComponent,
  },
  {
    path: "user-settings",
    component: AsyncUserSettingsComponent,
  },
  {
    path: "tables",
    component: Tables,
  },
  {
    path: "video-player",
    component: AsyncVideoPlayerComponent,
  },
  {
    path: "superheroes",
    component: AsyncSuperheroesComponent,
  },
  {
    path: "superpowers",
    component: AsyncAbilitiesComponent,
  },
  {
    path: "missions",
    component: AsyncMissionsComponent,
  },
  {
    path: "editor",
    component: AsyncEditorComponent,
  },
  // {
  //   path: "create",
  //   component: AsyncCreateSequenceComponent,
  // },
  {
    path: "creations",
    component: AsyncCreationsSequenceComponent,
  },
  {
    path: "mission",
    component: AsyncCreateSequenceComponent,
  },
  {
    path: "about",
    component: AsyncFullPageAboutUsUrlsComponent,
  },
  {
    path: "demos",
    component: AsyncDemosComponent,
  },
  {
    path: "pricing",
    component: HomeWithPricing,
  },
  {
    path: "scripts",
    component: AsyncScriptsComponent,
  },
  {
    path: "generations",
    component: AsyncGenerationsComponent,
  },
  {
    path: "community",
    component: AsyncCommunityComponent,
  },
  {
    path: "admin",
    component: AsyncAdminComponent,
  },
  {
    path: "referralDashboard",
    component: AsyncReferralDashboardComponent,
  },
];
