/**
 * Abilities Reducer
 */
//action types
import { GET_SUPERHEROES } from "actions/Types";
import { Superhero } from "kre8tv/model";

const INITIAL_STATE = {
  superheroes: JSON.parse(localStorage.getItem("superheroes") ?? "[]").map(
    (o) => {
      // console.log(o);
      return new Superhero(o);
    }
  ),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUPERHEROES:
      //save to local store
      localStorage.setItem("superheroes", JSON.stringify(action.payload));
      return { ...state, superheroes: action.payload };
    default:
      return { ...state };
  }
};
