import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { ShortFormVideo, parseVLError } from "kre8tv/model";

interface SubscriptionInfoResponse {
  is_active: boolean;
  billing_interval: string;
  seats: number;
  next_charge_date: number;
  cancelled: boolean;
  cancells_on?: number;
}

export const getSubscriptionInfo = async (): Promise<{
  personalPlan: SubscriptionInfoResponse | null;
  teamPlan: SubscriptionInfoResponse | null;
} | null> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `subscriptions`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
      params: {
        action: "get_subscription_info",
      },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getSubscriptionInfo result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    if (!result) return null;

    return {
      personalPlan: result.personal_plan,
      teamPlan: result.team_plan,
    };
  } catch (error: any) {
    console.error("error getSubscriptionInfo= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const createCheckoutSessionForTeamPlan = async (
  duration: "month" | "year",
  successUrl: string,
  cancelUrl: string,
  quantity: number,
  metadata: Record<string, string>,
  showingFreeTrial: boolean
): Promise<{ redirectToURL: string }> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `subscriptions`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 60000,
    };

    const body = {
      action: "create_checkout_url",
      duration: duration,
      success_url: successUrl,
      cancel_url: cancelUrl,
      quantity: quantity,
      show_free_trial: showingFreeTrial,
      metadata,
    };

    const fetchResult = await axios.post(url, body, config);

    // console.log("createCheckoutSession result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { checkout_url } = result;

    return {
      redirectToURL: checkout_url,
    };
  } catch (error: any) {
    console.error("error createCheckoutSession= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const createCheckoutSessionForPersonalPlan = async (
  duration: "month" | "year",
  successUrl: string,
  cancelUrl: string,
  metadata: Record<string, string>,
  showFreeTrial: boolean
): Promise<{ redirectToURL: string }> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `subscriptions`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 60000,
    };

    const body = {
      action: "create_checkout_url_for_individual",
      duration: duration,
      success_url: successUrl,
      cancel_url: cancelUrl,
      metadata,
      show_free_trial: showFreeTrial,
    };

    const fetchResult = await axios.post(url, body, config);

    // console.log("createCheckoutSession result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { checkout_url } = result;

    return {
      redirectToURL: checkout_url,
    };
  } catch (error: any) {
    console.error("error createCheckoutSession= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
