import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showPricing } from "actions";

// Async component
import { AsyncHomeComponent } from "routes";

const HomeWithPricing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showPricing(true));
  }, [dispatch]);

  return <AsyncHomeComponent />;
};

export default HomeWithPricing;
