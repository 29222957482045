/**
 * Abilities Reducer
 */
//action types
import {
  GET_CREATIONS,
  FAVORITE_CREATION,
  UNFAVORITE_CREATION,
  GET_FAVORITED_CREATIONS,
} from "actions/Types";
import { CreationResult } from "kre8tv/model";
import { NotificationManager } from "react-notifications";

const cachedCreations = () => {
  try {
    return JSON.parse(localStorage.getItem("creations") ?? "[]")?.map((o) => {
      return new CreationResult(o);
    });
  } catch (e) {
    localStorage.setItem("creations", []); //clean
    return [];
  }
};

const cachedFavorites = () => {
  try {
    let cached = JSON.parse(
      localStorage.getItem("creations_favorites") ?? "[]"
    );
    let favorites = {};
    let _ = cached.map(
      (favoriteObject) =>
        (favorites[favoriteObject.creation_id] = favoriteObject)
    );
    return favorites;
  } catch (e) {
    localStorage.setItem("creations_favorites", {}); //clean
    return [];
  }
};

const INITIAL_STATE = {
  creations: cachedCreations(),
  favorites: cachedFavorites(), //map of id : creation
  favoritedCreations: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREATIONS: {
      let allCreations = action.payload.results;
      let favorites = state.favorites;
      localStorage.setItem("creations", JSON.stringify(allCreations));
      return {
        ...state,
        creations: allCreations,
        favoritedCreations: allCreations.filter((item) => favorites[item.id]),
      };
    }
    case GET_FAVORITED_CREATIONS: {
      let allFavorites = action.payload.favorites ?? []; //from server we get it in array format
      let favorites = {}; //our custom object we are building
      //map result from server to local
      let favoriteObject = allFavorites.map(
        (favoriteObject) =>
          (favorites[favoriteObject.creation_id] = favoriteObject)
      );
      localStorage.setItem(
        "creations_favorites",
        JSON.stringify(favoriteObject)
      );
      let creations = state.creations;

      return {
        ...state,
        creations: creations,
        favorites: favorites,
        favoritedCreations: creations.filter((item) => favorites[item.id]),
      };
    }
    case FAVORITE_CREATION: {
      const id = action.payload.id;
      NotificationManager.success("Creation Favorited");

      let favorites = state.favorites;
      favorites[id] = action.payload;

      return { creations: state.creations, favorites };
    }
    case UNFAVORITE_CREATION: {
      const id = action.payload.id;
      NotificationManager.success("Creation Unavorited");

      let favorites = state.favorites;
      favorites[id] = null;
      return { creations: state.creations, favorites };
    }
    default:
      return { ...state };
  }
};
