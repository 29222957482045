import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { InspirationSource, InspirationSourceItem } from "kre8tv/model";
import { IInspirationItemsCallbackResult } from "kre8tv/model/interfaces";

/**
 * Search for inspiration sources
 * @param query
 * @param platform
 * @returns
 */
export const fetchInspirationSourceItems =
  async (): Promise<IInspirationItemsCallbackResult> => {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    try {
      let url = API_BASE_URL + `inspirationSourceItems`;

      const headers = {
        Authorization: `Bearer ${token}`,
        platform: `web`,
      };

      const params = {
        action: "fetch",
      };

      const config: AxiosRequestConfig = {
        headers: headers,
        timeout: 30000,
        params: params,
      };

      const saveResult = await axios.get(url, config);

      console.log("save result = ", saveResult);

      const { data } = saveResult;

      const items = data.items.map(
        (result: any) => new InspirationSourceItem(result)
      );

      return { items: items, error: undefined };
    } catch (error: any) {
      console.error("error submitInspirationSourceItems= ", error);
      // throw new Error(error);
      return { items: undefined, error: error.message };
    }
  };
