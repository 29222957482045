import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import {
  ContentFormatEpisode,
  FormModel,
  ScriptRecipe,
  Superscript,
  parseErrorMessage,
  parseVLError,
} from "kre8tv/model";
import { IScriptSceneCallbackResult } from "kre8tv/model/interfaces/scene-callback-result.interface";
import {
  IDuplicateScriptCallbackResult,
  IGenericSuccessCallback,
  ISuperscriptCallbackResult,
  ISuperscriptsCallbackResult,
} from "kre8tv/model/interfaces/supersripts-callback-result.interface";
import { ScriptScene } from "kre8tv/model/script-scene.model";

export const fetchFullScript = async (
  script_id: string
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  try {
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    // if (!token) {
    //   console.error("error fetch full script - no token");
    //   throw new Error("No token for firebase");
    // }

    if (!script_id) {
      console.error("error fetch full script - no script id");
      throw new Error("No story id");
    }

    // console.log("fetch full script - script id = ", script_id);

    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
      params: {
        action: "fetch_full_script",
        script_id: script_id,
        allow_previewing: true,
      },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;
    if (data.error && data.error.message) {
      //return error
      throw new Error(data.error.message);
    }

    const script_json = data.script;
    if (!script_json || !script_json.id) {
      throw new Error("No story found");
    }

    let result = new Superscript(script_json);

    //new flag for script
    result.can_edit = data.can_edit ?? true;

    const results: ISuperscriptCallbackResult = {
      results: result,
    };

    return results;
  } catch (error: any) {
    console.error("error fetch full script = ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const fetchSuperscripts =
  async (): Promise<ISuperscriptsCallbackResult> => {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    try {
      let url = API_BASE_URL + `superscripts`;

      const headers = {
        Authorization: `Bearer ${token}`,
        platform: `web`,
      };

      const config: AxiosRequestConfig = {
        headers: headers,
        timeout: 30000,
        params: { action: "fetch" },
      };

      const fetchResult = await axios.get(url, config);

      // console.log("fetch result = ", fetchResult);

      const { data } = fetchResult;

      const { superscripts } = data;

      const allSuperscripts = superscripts.map(
        (superscriptData: any) => new Superscript(superscriptData)
      );

      const results: ISuperscriptsCallbackResult = {
        results: allSuperscripts,
      };

      return results;
    } catch (error: any) {
      console.error("error fetch super scripts= ", error);
      throw new Error(error);
    }
  };

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const saveOrUpdateSuperScript = async (
  script: Superscript
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("error save or update script= ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let hasValidId = script.id?.length > 0;
    const action = hasValidId ? "update" : "save";
    const payload = {
      action: action,
      id: hasValidId ? script.id : undefined,
      title: script.title,
      audience: script.audience,
      niche: script.niche,
      topic: script.topic,
      style: script.style,
      scheduled_for: script.scheduled_for,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
    };

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    let result = new Superscript(data);

    const results: ISuperscriptCallbackResult = {
      results: result,
    };

    return results;
  } catch (error: any) {
    console.error("error save super script= ", error);
    throw new Error(error);
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const saveOrUpdateSuperScriptAndScenes = async (
  script: Superscript,
  scenes: ScriptScene[] = []
): Promise<ISuperscriptCallbackResult> => {
  return updateFullScript(script, scenes, true);
};

/**
 * Saves and will attempt to fork a superscript on the backend
 * @param script The superscript to save or update
 * @param scenes The scenes to save or update
 * @returns
 */
export const saveForkedScript = async (
  script: Superscript,
  scenes: ScriptScene[] = []
): Promise<ISuperscriptCallbackResult> => {
  return updateFullScript(script, scenes, false);
};

/**
 * Updates a superscript on the backend
 * @param script The superscript to save or update
 * @param scenes The scenes to save or update
 * @param skip_fork_check If true, will skip the fork check
 * @returns A promise with the results
 * @throws An error if there is an issue
 */
export const updateFullScript = async (
  script: Superscript,
  scenes: ScriptScene[] = [],
  skip_fork_check: boolean = true
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("error update full script= ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let hasValidId = script.id?.length > 0 && !script.is_local;
    const action = "save_or_update_script_and_scenes";

    let payload = {
      action: action,
      id: hasValidId ? script.id : undefined,
      title: script.title,
      audience: script.audience,
      niche: script.niche,
      topic: script.topic,
      style: script.style,
      skip_fork_check: skip_fork_check,
      user_id: script.user_id,
      created_on: script.created_on,
      metadata: { ...script.metadata, is_web: true, last_saved_on_web: true },
      organization_id: script.organization_id,
      script_info_message: script.script_info_message,
      capture_info_message: script.capture_info_message,
      request_upload: script.request_upload,
      script_assets: script.script_assets,
    };

    //now add scenes JSON
    const scenesJSON: Record<string, any>[] = [];
    //iterate over each scene and add properties ot scenesJSON
    scenes.forEach((scene: ScriptScene) => {
      let sceneJSON: {
        text_speech?: string;
        background_asset?: any;
        id?: string;
        asset_options?: any[];
      } = {
        text_speech: scene.text_speech,
      };
      if (scene.id) {
        sceneJSON["id"] = scene.id;
      }

      //new - to support content assets options
      const updateContentAssetOptions = () => {
        // update content asset options
        let options = scene.content_asset_options ?? [];
        // check if the selectedContentAsset is one of the options
        // in this case - we swap it with the current content asset with the selected asset (which is one of the options..)
        if (scene.selected_content_asset) {
          let index = options.findIndex((asset) => {
            return asset.id === scene.selected_content_asset?.id;
          });
          // move the original asset into the asset options
          if (scene.background_asset) {
            if (index > -1) {
              options[index] = scene.background_asset;
            } else if (
              scene.background_asset.id !== scene.selected_content_asset.id
            ) {
              //add it
              options.push(scene.background_asset);
            }
          }
          //update json
          sceneJSON["asset_options"] = options;
          sceneJSON["background_asset"] = scene.selected_content_asset;
        } else {
          // no changes aka no selected asset - just update the options?
          sceneJSON["asset_options"] = options;
          sceneJSON["background_asset"] = scene.background_asset;
        }
      };

      updateContentAssetOptions();

      scenesJSON.push(sceneJSON);
    });

    //@ts-ignore
    payload["scenes"] = scenesJSON;

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
    };

    // console.log("calling updateFullScript with payload = ", payload);

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const script_result_json = data.result;
    if (!script_result_json || !script_result_json.id) {
      throw new Error("No script returned from API");
    }

    let result = new Superscript(script_result_json);

    // console.log("✅ success saveOrUpdateSuperScriptAndScenes - ", result);

    const results: ISuperscriptCallbackResult = {
      results: result,
    };

    return results;
  } catch (error: any) {
    console.error("error update full script= ", error);
    throw new Error(error);
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const generateScene = async (
  scene: ScriptScene,
  script: Superscript,
  clearExistingInputs: boolean = false
): Promise<IScriptSceneCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let superpower_id = "generate_next_scene";
    const payload = {
      script_id: script.id,
      scene_id: scene.id,
      action: "generate_scene",
      superpower_id: superpower_id,
      clear_existing: clearExistingInputs ? "true" : "false",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    let result_id = data.id;

    if (!result_id) {
      throw new Error("Did not get valid scene response");
    }
    // console.log("scene id result returned = ", result_id);

    let updatedScene = new ScriptScene(data);

    const results: IScriptSceneCallbackResult = {
      results: updatedScene,
    };

    return results;
  } catch (error: any) {
    console.error("error generate scene= ", error);
    throw new Error(error);
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const generateScript = async (
  sourceUrl: string,
  source_type: string
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    // original
    // const payload = {
    //   source_url: sourceUrl,
    //   source_type: source_type,
    //   action: "generate_script",
    // };

    // updated
    const payload = {
      url: sourceUrl,
      action: "create_script",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 60000,
    };

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    let result_id = data.results ? data.results.id : data.id;

    if (!result_id) {
      throw new Error("Did not get valid script response");
    }
    // console.log("script id result returned = ", result_id);

    const script_data = data.results ? data.results : data;
    let updatedScript = new Superscript(script_data);

    const results: ISuperscriptCallbackResult = {
      results: updatedScript,
    };

    return results;
  } catch (error: any) {
    console.error("error generate scene= ", error);
    throw new Error(error);
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const duplicateScript = async (
  scriptId: string
): Promise<IDuplicateScriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      script_id: scriptId,
      action: "duplicate",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, payload, config);
    console.log("duplicate script fetch result = ", fetchResult);

    const { data } = fetchResult;

    let { script, scenes } = data.result;

    if (!script) {
      throw new Error("Did not get valid script response");
    }

    // console.log("script id result returned = ", script);

    let updatedScript = new Superscript(script);

    let updatedScenes = scenes.map((scene: any) => new ScriptScene(scene));

    const results: IDuplicateScriptCallbackResult = {
      script: updatedScript,
      scenes: updatedScenes,
    };

    return results;
  } catch (error: any) {
    console.error("error generate scene= ", error);
    throw new Error(error);
  }
};

/**
 * Merges two scripts into one.
 * @param scriptToMergeFrom The ID of the script to merge from.
 * @param scriptToMergeInto The ID of the script to merge into.
 * @returns The merged script.
 */
export const mergeScriptsIntoOneAnother = async (
  scriptToMergeFrom: string,
  scriptToMergeInto: string
): Promise<Superscript> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      script_to_merge_from: scriptToMergeFrom,
      script_to_merge_into: scriptToMergeInto,
      action: "merge_scripts",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, payload, config);
    console.log("merge scripts fetch result = ", fetchResult);

    const { data } = fetchResult;

    if (!data.result) {
      throw new Error("Did not get valid merge response");
    }

    const script_json = data.result.script;
    const scenes_json = data.result.scenes;

    const script = new Superscript(script_json);
    const scenes = scenes_json.map((scene: any) => new ScriptScene(scene));

    script.scenes = scenes;

    return script;
  } catch (error: any) {
    console.error("error merging scripts= ", error);
    throw new Error(error);
  }
};

/**
 * Imports a script into another script.
 * @param sourceScriptId The ID of the script to import from.
 * @param targetScriptId The ID of the script to import into.
 * @returns The imported script.
 */
export const importScriptIntoAnother = async (
  sourceScriptId: string,
  targetScriptId: string
): Promise<Superscript> => {
  //grab token

  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      import_source_script_id: sourceScriptId,
      import_target_script_id: targetScriptId,
      action: "import_script",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, payload, config);
    console.log("import script fetch result = ", fetchResult);

    const { data } = fetchResult;

    if (!data.result) {
      throw new Error("Did not get valid import response");
    }

    const script_json = data.result.script;
    const scenes_json = data.result.scenes;

    const script = new Superscript(script_json);
    const scenes = scenes_json.map((scene: any) => new ScriptScene(scene));

    script.scenes = scenes;

    return script;
  } catch (error: any) {
    console.error("error importing scripts= ", error);
    throw new Error(error);
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns
 */
export const sendScriptByEmail = async (
  scriptId: string,
  emails: string[],
  action: "share" | "send",
  additionalProperties: any
): Promise<IGenericSuccessCallback> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload = {
      script_id: scriptId,
      emails: emails,
      action: action,
    };

    //inject properties

    if (additionalProperties) {
      payload = { ...payload, ...additionalProperties };
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;

    return {
      success: success,
      error: success ? undefined : "Could not send email",
    };
  } catch (error: any) {
    console.error("error generate scene= ", error);
    // throw new Error(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

/**
 * Sends a superscript to a linked device
 * @param scriptId The ID of the superscript to send
 * @param userId The ID of the user to send the superscript to
 * @returns A promise with the result of the API call
 */
export const sendScriptToLinkedDevice = async (
  scriptId: string,
  userId: string
): Promise<IGenericSuccessCallback> => {
  // console.log(
  //   `sendScriptToLinkedDevice: scriptId=${scriptId}, userId=${userId}`
  // );

  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  // Setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + "superscripts";

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: "web",
    };

    let payload = {
      script_id: scriptId,
      user_id: userId,
      action: "send_script_to_linked_device",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    //post request
    const response = await axios.post(url, payload, config);
    // console.log("API response:", response);

    const { data } = response;
    const result = data.result;
    const success = result.success === true;
    const error = result.error;

    return {
      success: success,
      error: success ? undefined : error ?? "Failed to send push notification",
    };
  } catch (error: any) {
    console.error("Error sending the script:", error);
    return {
      success: false,
      error: error.message,
    };
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param scriptId The ID of the superscript to send
 * @param userIds The IDs of the users to send the script to
 * @param action The action to perform ("share" or "send")
 * @param additionalProperties Additional properties to include in the payload
 * @returns A promise with the result of the API call
 */
export const sendScriptToUser = async (
  scriptId: string,
  userId: string,
  method: "push" | "email" | "sms" = "push"
): Promise<IGenericSuccessCallback> => {
  // console.log(`sendScriptToUser: scriptId=${scriptId}, userId=${userId}`);

  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  // Setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + "superscripts";

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: "web",
    };

    let payload = {
      script_id: scriptId,
      send_to_user_id: userId,
      action: "send_script_to_user",
      send_method: method,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    //post request
    const response = await axios.post(url, payload, config);
    // console.log("API response:", response);

    const { data } = response;
    const result = data.result;
    const success = result.success === true;
    const error = result.error;

    return {
      success: success,
      error: success ? undefined : error ?? "Failed to send push notification",
    };
  } catch (error: any) {
    console.error("Error sending the script:", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns object.success = true if succesfull, object.success = false if failed
 */
export const commissionScript = async (
  scriptId: string,
  creatorIds: Record<string, boolean>,
  additionalProperties: any
): Promise<IGenericSuccessCallback> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  const creator_ids: string[] = [];
  const avatar_ids: string[] = [];
  Object.keys(creatorIds).forEach((key) => {
    const is_avatar = creatorIds[key];
    if (is_avatar) {
      avatar_ids.push(key);
    } else {
      creator_ids.push(key);
    }
  });

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload = {
      script_id: scriptId,
      creator_ids: creator_ids,
      avatar_ids: avatar_ids,
      action: "commission",
    };

    //inject properties

    if (additionalProperties) {
      payload = { ...payload, ...additionalProperties };
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 25000,
    };

    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;

    return {
      success: success,
      error: success ? undefined : "Could not commission script",
    };
  } catch (error: any) {
    console.error("error commissioning script= ", error);
    // throw new Error(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

//convert above to typescript
export const textToScript = async (
  script: Superscript,
  prompt: string
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
      "Cache-Control": "no-cache", // Add cache control headers to disable caching
    };

    const script_json = script.toJSON();
    // console.log("script_json= ", script_json);

    let payload = {
      action: "text_to_script",
      prompt: prompt,
      script: script_json,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 60000,
    };

    // console.log("textToScript payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;
    let scriptResult = data.result.script;
    // console.log("scriptResult data= ", data);

    if (!scriptResult) {
      throw new Error("Could not convert text to story");
    } else {
      return {
        results: scriptResult,
      };
    }
  } catch (error: any) {
    console.error("error converting text to story= ", error);
    throw error;
  }
};

export const deleteScript = async (
  scriptId: string
): Promise<IGenericSuccessCallback> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload = {
      action: "delete",
      id: scriptId,
      archive: true,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    // console.log("deleteScript payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;
    let scriptResult = data.script;
    // console.log("scriptResult data= ", data);

    if (!scriptResult) {
      throw new Error("Could not delete script");
    } else {
      return {
        success: true,
        error: undefined,
      };
    }
  } catch (error: any) {
    console.error("error deleting script= ", error);
    throw error;
  }
};

//import script
export const createScriptFromImportData = async (
  scenes: string[],
  auto_fetch_asset_type: "search" | "create" | "none",
  auto_fetch_model_type?: string,
  auto_fetch_prompt_prefix?: string
): Promise<ISuperscriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //print all params
  // console.log("calling createScriptFromImportData with params= ", {
  //   scenes,
  //   auto_fetch_asset_type,
  //   auto_fetch_model_type,
  //   auto_fetch_prompt_prefix,
  // });

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const scenesJSON = scenes.map((scene) => {
      return { text_speech: scene };
    });

    let payload = {
      action: "import_custom_script",
      scenes: scenesJSON,
      auto_fetch_assets_type: auto_fetch_asset_type,
      auto_fetch_model_type: auto_fetch_model_type,
      auto_fetch_prompt_prefix: auto_fetch_prompt_prefix,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 35000,
    };

    // console.log("import script result payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;
    let scriptResult = data.result;

    const script = new Superscript(scriptResult);
    // console.log("import scriptResult data= ", data);

    if (!script) {
      throw new Error("Could not create story");
    } else {
      return {
        results: script,
      };
    }
  } catch (error: any) {
    console.error("error creating script from import = ", error);
    throw error;
  }
};

//create from prompt
export const createScriptFromPrompt = async (
  prompt: string
): Promise<ISuperscriptCallbackResult> => {
  //call master function with default params
  const result = await createScriptMasterFunction(
    undefined,
    undefined,
    undefined,
    undefined,
    prompt
  );
  return { results: result.script };
};

//create from url
export const createScriptFromURL = async (
  sourceURL: string,
  linkTitle: string
): Promise<{
  script: Superscript | undefined;
  recipe: ScriptRecipe | undefined;
}> => {
  //call master function with default params

  const result = await createScriptMasterFunction(
    sourceURL,
    linkTitle,
    undefined,
    undefined,

    undefined
  );
  return result;
};

const createScriptMasterFunction = async (
  sourceURL: string | undefined,
  linkTitle: string | undefined,
  contentFormatEpisode: ContentFormatEpisode | undefined,
  trainingDataId: string | undefined,
  freeTextPrompt: string | undefined
): Promise<{
  script: Superscript | undefined;
  recipe: ScriptRecipe | undefined;
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //print all params
  console.log("calling createScriptMasterFunction with params= ", {
    sourceURL,
    linkTitle,
    contentFormatEpisode,
    trainingDataId,
    freeTextPrompt,
  });

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
      "Cache-Control": "no-cache", // Add cache control headers to disable caching
    };

    let payload: any = {
      action: "create_script",
      url: sourceURL,
      summary_style: "human_like",
      v2: true,
      check_for_listicle: true,
    };

    if (contentFormatEpisode) {
      payload["content_format_id"] = contentFormatEpisode.content_format_id;
      payload["content_format_episode_id"] = contentFormatEpisode.id;
    }

    if (linkTitle) {
      payload["link_title"] = linkTitle;
    }

    if (freeTextPrompt) {
      payload["free_text_prompt"] = freeTextPrompt;
    }

    if (trainingDataId) {
      payload["training_data_id"] = trainingDataId;
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 90000,
    };

    console.log("create script master payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);

    const { data, status } = fetchResult;

    console.log("fetch result = ", fetchResult);

    let success = status === 200;
    let response_result = data.results;

    if (response_result && response_result.script) {
      const script = new Superscript(response_result.script);
      if (!script) {
        let customMessage = parseErrorMessage(response_result);
        if (customMessage) {
          throw new Error(customMessage);
        } else {
          throw new Error("Could not create story");
        }
      } else {
        return {
          script: script,
          recipe: undefined,
        };
      }
    } else if (response_result && response_result.recipe) {
      const recipe = new ScriptRecipe(response_result.recipe);
      if (!recipe) {
        throw new Error("Could not create recipe");
      } else {
        return {
          script: undefined,
          recipe: recipe,
        };
      }
    } else {
      throw new Error("Could not create story");
    }
  } catch (error: any) {
    console.error("create script master function error:", error);
    // try to get response body
    const responseBody = error.response?.data;
    console.log("responseBody= ", responseBody);
    // attempt to parse custom message
    const customMessage = responseBody.error.message;
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const createScriptWithForm = async (
  form: FormModel,
  contentFormatEpisode: ContentFormatEpisode | undefined
): Promise<{
  script: Superscript | undefined;
  recipe: ScriptRecipe | undefined;
}> => {
  //call master function with default params
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //print all params
  console.log("calling createScriptWithForm with params= ", {
    form,
  });

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload: any = {
      action: "create_with_form",
      form: form,
    };

    if (contentFormatEpisode) {
      payload["content_format_id"] = contentFormatEpisode.content_format_id;
      payload["content_format_episode_id"] = contentFormatEpisode.id;
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 90000,
    };

    console.log("create script w/form payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);

    const { data, status } = fetchResult;

    // console.log("fetch result = ", fetchResult);

    let success = status === 200;
    let response_result = data.results;

    if (response_result && response_result.script) {
      const script = new Superscript(response_result.script);
      if (!script) {
        throw new Error("Could not create story");
      } else {
        return {
          script: script,
          recipe: undefined,
        };
      }
    } else if (response_result && response_result.recipe) {
      const recipe = new ScriptRecipe(response_result.recipe);
      if (!recipe) {
        throw new Error("Could not create recipe");
      } else {
        return {
          script: undefined,
          recipe: recipe,
        };
      }
    } else {
      throw new Error("Could not create story");
    }
  } catch (error: any) {
    console.error("create script with form error:", error);
    throw error;
  }
};

export const updateScriptFolder = async (
  scriptId: string,
  folderId: string
): Promise<Superscript> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    if (!token) {
      throw new Error("No auth token available");
    }

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload: any = {
      action: "update_script_folder",
      folder_id: folderId ?? null,
      script_id: scriptId,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
    };

    console.log("update script folder payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);

    const { data, status } = fetchResult;
    const { results } = data;
    const { script } = results;
    // console.log("fetch result = ", fetchResult);

    let success = status === 200;

    if (script) {
      const script_result = new Superscript(script);
      if (!script || !script.id) {
        throw new Error("Could not update folder");
      } else {
        return script_result;
      }
    }
    throw new Error("Could not update folder");
  } catch (error: any) {
    console.error("error updating script = ", error);
    throw error;
  }
};

export const updateScriptPermissionSettings = async (
  scriptId: string,
  access: "public" | "private" | "unlisted",
  permission: "owner" | "editor" | "viewer"
): Promise<Superscript> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    if (!token) {
      throw new Error("No auth token available");
    }

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload: any = {
      action: "update_script_permission_settings",
      script_id: scriptId,
      access: access,
      permission: permission,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
    };

    console.log("update script permission settings payload= ", payload);
    const fetchResult = await axios.post(url, payload, config);

    const { data, status } = fetchResult;
    const { results } = data;
    const { script } = results;
    // console.log("fetch result = ", fetchResult);

    let success = status === 200;

    if (script) {
      const script_result = new Superscript(script);
      if (!script || !script.id) {
        throw new Error("Could not update story permission settings");
      } else {
        return script_result;
      }
    }
    throw new Error("Could not update story permission settings");
  } catch (error: any) {
    console.error("error updating story = ", error);
    throw error;
  }
};

/**
 * Fetches assets associated with a script
 * @param scriptId The ID of the script to fetch assets for
 * @returns Promise with the script assets
 */
export const fetchScriptAssets = async (scriptId: string): Promise<any> => {
  const token = await firebase.auth().currentUser?.getIdToken(true);
  console.log("Fetching script assets for scriptId:", scriptId);

  if (!token) {
    console.error("No auth token available");
    throw new Error("No auth token available");
  }

  try {
    const url = API_BASE_URL + "superscripts";
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: "web",
    };

    const config: AxiosRequestConfig = {
      headers,
      timeout: 15000,
      params: {
        action: "fetch_script_assets",
        script_id: scriptId,
      },
    };

    const response = await axios.get(url, config);
    const { data } = response;
    console.log("Response received:", data);

    if (!data.results) {
      console.error("Could not fetch script assets");
      throw new Error("Could not fetch script assets");
    }

    console.log("Script assets fetched successfully:", data.results);
    return data.results;
  } catch (error: any) {
    console.error("Error fetching script assets:", error);
    throw error;
  }
};
