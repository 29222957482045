import React, { Component, useEffect, useState } from "react";
import { AnalyticsEvent, analytics } from "analytics/analytics";
import { Box, Container } from "@mui/system";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme, withStyles, withTheme } from "@mui/styles";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { connect } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NotificationManager } from "react-notifications";
import { sendRequestAccessToResource } from "services/resource-permission-scope.service";
import { showAuth } from "actions";
import { Helmet } from "react-helmet";
import AppleBadge from "../../../assets/Images/mobile_app_store_badge.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
  },
  container: {
    width: "100%",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "20px",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#212121",
  },
  subtitle: {
    marginTop: 15,
    fontSize: 16,
    color: "#212121",
    fontWeight: 450,
  },
  primaryButton: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "none",
    background: "#0C1DB8",
    borderRadius: "20px",
    height: "52px",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover": {
      background: "#B9C0FA",
    },
  },
  primaryButtonOutlined: {
    width: "100%",
    background: "none !important",
    border: "1px solid #0C1DB8",
    color: "#0C1DB8",
    height: "52px",
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: 14,
    fontWeight: "bold",
  },
  appstoreButton: {
    width: "156px",
    height: "52px",
    padding: "0px",
  },
  loginToViewDesktop: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "50vh",
    background:
      "-webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%)",
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    justifyContent: "center",
    alignItems: "center",
  },
  loginToViewMobile: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "-webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 65%)",
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const OnlyiOSScreen = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} id="only-mobile-app-blocker-screen">
      <LoginToViewMobile {...props} />
    </Box>
  );
};

const LoginToViewMobile = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const source_url = urlParams.get("source_url");

  //log event on show
  useEffect(() => {
    analytics.logEvent("web - mobile only screen - shown", {
      source_url: source_url ?? "unknown",
    });
  }, []);

  const onLogoButtonClicked = () => {
    console.log("logo button clicked");
    //get the source_url from the props

    analytics.logEvent("web - mobile only screen - logo button clicked", {
      source_url: source_url ?? "unknown",
    });
    //@todo - make special appsflyer link?
    let url = "https://supercreator.ai/ios";
    window.open(url, "_blank");
  };

  const classes = useStyles();
  return (
    <Box textAlign="center" className={classes.loginToViewMobile} sx={{}}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={1}
      >
        <Box className={classes.title}>
          {/* Open link via the Supercreator iOS app */}
          App Only Link
        </Box>
        <Box className={classes.subtitle}>
          Open link with the Supercreator iOS app
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          sx={{
            marginTop: "30px",
          }}
        >
          <Box width="100%">
            <Button
              className={classes.appstoreButton}
              onClick={onLogoButtonClicked}
            >
              <img width="100%" height="100%" alt="download" src={AppleBadge} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ authUser }) => {
  return {
    needsLogin: authUser.isAuthed === false,
  };
};
export default connect(mapStateToProps, { showAuth })(OnlyiOSScreen);
