/**
 * User Settings App Actions
 */

import {
  EMAIL_ON_SWITCH_CHANGE,
  COPY_INVITE_LINK,
  UPDATED_USER_PROFILE_DETAILS,
} from "./Types";
import { NotificationManager } from "react-notifications";

/*
 * Function to update on switch changes in email settings
 */
export const emailSwitchChange = (key, value) => ({
  type: EMAIL_ON_SWITCH_CHANGE,
  payload: { key, value },
});

export const copyInviteLink = (text) => {
  navigator.clipboard.writeText(`https://supercreator.ai/?vip=${text}`);
  NotificationManager.success("Invite link copied to clipboard");
};

export const updateAfterSavedProfile = (user, accountDetails) => (dispatch) => {
  // console.log("updateAfterSavedProfile called for user:", user);
  dispatch({
    type: UPDATED_USER_PROFILE_DETAILS,
    payload: { user, accountDetails },
  });
};
