import * as React from "react";

import parse, { attributesToProps, Element } from "html-react-parser";
import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { Mission } from "kre8tv/model/VLMission";
import { AIAbility } from "kre8tv/model/AIAbility";
import { RecipeIngredient, Recipe } from "kre8tv/model";

var Airtable = require("airtable");
var _ = require("lodash");

/**
 * Editor Manager
 * Handles operations in regards to editing and updating missions, ability/superpowers, recipes on backend
 */
export class EditorManager {
  base?: any;
  constructor() {}

  /**
   *
   * @param input String input which is effectively the template string
   * @returns An array of objects containing the props of the elements
   */
  getTokensFromInput(input: string) {
    let results: any[] = [];
    const options = {
      replace: (domNode: any) => {
        if (domNode.attribs && domNode.name === "token") {
          const props = attributesToProps(domNode.attribs);
          if (props.id && props.id.length > 0) {
            results.push(props);
          }
        }
      },
      lowerCaseTags: false,
    };

    let parseInput = parse(input, options);

    return results;
  }

  //regex = \${\/?[\w\s]*}|.[]>
  replaceAllVariableWithValue(input: string, variable: string, value: string) {
    const results = input.replaceAll(`{{${variable}}}`, value);
    return results;
  }

  /**
   * Create a new Ability/Superpower from a recipe and optional ability
   * @param recipe
   * @param existingAbility
   * @returns
   */
  convertRecipeToAbility(recipe: Recipe, existingAbility?: AIAbility) {
    let ability = new AIAbility({});
    //copy existing values if they exist
    ability.id = existingAbility?.id ?? "temp";
    ability.title = existingAbility?.title ?? recipe.title;
    ability.description = existingAbility?.description ?? recipe.description;
    ability.status = existingAbility?.status ?? ability.status;
    ability.last_updated =
      existingAbility?.last_updated ?? ability.last_updated;
    ability.created_on = existingAbility?.created_on ?? ability.created_on;
    ability.path = existingAbility?.path ?? ability.path;
    ability.icon = existingAbility?.icon ?? ability.icon;
    ability.platform = existingAbility?.platform ?? ability.platform;

    //set recipe to ability
    ability.recipe = recipe;

    return ability;
  }

  /**
   * Convert the recipe alongside the user input to a strictly text based prompt to send to open ai / backend
   * What happens here is that we create a "clean" version of the "formatted" prompt (which initially includes <recipe> and <token> elements for defining input/controls)
   * @param input
   */
  generateInstructions(input: string, ingredients: RecipeIngredient[]) {
    var root = document.createElement("div");
    const rootInput = document.createElement("div");
    rootInput.innerHTML = input;
    root.appendChild(rootInput);
    const tokenElements = root.getElementsByTagName("token");
    const recipeElements = rootInput.getElementsByTagName("recipe");
    let recipe = recipeElements[0];

    var arr = [].slice.call(tokenElements);

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];

      const id =
        (element as globalThis.Element)?.attributes.getNamedItem("id")?.value ??
        "";

      let ingredientIndex = ingredients.findIndex(
        (i: RecipeIngredient) => i.id === id
      );
      let ingredient = ingredients[ingredientIndex];
      const value =
        ingredient.value ??
        (element as globalThis.Element)?.attributes.getNamedItem("value")
          ?.value ??
        "";

      const textNode = document.createTextNode(value);

      recipe.replaceChild(textNode, element);
    }

    return recipe.innerHTML.trim();
  }

  /**
   * Convert the recipe alongside the user input to a strictly text based prompt to send to open ai / backend
   * @param input
   */
  generateRecipeAIProperties(input: string) {
    var root = document.createElement("div");
    const rootInput = document.createElement("div");
    rootInput.innerHTML = input;
    root.appendChild(rootInput);
    const recipeElements = root.getElementsByTagName("recipe");
    let properties: any = {};

    for (let index = 0; index < recipeElements.length; index++) {
      const element = recipeElements[index];
      for (
        let propIndex = 0;
        propIndex < element.attributes.length;
        propIndex++
      ) {
        const node = element.attributes[propIndex];
        properties[node.nodeName] = node.nodeValue;
      }
    }
    return properties;
  }

  airtable = new AirtableManager();
  /**
   * Update a recipe
   * @param recipe Ther recipe to update on the server
   * @param callback
   */
  saveRecipe(recipe: Recipe, callback: (result?: Recipe, error?: any) => void) {
    this.airtable.saveRecipe(recipe, (result?: Recipe, error?: any) => {
      if (result) {
        callback(result, undefined);
      } else {
        console.log("saveRecipe error - ", error);
        callback(result, "Could not save recipe");
      }
    });
  }

  /**
   * Creates a recipe and saves it to airtable
   * @param recipe The receipe to create
   * @param callback Callback with a result and error values (if exists)
   */
  createRecipe(
    recipe: Recipe,
    callback: (result?: Recipe, error?: any) => void
  ) {
    this.airtable.createRecipe(recipe, (result?: Recipe, error?: any) => {
      if (result) {
        callback(result, undefined);
      } else {
        console.log("saveRecipe error - ", error);
        callback(result, "Could not create recipe");
      }
    });
  }
  /**
   * Fetches an ability/superpower from airtable
   * @param id The id of the superpower
   * @param callback Callback function with the result, error if exists
   */
  fetchAbility(
    id: string,
    callback: (result?: AIAbility, error?: any) => void
  ) {
    this.airtable.fetchSuperpower(id, callback);
  }

  fetchMission(id: string, callback: (result?: Mission, error?: any) => void) {
    this.airtable.fetchMission(id, callback);
  }
}
