export class FormModelAnswer {
  id = "";
  value: any;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.value = obj.value;
  }
}
