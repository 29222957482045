/**
 * App Config File - Where all of the configuration related stuff for the current session is like main texts, colors, etc exist
 * This is meant for non-sensitive data
 */

const AppConfig = {
  brandName: "Supercreator.ai",
  copyRightText: "2021 CLB CREATIVE LTD, All Rights Reserved.",
  navCollapsed: true,
  mobileSidebarToggle: false,
  isDarkModeActive: false,
  isRtlActive: false,
  isHorizontalMenuActive: false,
  isMiniSidebarActive: false,
  selectedThemeColor: "light-theme",
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "usa.png",
  },
  // Default Theme Colors
  lightThemeColors: {
    primary: "#0C1DB8",
    secondary: "#FF7882",
    success: "#00d41a",
    info: "#00d0bd",
    warning: "#ffb70f",
    error: "#e53935",
    textPrimary: "#29303b",
    textSecondary: "#717275",
    divider: "#e0e0e0",
    icon: "#717275",
    bgPaper: "#FFFFFF",
    bgDefault: "#EBF1F4",
    white: "#ffffff",
    black: "#212121",
    primaryLight: "#9163f0",
  },
  // Teal Theme Colors
  tealThemeColors: {
    primary: "#028484",
    secondary: "#d99830",
    success: "#00d41a",
    info: "#00d0bd",
    warning: "#ffb70f",
    error: "#e64c3d ",
    textPrimary: "#29303b",
    textSecondary: "#717275",
    divider: "#e0e0e0",
    icon: "#717275",
    bgPaper: "#FFFFFF",
    bgDefault: "#f3f7fa",
    white: "#ffffff",
    black: "#000000",
  },
  // Violet Theme Colors
  violetThemeColors: {
    primary: "#53419A",
    secondary: "#F0bc02",
    success: "#ff8600",
    info: "#00d0bd",
    warning: "#ffb70f",
    error: "#e53935",
    textPrimary: "#29303b",
    textSecondary: "#717275",
    divider: "#e0e0e0",
    icon: "#717275",
    bgPaper: "#FFFFFF",
    bgDefault: "#f3f7fa",
    white: "#ffffff",
    black: "#000000",
  },
  // Dark Theme Colors
  darkThemeColors: {
    primary: "#9163f0",
    secondary: "#ff8600",
    success: "#00d41a",
    info: "#00d0bd",
    warning: "#ffb70f",
    error: "#e53935",
    textPrimary: "#9d9daf",
    textSecondary: "#717275",
    divider: "rgba(255,255,255,0.12)",
    icon: "#717275",
    bgPaper: "#27293c",
    bgDefault: "#1e1e2e",
    white: "#ffffff",
    black: "#000000",
  },
};

export default AppConfig;
