//MARK: Abilities

import { Recipe } from "./VLRecipe";

/**
 * AIAbility consists of input, output and an integration in order to produce the "ability" aka "superpower".
 * For example, create an amazingl LinkedIn post, where input would be text and output would  be text, integreation could be openai gpt3
 */
export class AIAbility {
  id = "";
  title: string;
  description: string;
  status: string;
  last_updated: number;
  created_on: number;
  path: string;
  icon: string;
  emoji: string;
  platform: string;

  recipe_id?: string;

  recipe?: Recipe;

  input: AbilityInputType[] = ["text"];
  output: AbilityInputType[] = ["text"];
  integration: string;

  color: string;
  airtable_record_id?: string;

  //superhero details (temporary)
  superhero_image_url?: string;
  superhero_tone_name?: string;
  superhero_tone_title?: string;

  //decorators
  background_color: string = "";
  label?: string;
  featured?: boolean = false;
  sceneToPowerup?: any;

  constructor(data?: any) {
    this.id = data["id"];
    this.airtable_record_id = data["airtable_record_id"];

    this.title = data["title"];
    this.description = data["description"];
    this.status = data["status"];
    this.last_updated = data["last_updated"];
    this.created_on = data["created_on"];
    this.path = data["path"];
    this.icon = data["icon"];
    this.emoji = data["emoji"];
    this.platform = data["platform"];

    this.input = data["input"] ?? [];
    this.output = data["output"] ?? [];

    this.integration = data["integration"];

    this.color = data["color"] ?? "#f4f6f8";
    this.background_color =
      data["background_color"] ??
      "linear-gradient(123.44deg, #CAD8E0 2.91%, #FFB196 98.99%, rgba(234, 161, 135, 0) 99%);";

    if (data["recipe_id"]) {
      this.recipe_id = data["recipe_id"];
    }

    this.label = data["beta"] && data["beta"] === true ? "beta" : undefined;
    this.featured = data["featured"];

    /* If base recipe info exists, retrieve it and attempt to parse the full recipe object */
    if (this.recipe_id && data["prompt"]) {
      const recipe_prompt = data["prompt"][0];
      this.recipe = new Recipe({
        id: this.recipe_id,
        prompt: recipe_prompt,
      });
    }
  }

  matchesQuery(query: string) {
    return (
      this.title.toLowerCase().indexOf(query) > -1 ||
      this.description.toLowerCase().indexOf(query) > -1
    );
  }

  isAddSuperpower() {
    return this.id === "client_add";
  }
}

export const SuperpowerTone = () => {
  const tone = new AIAbility({
    id: "change_tone",
  });
  return tone;
};
