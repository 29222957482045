export class WizardSection {
  id: string;
  title: string;
  detail: string;
  options: string[] = [];
  selectedOption: string;
  selectedOptions: string[] = [];
  required = true;
  handle_navigation_on_server = false;

  allow_input = false;
  allow_more_options = false;
  allow_edit_options = false;

  allow_multi_select_to_count?: number;
  multi_select_min_required?: number;

  constructor(obj?: any) {
    //general
    this.id = obj.id;
    this.title = obj.title;
    this.detail = obj.detail;
    this.options = obj.options;
    this.selectedOption = obj.selectedOption;
    this.required = obj.required ?? true;
    this.selectedOption = obj.selectedOption;
    this.selectedOptions = obj.selectedOptions ?? [];
    this.handle_navigation_on_server = obj.handle_navigation_on_server ?? false;

    this.allow_input = obj.allow_input ?? false;
    this.allow_more_options = obj.allow_more_options ?? false;
    this.allow_edit_options = obj.allow_edit_options ?? false;

    this.allow_multi_select_to_count = obj.allow_multi_select_to_count;
    this.multi_select_min_required = obj.multi_select_min_required;
  }

  /**
   * Minimum number of options required to be selected
   * @returns A number representing the minimum number of options required to be selected - 0 if not a multi select section or if no minimum is set
   */
  minimumSelectionRequired(): number {
    return this.multi_select_min_required ?? 0;
  }

  /**
   * Is this a multi select section
   * @returns True if this is a multi select section
   */
  isMultiSelect(): boolean {
    return (
      this.allow_multi_select_to_count !== undefined &&
      this.allow_multi_select_to_count > 0
    );
  }

  /**
   * Checks if the user can select more options
   * @returns True if the user can select more options
   */
  canSelectMoreOptions(): boolean {
    return (
      this.isMultiSelect() &&
      this.selectedOptions !== undefined &&
      this.selectedOptions.length < (this.allow_multi_select_to_count ?? 10)
    );
  }

  /**
   * Determines if the section is completed
   * @returns True if the section is completed
   */
  isCompleted(): boolean {
    if (
      this.allow_multi_select_to_count !== undefined &&
      this.isMultiSelect()
    ) {
      return (
        this.selectedOptions.length >= this.minimumSelectionRequired() &&
        this.selectedOptions.length <= this.allow_multi_select_to_count
      );
    } else if (this.selectedOption !== undefined) {
      return true;
    }

    return false;
  }
}
