/**
 * Chat App Actions
 */
import {
  FETCHED_GENERATIONS,
  FETCH_MY_GENERATIONS,
  UPDATE_GENERATION,
} from "actions/Types";
import { GenerationManager } from "kre8tv/managers/VLGenerationManager";

import { NotificationManager } from "react-notifications";

import { store } from "store";

var _ = require("lodash/core");

export function fetchCurrentUserGenerations() {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_MY_GENERATIONS,
      });
      let generationManager = new GenerationManager();
      const fetchResults =
        await generationManager.fetchCurrentUserGenerations();
      dispatch({
        type: FETCHED_GENERATIONS,
        payload: {
          generations: fetchResults,
          scripts: store.getState().superscriptsReducer.superscripts,
        },
      });
      return fetch;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export const updateGeneration = (generation) => (dispatch) => {
  dispatch({
    type: UPDATE_GENERATION,
    payload: generation,
  });
};
