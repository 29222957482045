import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import {
  ContentAsset,
  Organization,
  ResourcePermissionInvitation,
  ResourcePermissionScope,
  TextToImageModel,
  User,
  parseVLError,
} from "kre8tv/model";
import { UserToResourcePermissionScope } from "./resource-permission-scope.service";

/**
 * Saves a user asset to the server
 * @param asset The asset to save
 * @returns The saved asset
 */
export const createOrganization = async (
  name: string
): Promise<{ organization: Organization }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `organizations`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "create_organization",
      organization: {
        name: name,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("createOrganization fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const savedOrganization = new Organization(result.organization);
    if (!savedOrganization || !savedOrganization.id) {
      throw new Error("error creating organization");
    }

    return { organization: savedOrganization };
  } catch (error: any) {
    console.error("error creating organization= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const getUserOrganizations = async (): Promise<{
  organizations: Organization[];
}> => {
  try {
    const url = API_BASE_URL + `organizations`;

    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    if (!token) throw new Error("User not logged in");

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "get_user_organizations",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: params,
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getUserOrganizations fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const organizations = result.organizations.map(
      (organization: Organization) => new Organization(organization)
    );

    return { organizations };
  } catch (error: any) {
    console.error("error getting user organizations= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const getCurrentUserOrganization = async (
  organization_id: string
): Promise<{
  organization: Organization;
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  try {
    const url = API_BASE_URL + `organizations`;

    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    if (!token) throw new Error("User not logged in");

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "get_current_organization",
      organization_id: organization_id,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000, // 30 seconds
      params: params,
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getCurrentUserOrganization fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;
    const { organization, user_to_resource_permission_scopes } = result;

    let userToResourcePermissionScope: UserToResourcePermissionScope[] = [];
    //attempt to parse each user and permission scope in the array
    user_to_resource_permission_scopes.forEach((item: any) => {
      const { user, resource_permission_scope } = item;
      const parsedUser = new User(user);
      const parsedResourcePermissionScope = new ResourcePermissionScope(
        resource_permission_scope
      );
      if (
        parsedUser.id.length > 0 &&
        parsedResourcePermissionScope.id.length > 0
      ) {
        userToResourcePermissionScope.push(
          new UserToResourcePermissionScope(
            parsedUser,
            parsedResourcePermissionScope
          )
        );
      }
    });

    const invitations =
      result.invitations &&
      result.invitations.map(
        (invitation: any) => new ResourcePermissionInvitation(invitation)
      );

    //sort userToResourcePermissionScope by the created_on of the resource_permission_scope
    userToResourcePermissionScope.sort((a, b) => {
      return (
        a.resource_permission_scope.created_on -
        b.resource_permission_scope.created_on
      );
    });

    //sort invitations by the created_on of the invitation
    invitations.sort(
      (a: ResourcePermissionInvitation, b: ResourcePermissionInvitation) => {
        return a.created_on - b.created_on;
      }
    );

    return {
      organization: new Organization(organization),
      user_resource_permission_scopes: userToResourcePermissionScope,
      invitations,
    };
  } catch (error: any) {
    console.error("error getting current user organizations= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
export const changeUserOrganization = async (
  organization_id?: string
): Promise<{
  organization?: Organization;
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  try {
    const url = API_BASE_URL + `organizations`;

    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    if (!token) throw new Error("User not logged in");

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "change_current_organization",
      organization_id: organization_id,
    };

    //generate rest of params
    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("changeUserOrganization fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { organization, user_to_resource_permission_scopes } = result;

    let userToResourcePermissionScope: UserToResourcePermissionScope[] = [];
    //attempt to parse each user and permission scope in the array
    user_to_resource_permission_scopes.forEach((item: any) => {
      const { user, resource_permission_scope } = item;
      const parsedUser = new User(user);
      const parsedResourcePermissionScope = new ResourcePermissionScope(
        resource_permission_scope
      );
      if (
        parsedUser.id.length > 0 &&
        parsedResourcePermissionScope.id.length > 0
      ) {
        userToResourcePermissionScope.push(
          new UserToResourcePermissionScope(
            parsedUser,
            parsedResourcePermissionScope
          )
        );
      }
    });

    const invitations =
      result.invitations &&
      result.invitations.map(
        (invitation: any) => new ResourcePermissionInvitation(invitation)
      );

    //sort userToResourcePermissionScope by the created_on of the resource_permission_scope
    userToResourcePermissionScope.sort((a, b) => {
      return (
        a.resource_permission_scope.created_on -
        b.resource_permission_scope.created_on
      );
    });

    //sort invitations by the created_on of the invitation
    invitations.sort(
      (a: ResourcePermissionInvitation, b: ResourcePermissionInvitation) => {
        return a.created_on - b.created_on;
      }
    );

    return {
      organization: organization ? new Organization(organization) : undefined,
      user_resource_permission_scopes: userToResourcePermissionScope,
      invitations,
    };
  } catch (error: any) {
    console.error("error changing user organization= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
/**
 * Updates the name of an organization
 * @param id Id of the organization to update
 * @param name New name of the organization
 * @returns Created organization, or throws an error
 */
export const updateOrganizationName = async (
  id: string,
  name: string
): Promise<{ organization: Organization }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `organizations`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "update_organization",
      organization: {
        id: id,
        name: name,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("updateOrganizationName fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const updatedOrganization = new Organization(result.organization);
    if (!updatedOrganization || !updatedOrganization.id) {
      throw new Error("error updating organization");
    }

    return { organization: updatedOrganization };
  } catch (error: any) {
    console.error("error updating organization= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

export const getOrganizationDetails = async (
  organization_id: string
): Promise<{ organization: Organization; owner: User }> => {
  try {
    const url = API_BASE_URL + `organizations`;

    console.log("getOrganizationDetails organization_id= ", organization_id);

    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    if (!token) throw new Error("User not logged in");

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "get_organization_details",
      id: organization_id,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000, // 30 seconds
      params: params,
    };

    const fetchResult = await axios.get(url, config);

    console.log("getOrganizationDetails fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;
    const { organization, owner } = result;

    return {
      organization: new Organization(organization),
      owner: new User(owner),
    };
  } catch (error: any) {
    console.error("error getting organization details= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
