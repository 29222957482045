import firebase from "firebase";
import { AvatarGeneration } from "kre8tv/model/avatar-generation.model";
import { cleanObject } from "util/SCUtils";
import { DB_AVATAR_GENERATIONS } from "./DBConstants";
import { orderBy } from "lodash";

export class GenerationManager {
  base?: any;
  firestore = firebase.firestore();

  async fetchCurrentUserGenerations() {
    const db = this.firestore.collection(DB_AVATAR_GENERATIONS);

    try {
      let generations: AvatarGeneration[] = [];
      const user_id = firebase.auth().currentUser?.uid;
      const snapshot = await db.where("user_id", "==", user_id).get();
      snapshot.forEach((doc) => {
        //only store if generations is a valid object
        let generation = AvatarGeneration.fromJSON(doc.data());
        if (generation) generations.push(generation);
      });
      //order by created_on for now
      generations = orderBy(generations, ["created_on"], ["desc"]);
      return generations;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  }
}
