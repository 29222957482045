/**
 * Short form video Actions
 */
import { UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS } from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { NotificationManager } from "react-notifications";
import { ShortFormVideoManager } from "kre8tv/managers";

const shortFormVideoManager = new ShortFormVideoManager();

var _ = require("lodash/core");

/**
 * Fetch all recently created scripts
 */
export const getRecentlyCreatedShortFormVideos = () => {
  return async (dispatch) => {
    try {
      let videos =
        await shortFormVideoManager.fetchRecentlyCreatedShortFormVideos();
      if (videos) {
        dispatch({
          type: UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS,
          payload: videos,
        });
      } else {
        throw new Error("No short form videos found");
      }
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
};
