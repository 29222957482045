/**
 * Chat App Actions
 */
import {
  GET_CREATIONS,
  FAVORITE_CREATION,
  UNFAVORITE_CREATION,
  GET_FAVORITED_CREATIONS,
} from "actions/Types";
import { CreationManager } from "kre8tv/managers/VLCreationManager";

import { NotificationManager } from "react-notifications";
import { fetchCreations, fetchFavoritedCreations } from "services";
import { store } from "store";

var _ = require("lodash/core");

/**
 * Fetch all abilities
 */
export const copyCreationResultToClipoard = (text) => {
  navigator.clipboard.writeText(text);
  NotificationManager.success(text, "Succesfully copied to clipoard");
};

export function fetchCurrentUserCreations() {
  return async (dispatch) => {
    try {
      const fetchResults = await fetchCreations();
      dispatch({
        type: GET_CREATIONS,
        payload: fetchResults,
      });
      return fetchResults;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export function fetchCurrentUserFavoritedCreations() {
  return async (dispatch) => {
    try {
      const fetchResults = await fetchFavoritedCreations();
      dispatch({
        type: GET_FAVORITED_CREATIONS,
        payload: fetchResults,
      });
      return fetchResults;
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
}

export const favoriteCreation = (creation) => (dispatch) => {
  //execute first for smooth appearance on UI
  dispatch({
    type: FAVORITE_CREATION,
    payload: creation,
  });

  //run logic
  let creationManager = new CreationManager();
  creationManager.favoriteCreationResult(creation, true, (success) => {
    //dispatch changes
    if (success) {
      return;
    } else {
      NotificationManager.error("Could not favorite creation");
    }
  });
};

export const unfavoriteCreation = (creation) => (dispatch) => {
  //execute first for smooth appearance on UI
  dispatch({
    type: UNFAVORITE_CREATION,
    payload: creation,
  });

  //run logic
  let creationManager = new CreationManager();
  creationManager.favoriteCreationResult(creation, false, (success) => {
    //dispatch changes
    if (success) {
      //dispatch logic
      return;
    } else {
      dispatch({
        type: FAVORITE_CREATION,
        payload: creation,
      });
      NotificationManager.error("Could not unfavorite creation");
    }
  });
};

export const isFavorited = (creation) => (dispatch) => {
  let favorites = store.getState().creationsReducer.favorites;
  //check if it contains this creation
  let is_favorite = favorites[creation.id] ? true : false;
  // console.log(
  //   `creation ${creation.id} is ${is_favorite ? "favorited" : "not favorite"}`
  // );
  return is_favorite;
};
