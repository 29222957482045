import { IEntity } from "./interfaces/entity.interface";

/**
 * A scene object used in scripts
 */
export class NewsItem {
  /**
   * title of script
   */
  title = "";
  /**
   * Created on date
   */
  date: number = 0;
  /**
   * Last updated by user id
   */
  url: string = "";

  /**
   * Last updated by user id
   */
  source: string = "";

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.title = obj.title;
    this.date = Number(obj.timestamp ? obj.timestamp : 0);
    this.url = obj.url;
    this.source = obj.source;
  }

  createdOnString(): string {
    let date = new Date(this.date);
    return `${date.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })}`;
  }
}
