/*
 * App Routes
 *
 */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// router service
import routerService from "../services/_routerService";

class DefaultLayout extends Component {
  render() {
    const { match } = this.props;
    return (
      routerService &&
      routerService.map((route, key) => (
        <Route key={key} path={`/${route.path}`} component={route.component} />
      ))
    );
  }
}

export default withRouter(connect(null)(DefaultLayout));
