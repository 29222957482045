import firebase from "firebase";
import {
  IWizardSectionsCallbackResult,
  IWizardSectionCallbackResult,
  ICreateScriptCallbackResult,
  IWizardScriptRecipeCallbackResult,
  IWizardStartCallbackResult,
} from "kre8tv/model/interfaces";
import { WizardSection } from "kre8tv/model/wizard-section.model";
import {
  createScript,
  createScriptRecipe,
  loadMoreOptionsForSection,
  submitOptionAndGetNext,
} from "services";

import { store } from "store";
import { cleanObject } from "util/SCUtils";

export class ScriptWizardManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  /**
   * Creates a script recipe from a summary and its sources
   * Then uses these to create the script recipe object
   * @returns A script recipe object
   */
  async createScriptRecipe(
    sources: string[],
    summary: string,
    source_text?: string
  ): Promise<IWizardScriptRecipeCallbackResult | undefined> {
    try {
      const result = await createScriptRecipe(sources, summary, source_text);
      return result;
    } catch (error: any) {
      return undefined;
    }
  }

  /**
   * Fetches the wizard sections for the creation script wizard
   * @returns
   */
  async loadMoreOptions(
    section: WizardSection,
    scriptRecipeId: string
  ): Promise<IWizardSectionCallbackResult | undefined> {
    try {
      if (!section) throw new Error("No section provided");
      const result = await loadMoreOptionsForSection(section, scriptRecipeId);
      return result;
    } catch (error: any) {
      return undefined;
    }
  }

  /**
   * Fetches the wizard sections for the creation script wizard
   * @returns
   */
  async submitOptionAndGetNextSection(
    scriptRecipeId: string,
    scriptRecipeGoalType: string,
    section: WizardSection,
    nextSectionId: string
  ): Promise<IWizardSectionCallbackResult | undefined> {
    try {
      if (section.isMultiSelect()) {
        const selection_options = section.selectedOptions;
        if (!selection_options || selection_options.length === 0) {
          return Promise.reject("No options selected");
        }
      } else {
        const selection_option = section.selectedOption;
        if (!selection_option) {
          return Promise.reject("No option selected");
        }
      }

      const result = await submitOptionAndGetNext(
        scriptRecipeId,
        scriptRecipeGoalType,
        section,
        nextSectionId
      );
      return result;
    } catch (error: any) {
      return undefined;
    }
  }

  /**
   * Fetches the wizard sections for the creation script wizard
   * @returns A superscript object
   */
  async createScript(
    scriptRecipeId: string
  ): Promise<ICreateScriptCallbackResult | undefined> {
    try {
      const result = await createScript(scriptRecipeId);
      return result;
    } catch (error: any) {
      return undefined;
    }
  }
}
