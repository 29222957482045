import firebase from "firebase";
import { AIAbilityCreation } from "kre8tv/mappers/VLDataMapper";
import { AIAbility } from "kre8tv/model/AIAbility";
import { cleanObject } from "util/SCUtils";
import { DB_CREATIONS } from "./DBConstants";
import { AirtableManager } from "./VLAirtableManager";
import { createAIAssets, fetchAssets } from "services/superpowers.service";
import { ERROR_FETCHING_ASSETS } from "actions/Types";

export class SuperpowerManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  airtable = new AirtableManager();

  /**
   * Fetches a superpower's recipe
   * This is important as some superpowers might not
   * contain all the information when we fetch them in order to protect the "secret ingredients"
   * @param superpower Superpower to fetch
   * @param callback The callback
   * @returns
   */
  fetchSuperpowerRecipe(
    superpower: AIAbility,
    callback: (result?: AIAbility, error?: any) => void
  ) {
    try {
      if (!superpower.recipe_id) {
        throw new Error("no recipe id");
      }

      if (typeof superpower.recipe_id !== "string") {
        throw new Error("unsupportd recipe_id type");
      }

      this.airtable.fetchRecipe(superpower.recipe_id, (recipe, error) => {
        if (recipe) {
          superpower.recipe = recipe;
          callback(superpower, undefined);
        } else {
          //no recipe
          callback(undefined, "recipe not found");
        }
      });
      return fetch; //should be array
    } catch (error: any) {
      //   throw new Error(error);
      callback(undefined, "Error fetching recipe");
    }
  }

  /**
   * Fetches an ability/superpower from airtable
   * @param id The id of the superpower
   * @param callback Callback function with the result, error if exists
   */
  fetchSuperpower(
    id: string,
    callback: (result?: AIAbility, error?: any) => void
  ) {
    this.airtable.fetchSuperpower(id, callback);
  }

  /**
   * Saves a creaiton result to the backend and user's account
   * @param creation The creation object to save
   */
  saveCreationResult(creation: AIAbilityCreation) {
    let cleaned = cleanObject(creation);
    console.log("attempting save creation - ", cleaned);
    const doc = this.firestore.collection(DB_CREATIONS).doc();
    cleaned.id = doc.id;
    doc
      .set({ ...cleaned })
      .then((result) => {
        console.log("save creation success - ", result);
      })
      .catch((error) => {
        console.error("save creation error - ", error);
      });

    //save
  }

  async fetchAssets(query: string, scene_id?: string) {
    try {
      const assets = await fetchAssets(query, scene_id);
      return {
        succes: true,
        results: assets.assets,
        finalQuery: assets.finalQuery,
      }; //should be {results : []}
    } catch (error) {
      // // throw new Error(error);
      // dispatch({
      //   type: ERROR_FETCHING_ASSETS,
      //   payload: error,
      // });
      return { succes: false, results: null }; //should be {results : []}
    }
  }

  async attemptCreateAIAssets(
    text: string,
    proportion: "square" | "portrait" | "landscape" = "square",
    amount: number = 1,
    with_background: boolean = false,
    model_id?: string,
    scene_id?: string
  ) {
    try {
      const assets = await createAIAssets(
        text,
        proportion,
        amount,
        with_background,
        model_id,
        scene_id
      );
      return {
        succes: true,
        results: assets.assets,
        finalQuery: assets.finalQuery,
      };
    } catch (error) {
      return { succes: false, results: null }; //should be {results : []}
    }
  }
}
