/**
 * Chat App Actions
 */
import { UPDATE_MOST_RECENTLY_CONNECTED_USERS } from "actions/Types";
import { UserManager } from "kre8tv/managers";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { NotificationManager } from "react-notifications";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch recently connected users
 */
export const getRecentlyConnectedUsers = (limit = 10) => {
  return async (dispatch) => {
    try {
      const userManager = new UserManager();
      const mostRecentUsers = await userManager.fetchRecentlyConnectedUsers(10);
      dispatch({
        type: UPDATE_MOST_RECENTLY_CONNECTED_USERS,
        payload: mostRecentUsers,
      });
      return true;
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error fetching most recently connected users"
      );
      return null;
    }
  };
};
