import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { CreationManager } from "kre8tv/managers/VLCreationManager";
import { FormModel, ScriptRecipe, Superscript } from "kre8tv/model";
import {
  IWizardSectionsCallbackResult,
  ICreateScriptCallbackResult,
  IWizardSectionCallbackResult,
  IWizardScriptRecipeCallbackResult,
  IWizardStartCallbackResult,
} from "kre8tv/model/interfaces";
import { ScriptScene } from "kre8tv/model/script-scene.model";
import { WizardSection } from "kre8tv/model/wizard-section.model";

/**
 * Fetches the base sections for the wizard
 * @returns IWizardSectionsCallbackResult
 */
export const createScriptRecipe = async (
  sources: string[],
  summary: string,
  source_text?: string
): Promise<IWizardScriptRecipeCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("createScriptRecipe - error fetch script wizard sections= ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "create_script_recipe",
      source_urls: sources.join(","),
      summary: summary,
      source_text: source_text,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
    };

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    if (!data.results || data.error) {
      throw new Error(data.error ?? "unsupported request");
    }

    const script_recipe_json = data.results;
    const script_recipe = new ScriptRecipe(script_recipe_json);

    const results: IWizardScriptRecipeCallbackResult = {
      results: script_recipe,
    };

    return results;
  } catch (error: any) {
    console.error(
      "createScriptRecipe - error fetch script wizard sections= ",
      error
    );
    throw new Error(error);
  }
};

/**
 * Fetches the initial sections for the wizard based on the script recipe
 * @returns IWizardSectionsCallbackResult
 */
export const fetchInitialSections = async (
  scriptRecipe: ScriptRecipe
): Promise<IWizardStartCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error(
      "fetchInitialSections - error fetch script wizard sections= "
    );
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload: any = {
      action: "get_wizard_steps",
      script_recipe_id: scriptRecipe.id,
      wizard_goal: scriptRecipe.goal_type,
      v2: true,
    };

    console.log("fetch initial sections payload = ", payload);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
      params: payload,
    };

    const fetchResult = await axios.get(url, config);

    console.log("fetch initial sections result = ", fetchResult);

    const { data } = fetchResult;

    let json = data.results;
    let sections = json.sections;
    let script_recipe = json.script_recipe;
    const wizardSections = sections
      ? sections.map((section: any) => new WizardSection(section))
      : undefined;

    const results: IWizardStartCallbackResult = {
      sections: wizardSections,
      scriptRecipe: script_recipe,
    };

    return results;
  } catch (error: any) {
    console.error(
      "fetchInitialSections - error fetch script wizard sections= ",
      error
    );
    throw new Error(error);
  }
};

/**
 * Loads more options for the specific section`
 * @returns IWizardSectionCallbackResult
 */
export const loadMoreOptionsForSection = async (
  section: WizardSection,
  scriptRecipeId: string
): Promise<IWizardSectionCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error(
      "loadMoreOptionsForSection - error fetch script wizard sections= "
    );
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "load_more_options_for_section",
      section_id: section.id,
      section_title: section.title,
      script_recipe_id: scriptRecipeId,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
      params: payload,
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;
    const result: WizardSection | undefined = data.results
      ? new WizardSection(data.results)
      : undefined;

    const results: IWizardSectionCallbackResult = {
      results: result,
      sections: undefined,
    };

    return results;
  } catch (error: any) {
    console.error(
      "loadMoreOptionsForSection - error fetch script wizard sections= ",
      error
    );
    throw new Error(error);
  }
};

/**
 * Loads more options for the specific section`
 * @returns IWizardSectionCallbackResult
 */
export const submitOptionAndGetNext = async (
  scriptRecipeId: string,
  scriptRecipeGoalType: string,
  section: WizardSection,
  nextSectionId: string
): Promise<IWizardSectionCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("error submitting wizard value to server = ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload: any = {
      action: "generate_next_wizard_section",
      script_recipe_id: scriptRecipeId,
      section_id: section.id,
      section_value: section.selectedOption,
      next_section_id: nextSectionId,
      wizard_goal: scriptRecipeGoalType,
      v2: true,
    };

    if (section.isMultiSelect()) {
      payload["section_value"] = section.selectedOptions;
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 16500,
    };

    const fetchResult = await axios.post(url, payload, config);

    console.log("fetch submit option and get next result = ", fetchResult);

    const { data } = fetchResult;
    if (data.error) {
      throw new Error(data.error);
    }

    if (data.results) {
      if (data.results.id) {
        const result: WizardSection | undefined = data.results
          ? new WizardSection(data.results)
          : undefined;

        return { results: result, sections: undefined };
      }
      if (data.results.length > 0) {
        let sections_json = data.results;

        let sections = [];
        for (let i = 0; i < sections_json.length; i++) {
          let section = sections_json[i];
          sections.push(new WizardSection(section));
        }

        return { results: undefined, sections: sections };
      } else {
        throw new Error("No results from server");
      }
    } else {
      throw new Error("No results from server");
    }
  } catch (error: any) {
    console.error("error submitting wizard value to server = ", error);
    throw new Error(error);
  }
};

export const createScript = async (
  scriptRecipeId: string
): Promise<ICreateScriptCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("error creating script to server = ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "create_script",
      script_recipe_id: scriptRecipeId,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
    };

    const fetchResult = await axios.post(url, payload, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;
    if (data.error) {
      throw new Error(data.error);
    }
    const script: Superscript | undefined = data.results
      ? new Superscript(data.results)
      : undefined;

    const results: ICreateScriptCallbackResult = {
      script: script,
      tones: undefined,
    };

    return results;
  } catch (error: any) {
    console.error("error creating script to server = ", error);
    throw new Error(error);
  }
};

//same s above but in ts
export const fetchAdvancedScriptCreationForm = async (
  form_id: string
): Promise<FormModel> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    console.error("error creating script to server = ");
    throw new Error("No token for firebase");
  }

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload: any = {
      action: "fetch_creation_form",
      id: "advanced_script_creation",
    };

    if (form_id) {
      payload["form_id"] = form_id;
    }

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
      params: payload,
    };

    const fetchResult = await axios.get(url, config);

    console.log(
      "fetchAdvancedScriptCreationForm -  fetch result = ",
      fetchResult
    );

    const { data } = fetchResult;
    if (data.error) {
      throw new Error(data.error);
    }
    if (data.result) {
      let form = new FormModel(data.result);
      return form;
    } else {
      throw new Error("Could not fetch form data");
    }
  } catch (error: any) {
    console.error("error creating script to server = ", error);
    throw new Error(error);
  }
};
