/**
 * Chat App Actions
 */
import { GET_SUPERHEROES } from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch all superheroes (factual users used as building blocks)
 */
export const getSuperheroes = () => (dispatch) => {
  airtable.fetchSuperheroes((superheroes, error) => {
    if (superheroes) {
      dispatch({
        type: GET_SUPERHEROES,
        payload: superheroes,
      });
    } else {
      dispatch({
        type: GET_SUPERHEROES,
        payload: error,
      });
    }
  });
};
