/**
 * Chat App Actions
 */
import {
  POWER_UP_CONTENT,
  PICK_SUPERPOWER_FOR_SELECTED_CONTENT,
  PICK_SUPERHERO_FOR_SELECTED_CONTENT,
  DISMISS_POWER_UP_CONTENT,
} from "actions/Types";

/**
 * Power up content
 */
export const powerUpContent = (content) => (dispatch) => {
  dispatch({
    type: POWER_UP_CONTENT,
    payload: { content: content },
  });
};

/**
 * dismiss power up content
 */
export const dismissPowerUpContent = () => (dispatch) => {
  dispatch({
    type: DISMISS_POWER_UP_CONTENT,
  });
};

/**
 * Should power up selected content
 */
export const pickSuperpowerForSelectedContent =
  (content, scene) => (dispatch) => {
    // console.log("dispatching PICK_SUPERPOWER_FOR_SELECTED_CONTENT");
    dispatch({
      type: PICK_SUPERPOWER_FOR_SELECTED_CONTENT,
      payload: { content: content, scene: scene },
    });
  };

/**
 * Should power up selected content
 */
export const pickSuperheroForSelectedContent =
  (content, scene) => (dispatch) => {
    // console.log("dispatching PICK_SUPERHERO_FOR_SELECTED_CONTENT");
    dispatch({
      type: PICK_SUPERHERO_FOR_SELECTED_CONTENT,
      payload: { content: content, scene: scene },
    });
  };
