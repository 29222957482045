import { IEntity } from "./interfaces/entity.interface";
import { RecipeIngredient } from "./VLRecipeIngredient";

export class CreationFavoritedEvent implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";
  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * user id of who ran it
   */
  creation_id = "";
  /**
   * user id of who ran it
   */
  favorited = false;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.user_id = obj.user_id;
    this.creation_id = obj.creation_id;
    this.favorited = obj.favorited;

    //metadata
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
    this.last_updated_by_user_id = obj.user_id;
  }

  createdOnString(): string {
    let date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    let date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }
}
