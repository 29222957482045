/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";

// App locale
import AppLocale from "../lang";

// themes
import darkTheme from "../themes/DarkTheme";
import lightTheme from "../themes/LightTheme";
import tealTheme from "../themes/TealTheme";
import violetTheme from "../themes/VioletTheme";

class HulkThemeProvider extends Component {
  render() {
    const {
      locale,
      isDarkModeActive,
      isRtlActive,
      children,
      selectedThemeColor,
    } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    let theme = "";
    if (isDarkModeActive) {
      theme = darkTheme;
    } else {
      //NOTE - we're currently using light-theme as the default theme
      if (selectedThemeColor === "light-theme") {
        theme = lightTheme;
      } else if (selectedThemeColor === "teal-theme") {
        theme = tealTheme;
      } else if (selectedThemeColor === "violet-theme") {
        theme = violetTheme;
      } else {
        //DEFAULT
        theme = lightTheme;
      }
    }

    if (isRtlActive) {
      document.getElementsByTagName("BODY")[0].setAttribute("dir", "rtl");
      theme.direction = "rtl";
    } else {
      document.getElementsByTagName("BODY")[0].setAttribute("dir", "ltr");
      // document.getElementsByTagName("BODY")[0].removeAttribute("dir");
      theme.direction = "ltr";
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <Fragment>{children}</Fragment>
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  const { locale, isDarkModeActive, isRtlActive, selectedThemeColor } =
    settings;

  return { locale, isDarkModeActive, isRtlActive, selectedThemeColor };
};

export default connect(mapStateToProps)(HulkThemeProvider);
