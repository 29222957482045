import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { Recommendation } from "kre8tv/model";
import { IRecommendationsCallbackResult } from "kre8tv/model/interfaces";

/**
 * Search for inspiration sources
 * @param query
 * @param platform
 * @returns
 */
export const fetchRecommendationsForCurrentUser = async (
  forceGenerate = false
): Promise<IRecommendationsCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `recommendations`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const params = {
      action: "fetch",
      force_generate: forceGenerate,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: params,
    };

    const saveResult = await axios.get(url, config);

    console.log("save result = ", saveResult);

    const { data } = saveResult;
    const items = data.items.map((result: any) => new Recommendation(result));

    return { items: items, error: undefined };
  } catch (error: any) {
    console.error("error fetch recommendations= ", error);
    // throw new Error(error);
    return { items: undefined, error: error.message };
  }
};
