/**
 * Chat App Actions
 */
import {
  GET_CREATORS,
  FAILED_GET_CREATORS,
  GET_CREATOR_AVATARS,
  FAILED_GET_CREATOR_AVATARS,
} from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch all creators (real people we can comission scripts to)
 */
export const getKRE8TVCreators = () => (dispatch) => {
  airtable.fetchKRE8TVCreators((creators, error) => {
    if (creators) {
      dispatch({
        type: GET_CREATORS,
        payload: creators,
      });
    } else {
      dispatch({
        type: FAILED_GET_CREATORS,
        payload: error,
      });
    }
  });
};

/**
 * Fetch all creator avatars (synthetic creators we can comission scripts to)
 */
export const getKRE8TVSupercreatorAvatars = () => (dispatch) => {
  airtable.fetchKRE8TVSupercreators((avatars, error) => {
    if (avatars) {
      dispatch({
        type: GET_CREATOR_AVATARS,
        payload: avatars,
      });
    } else {
      dispatch({
        type: FAILED_GET_CREATOR_AVATARS,
        payload: error,
      });
    }
  });
};
