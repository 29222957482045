import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { CreationManager } from "kre8tv/managers/VLCreationManager";
import { CreationResult, User } from "kre8tv/model";
import {
  ICreationsCallbackResult,
  ICreationsFavoritedCallbackResult,
} from "kre8tv/model/interfaces/creations-callback-result.interface";

export const fetchCreations = async (): Promise<ICreationsCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `creations`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
      params: { action: "fetch" },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const { creations } = data;

    const allCreations = creations.map(
      (creationData: any) => new CreationResult(creationData)
    );

    const results: ICreationsCallbackResult = {
      results: allCreations,
    };

    return results;
  } catch (error: any) {
    console.error("error fetching creations= ", error);
    throw new Error(error);
  }
};

export const fetchFavoritedCreations =
  async (): Promise<ICreationsFavoritedCallbackResult> => {
    let creationManager = new CreationManager();
    const favorites =
      await creationManager.fetchFavoritedCreationsForCurrentUser();
    const results: ICreationsFavoritedCallbackResult = {
      favorites: favorites ?? undefined,
    };
    return results;
  };
