import { IEntity } from "./interfaces/entity.interface";

export class User implements IEntity {
  id: string = "";
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  first_name?: string;
  last_name?: string;
  email?: string;
  is_admin = false;
  display_name?: string;
  invite_key?: string;
  milestones = {};

  profile_id?: string;
  profilePictureURL?: string;
  title?: string;
  tags: string[] = [];

  constructor(obj?: any) {
    this.deserialize(
      obj.id,
      obj.created_on,
      obj.last_updated,
      obj.last_updated_by_user_id,
      obj.first_name,
      obj.last_name,
      obj.email,
      obj.is_admin,
      obj.display_name,
      obj.invite_key,
      obj.milestones,
      obj.profile_id,
      obj.profilePictureURL,
      obj.title,
      obj.tags
    );
  }

  handle() {
    return `@${this.profile_id}`;
  }

  fullName() {
    let hasFirst = this.first_name ? true : false;
    if (hasFirst) {
      return `${this.first_name} ${this.last_name}`;
    } else {
      return this.last_name ?? "No name";
    }
  }

  /**
   * Custom intializing method with properties
   * @param id
   * @param created_on
   * @param last_updated
   * @param last_updated_by_user_id
   */
  deserialize(
    id: string,
    created_on: number,
    last_updated: number,
    last_updated_by_user_id: string,
    first_name: string,
    last_name: string,
    email: string,
    is_admin: boolean = false,
    display_name?: string,
    invite_key?: string,
    milestones?: any,
    profile_id?: any,
    profilePictureURL?: string,
    title?: string,
    tags?: any
  ) {
    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.is_admin = is_admin;
    this.display_name = display_name;
    this.invite_key = invite_key;
    this.milestones = milestones ?? {};

    this.profile_id = profile_id;
    this.profilePictureURL = profilePictureURL;
    this.title = title ?? this.title;
    this.tags = tags ?? [];
  }
}
