/**
 * Abilities Reducer
 */
//action types
import { GET_NEWS_ITEMS } from "actions/Types";

const INITIAL_STATE = {
  newsItems: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NEWS_ITEMS:
      return { ...state, newsItems: action.payload };
    default:
      return { ...state };
  }
};
