/**
 * Chat App Actions
 */
import { GET_MISSIONS } from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { NotificationManager } from "react-notifications";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch all abilities
 */
export const getMissions = () => (dispatch) => {
  airtable.fetchMissions((missions, error) => {
    if (missions) {
      dispatch({
        type: GET_MISSIONS,
        payload: missions,
      });
    } else {
      dispatch({
        type: GET_MISSIONS,
        payload: error,
      });
    }
  });
};
