import { getReferralDashboard, sendAffiliateLinkClick } from "services";

/**
 * Fetch recently connected users
 */
export const logAffiliateLinkClick = (link, referralCode) => {
  return async (dispatch) => {
    try {
      const logClick = await sendAffiliateLinkClick(link, referralCode);
      if (logClick) {
        // console.log("Successfully logged affiliate link click");
        return true;
      } else {
        // console.log("Error logging affiliate link click");
        return false;
      }
    } catch (error) {
      console.error("Error logging affiliate link click", error);
      return false;
    }
  };
};

export const fetchAffiliateDashboardDetails = (token) => {
  return async (dispatch) => {
    const response = await getReferralDashboard(token);
    return response;
  };
};
