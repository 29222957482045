import { EditorManager } from "kre8tv/managers/VLEditorManager";

import { Sequence } from "./VLSequence";

export class Mission {
  /**
   * Airtable record
   */
  airtable_record_id: undefined;
  /**
   * Unique id
   */
  id = "";
  /**
   * Title of mission
   */
  title: string;
  /**
   * Description of mission
   */
  description: string;
  /**
   * Status
   */
  status: RecipeStatus = "none";
  /**
   * Last updated date
   */
  last_updated: number;
  /**
   * Created on date
   */
  created_on: number;

  /** Image URL */
  image_url?: string;

  /** Additional properties */
  properties: any = {};
  /**
   * 1d Array of sequence id's ([sequence_1, sequence_2])
   */
  sequence_ids: string[];

  /**
   * direct path to mission
   */
  path: string;

  /**
   * Background color
   */
  background_color: string = "";

  /**
   * Temp object -
   * 1d Array of actual sequences
   */
  sequences: Sequence[] = [];

  constructor(data?: any) {
    this.airtable_record_id = data?.id;
    this.id = data?.get("id") ?? "";
    this.title = data?.get("title") ?? "";
    this.description = data?.get("description") ?? "";
    this.status = data?.get("status") ?? "none";
    this.last_updated = data?.get("last_updated") ?? 0;
    this.created_on = data?.get("created_on") ?? 0;

    this.image_url = data?.get("image_url") ?? this.image_url;
    this.properties = data?.get("properties") ?? {};

    //custom parsing due to annoying airtable format
    this.sequence_ids =
      String(data?.get("sequence_ids") ?? "")
        ?.split(",")
        ?.map((i) => i.trim()) ?? [];

    this.path = data?.get("path");

    this.background_color =
      data?.get("background_color") ??
      "linear-gradient(123.44deg, #CAD8E0 2.91%, #FFB196 98.99%, rgba(234, 161, 135, 0) 99%);";
  }

  matchesQuery(query: string) {
    return (
      this.title.toLowerCase().indexOf(query) > -1 ||
      this.description.toLowerCase().indexOf(query) > -1
    );
  }

  //   static fromJSON(data: any) {
  //     let obj = new Recipe();
  //     obj.airtable_record_id = data["airtable_record_id"];
  //     obj.id = data["id"];
  //     obj.title = data["title"];
  //     obj.description = data["description"];
  //     obj.status = data["status"];
  //     obj.last_updated = data["last_updated"];
  //     obj.created_on = data["created_on"];

  //     //details
  //     obj.prompt = data["prompt"];
  //     obj.properties = data["properties"];

  //     obj.createIngredients();
  //     return obj;
  //   }
}
