export const DB_CREATIONS = "creations";
export const DB_CREATION_FAVORITES = "creation_favorites";
export const DB_SUPERSCRIPTS = "superscripts";
export const DB_SCRIPT_SCENES = "script_scenes";
export const DB_SCENE_ASSETS = "scene_assets";
export const DB_USERS = "users";
export const DB_AVATAR_GENERATIONS = "avatar_generations";
export const DB_ACCOUNT_DETAILS = "account_details";
export const DB_SCRIPT_RECIPES = "script_recipes";
export const DB_SHORT_FORM_VIDEOS = "short_form_videos";
export const DB_ORGANIZATIONS = "organizations";
