import { AmplitudeTracker } from "./trackers/amplitude.tracker";
import { FirebaseTracker } from "./trackers/firebase.tracker";
import { FullStoryRecorder } from "./recorders/fullstory.recorder";
import { IntercomTracker } from "./trackers/intercom.tracker";
import { isMobile } from "react-device-detect";
import { CustomerIOTracker } from "./trackers/customerio.tracker";

class Analytics {
  constructor() {
    this.isProduction = false;
    this.recorder = null;
  }

  init(isProduction) {
    this.isProduction = isProduction;

    if (isProduction) {
      this.trackers = [
        new AmplitudeTracker().init(),
        new FirebaseTracker().init(),
        new IntercomTracker().init(),
        new CustomerIOTracker().init(),
      ];
    } else {
      //remove customer.io tracker from dev/staging
      this.trackers = [
        new AmplitudeTracker().init(),
        new FirebaseTracker().init(),
        new IntercomTracker().init(),
      ];
    }

    if (window.location.href.match(/\/admin/)) {
      return;
    }

    isMobile ? analytics.setMobile() : analytics.setDesktop();

    this.initRecorder();
  }

  initRecorder() {
    this.recorder = new FullStoryRecorder();
    this.recorder.init(this.isProduction);
  }

  /**
   * Special Event - announcing about adding item to a cart
   * @param item
   * @param currency
   * @param price
   */
  addToCart(item, currency, price) {
    this.event(
      new AnalyticsEvent("add to cart", [
        {
          item,
          currency,
          price,
        },
      ])
    );
  }

  /**
   * Base event logging
   */
  logEvent(eventName, properties) {
    this.event(new AnalyticsEvent(eventName, properties));
  }

  /**
   * Log out event
   */
  logout() {
    this.setUserId(null);
    this.event(new AnalyticsEvent("web - user - logout"));

    if (this.recorder && this.recorder["logout"]) this.recorder.logout();
    //call logout on all trackers
    this.trackers.forEach((tracker) => {
      if (tracker["logout"]) tracker.logout();
    });
  }

  setUserId(userId) {
    this.trackers.forEach((tracker) => {
      if (tracker["setUserId"]) tracker.setUserId(userId);
    });

    if (this.recorder && this.recorder["setUserId"])
      this.recorder["setUserId"](userId);

    return this;
  }

  setFullName(name) {
    this.trackers.forEach((tracker) => {
      if (tracker["setFullName"]) tracker.setFullName(name);
    });

    if (this.recorder && this.recorder["setFullName"])
      this.recorder["setFullName"](name);

    return this;
  }

  setFirstName(name) {
    this.trackers.forEach((tracker) => {
      if (tracker["setFirstName"]) tracker.setFirstName(name);
    });

    if (this.recorder && this.recorder["setFirstName"])
      this.recorder["setFirstName"](name);

    return this;
  }

  setLastName(name) {
    this.trackers.forEach((tracker) => {
      if (tracker["setLastName"]) tracker.setLastName(name);
    });

    if (this.recorder && this.recorder["setLastName"])
      this.recorder["setLastName"](name);

    return this;
  }

  setEmail(name) {
    this.trackers.forEach((tracker) => {
      if (tracker["setEmail"]) tracker.setEmail(name);
    });

    if (this.recorder && this.recorder["setEmail"])
      this.recorder["setEmail"](name);

    return this;
  }

  setMobile() {
    this.trackers.forEach((tracker) => {
      if (tracker["setMobile"]) tracker.setMobile();
    });

    return this;
  }

  setDesktop() {
    this.trackers.forEach((tracker) => {
      if (tracker["setDesktop"]) tracker.setDesktop();
    });

    return this;
  }

  setCustomUserAttribute(key, value) {
    this.trackers.forEach((tracker) => {
      if (tracker["setCustomUserAttribute"])
        tracker.setCustomUserAttribute(key, value);
    });

    if (this.recorder && this.recorder["setCustomUserAttribute"])
      this.recorder["setCustomUserAttribute"](key, value);

    return this;
  }

  /**
   * Main method to send analytics events.
   * @param eventModel
   * @param callback
   */
  event(eventModel, callback = null) {
    // console.log(TAG, "Sending event: ", eventModel);

    let promises = [];

    this.trackers.forEach((tracker) => {
      promises.push(tracker.event(eventModel.name, eventModel.properties));
    });

    Promise.all(promises).then(() => {
      if (typeof callback === "function") callback();
    });

    if (this.recorder && this.recorder["event"]) {
      this.recorder.event(eventModel.name, eventModel.properties);
    }
  }

  screenLoadedEvent(screenName, callback = null) {
    // console.log(TAG, 'Sending event: ', eventModel);
    let promises = [];

    let eventName = `web - screen loaded - ${screenName}`;

    this.trackers.forEach((tracker) => {
      promises.push(tracker.event(eventName, {}));
    });

    Promise.all(promises).then(() => {
      if (typeof callback === "function") callback();
    });

    if (this.recorder && this.recorder["event"])
      this.recorder.event(eventName, {});
  }

  /**
   * Log event of script creation
   * @param {*} method The method of script creation (url, import, scratch, etc.)
   * @param {*} status Status of script creation (attempt, success, failed)
   * @param {*} attributes Additional attributes (like url, etc.)
   */
  scriptCreationEvent(method, status, attributes = {}) {
    let finalAttributes = { ...attributes, method };
    let eventName = `web - flow - script creation - ${status}`;
    this.event(new AnalyticsEvent(eventName, finalAttributes));
  }

  recipeCreationEvent(method, status, attributes = {}) {
    let finalAttributes = { ...attributes, method };
    let eventName = `web - flow - recipe creation - ${status}`;
    this.event(new AnalyticsEvent(eventName, finalAttributes));
  }
}

class AnalyticsEvent {
  name = "";
  properties = {};

  constructor(name, properties = {}) {
    this.name = name;
    this.properties = properties;
  }
}

const analytics = new Analytics();

export { analytics, AnalyticsEvent };
