export default {
   "sidebar.dashboard": "لوحة القيادة",
   "sidebar.dashboard1": "ل1وحة القيادة",
   "sidebar.dashboard2": "ل2وحة القيادة",
   "sidebar.dashboard3": "ل3وحة القيادة",
   "sidebar.videoPlayer" : "مشغل فديوهات",
   "sidebar.multilevelmenu": "قائمة متعددة المستويات",
   "sidebar.level1": "المستوى 1",
   "sidebar.level2": "المستوى 2",
   "sidebar.webanalytics": "تحليلات الويب",
   "sidebar.saas": "SAAS",
   "sidebar.ecommerce": "التجارة الإلكترونية",
   "sidebar.shop": "متجر",
   "sidebar.cart": "عربة التسوق",
   "sidebar.checkout": "الدفع",
   "sidebar.productdetails": "تفاصيل المنتج",
   "sidebar.invoice": "فاتورة",
   "sidebar.signIn": "تسجيل الدخول",
   "sidebar.chat": "دردشة",
   "sidebar.email": "البريد الإلكتروني",
   "sidebar.calendar": "التقويم",
   "sidebar.payment": "الدفع",
   "sidebar.signaturePad": "لوحة التوقيع",
   "sidebar.userManagement": "إدارةالمستخدم",
   "sidebar.contactGrid": "الاتصال بشبكة",
   "sidebar.userProfile": "ملف تعريفي للمستخدم",
   "sidebar.socialFeeds": "يغذي الاجتماعية",
   "sidebar.userSettings": "إعدادات المستخدم",
   "sidebar.pages": "الصفحات",
   "sidebar.faq": "التعليمات",
   "sidebar.pricing": "التسعير",
   "sidebar.pricingv1": "التسعير V1",
   "sidebar.pricingv2": "التسعير V2",
   "sidebar.pricingUpgrade": "ترقية التسعير",
   "sidebar.verticalTimeline": "مخطط زمني عمودي",
   "sidebar.horizontalTimeline":"مخطط زمني أفقي",
   "sidebar.horizontalStepper": "السائر الأفقي",
   "sidebar.verticalStepper": "السائر العمودي",
   "sidebar.blog": "مدونة",
   "sidebar.blogGrid": "شبكة المدونة",
   "sidebar.blogDetails": "شبكة المدونة",
   "sidebar.uiComponents": "مكونات واجهة المستخدم",
   "sidebar.appBar": "شريط التطبيق",
   "sidebar.avatars": "الآلهة",
   "sidebar.buttons": "وصفت",
   "sidebar.bottomNavigations": "التنقلات السفلية",
   "sidebar.chip": "رقاقة",
   "sidebar.lists": "قائمة",
   "sidebar.modals": "مشروط",
   "sidebar.tables": "الجداول",
   "sidebar.basicTable": "الجدول الأساسي",
   "sidebar.agGrid": "أججريد",
   "sidebar.searchTable": "جدول البحث",
   "sidebar.customTable": "جدول مخصص",
	"sidebar.sessions": "الجلسات",
	"sidebar.layout1":"التخطيط 1",
	"sidebar.layout2":"التخطيط 2",
   "sidebar.signUp": "سجل",
   "sidebar.forgotPassword": "هل نسيت كلمة المرور",
   "sidebar.errorPages": "صفحات الخطأ",
   "sidebar.basic": "الأساسي",
   "sidebar.upgrade": "تطوير",
   "sidebar.fourzerofour": "404",
   "sidebar.fivezerozero": "500",
   "sidebar.timeline": "الجدول الزمني",
   "sidebar.stepper": "السائر",
   "sidebar.profile": "الملف الشخصي",
   "sidebar.modern": "عصري",
   "sidebar.standard": "اساسي",
   "sidebar.style1": "النمط 1",
   "sidebar.style2": "النمط 2",
   "sidebar.fullpagemenu" : "قائمة الصفحة الكاملة",
   
   "widgets.newVisitors": "الزوار الجدد",
   "widgets.newSales": "مبيعات جديدة",
   "widgets.bounceRate": "معدل الارتداد",
   "widgets.earning": "كسب",
   "widgets.QuickLinks": "روابط سريعة",
   "widgets.frequentlyUsedSectiopns": "الأقسام المستخدمة بكثرة",
   "widgets.newUser": "مستخدمون جدد",
   "widgets.welcome": "مرحبًا جون ، مرحبًا بك مرة أخرى",
   "widgets.quickStats": "إحصائيات سريعة اليوم",
   "widgets.views": "الآراء",
   "widgets.growth": "نمو",
   "widgets.rating": "التقييمات",
   "widgets.userGrowth": "نمو٪ المستخدم",
   "widgets.avgUsers": "المتوسط المستخدمون",
   "widgets.avgTime": "المتوسط زمن",
   "widgets.customTable": "جدول مخصص",
   "widgets.users": "المستخدمون",
   "widgets.revenue": "إيرادات",
   "widgets.conversionRate": "معدل التحويل",
   "widgets.sessions": "الجلسات",

   "widgets.totalRevenue": "إجمالي الإيرادات",
   "widgets.totalOrders": "إجمالي الطلبات",
   "widgets.totalUsers": "إجمالي المستخدمين",
   "widgets.overallTrafficStatus": "الحالة المرورية الإجمالية",
   "widgets.liveChat": "الرسم البياني الحي",
   "widgets.tasksList": "قائمة المهام",
   "widgets.newUsers": "مستخدمون جدد",
   "widgets.dynamicBarChart": "مخطط شريطي ديناميكي",
   "widgets.visitorsStats": "إحصائيات الزوار",
   "widgets.recipients": "المستلمون",
   "widgets.openRate": "معدل مفتوح",
   "widgets.marketing": "تسويق",
   "widgets.sales": "مبيعات",
   "widgets.topProducts": "أهم المنتجات",
   "widgets.trends": "اتجاهات",
   "widgets.analyticsWebSessionsByRegion": "جلسات تحليلات الويب حسب المنطقة",
   "widgets.keywordSearch": "بحث بكلمة مفتاحية",
   "widgets.visitorsDevicesUsed": "الأجهزة المستخدمة من قبل الزوار",
   "widgets.howmanyuserscamefromABC?": "كم عدد المستخدمين الذين جاءوا من ABC؟",
   "widgets.totalViews": "عدد المشاهدات",
   "widgets.totalSales": "إجمالي المبيعات",
   "widgets.viewMore": "عرض المزيد",
   "widgets.newAccounts": "حسابات جديدة",

   "component.products": "منتجات",
   "component.signup": "سجل",
   "component.loadmore": "تحميل المزيد",
   "component.categories": "التصنيفات",
   "component.rating": "تقييم",
   "component.priceFilter": "تصفية الأسعار",
   "component.productDetails": "تفاصيل المنتج",
   "component.backToProducts": "العودة إلى المنتجات",
   "component.addToCart": "أضف إلى السلة",
   "component.viewCart": "عرض السلة",
   "component.addtowishlist": "أضف إلى قائمة الامنيات",
   "component.yourProductCart": "سلة منتجك",
   "component.continueShopping": "مواصلة التسوق",
   "component.myShoppingBag": "حقيبة التسوق الخاصة بي",
   "component.yourBill": "فاتورتك",
   "component.saveShoppingBag": "حفظ حقيبة التسوق",
   "component.proceedToCheckout": "باشرالخروج من الفندق",   
   "component.subtotal": "المجموع الفرعي",
   "component.tax": "ضريبة",
   "component.total": "مجموع",
   "component.shippingAddress": "عنوان الشحن",
   "component.payment": "دفع",
   "component.continueToPayment": "تابع الدفع",
   "component.invoice": "فاتورة",
   "component.getMembership": "احصل على عضوية",
   "component.alreadyHaveAccount?": "هل لديك حساب بالفعل؟",
   "component.continueAsGuest?": "تواصل كضيف؟",
	"components.compose": "مؤلف موسيقى",
	"component.firebase" : "قاعدة النار",
	"component.auth0" : "المصادقة 0",
	"component.youtubevideoplayer":"مشغل فيديو يوتيوب",
	"component.vimeovideoplayer":"مشغل فيديو Vimeo",

   "module.inbox": "صندوق الوارد",
   "module.drafts": "المسودات",
   "module.sent": "أرسلت",
   "module.trash": "قمامة، يدمر، يهدم",
   "module.spam": "بريد مؤذي",
   "module.primary": "ابتدائي",
   "module.social": "اجتماعي",
   "module.marketing": "تسويق",

   "component.contactGrid": "الاتصال بشبكة",
   "component.addContact": "إضافة جهة اتصال",
   "component.allContacts": "كل الاتصالات",
   "component.favourite": "مفضل",
   "component.recentlyAdded": "أضيف مؤخرا",
   "component.todaysTasks": "مهام اليوم",
   "component.recentFollowers": "المتابعين الأخيرة",
   "component.settings": "الإعدادات",
   "component.home": "الصفحة الرئيسية",
   "component.general": "جنرال لواء",
   "component.account": "الحساب",
   "component.email": "البريد الإلكتروني",
   "component.faq": "أسئلة مكررة",
   "component.generalEnquiries": "استفسارات عامة",
   "component.accounts": "حسابات",
   "component.features": "المميزات",
   "component.rulesAndPolicies": "القواعد والسياسات",
   "component.chooseYourPlan": "اختر خطتك",
   "component.starter": "بداية",
   "component.professional": "المحترفين",
   "component.team": "الفريق",
   "component.enterprise": "مشروع - مغامرة",
   "component.monthly": "شهريا",
   "component.yearly": "سنوي",
   "component.stillHaveQueries": "هل لا تزال لديك استفسارات؟",
   "component.callUsAnyTime": "اتصل بنا",
   "component.dropUsALine": "ترك لنا خطا",
   "component.backToPricingV1": "عودة إلى التسعير V1",
   "component.basic": "الأساسي",
   "component.standard": "اساسي",
   "component.premium": "الممتازة",
   "component.purchasePlan": "خطة الشراء",
   "component.personal": "شخصي",
   "component.finish": "إنهاء",
   "component.accountInformation": "معلومات الحساب",
   "component.personalInformation": "معلومات شخصية",
   "component.editBlog": "تحرير مدونة",
   "component.deleteBlog": "حذف المدونة",
   "component.recentBlog": "المدونة الأخيرة",
   "component.simpleAppBar": "شريط التطبيق البسيط",
   "component.appBarWithAPrimarySearchField": "شريط التطبيق مع حقل بحث أساسي",
   "component.appBarWithMenu": "شريط التطبيق مع القائمة",
   "component.imagesAvatar": "الصور الرمزية",
   "component.letterAvatar": "الصورة الرمزية الرسالة",
   "component.sizes": "الأحجام",
   "component.iconAvatar": "الصورة الرمزية للرمز",
   "component.grouped": "مجمعة",
   "component.withBadge": "أفارتارز مع شارة",
   "component.containedButtons": "الأزرار الموجودة",
   "component.textButtons": "أزرار النص",
   "component.outlinedButtons": "الأزرار المحددة",
   "component.uploadButton": "زر التحميل",
   "component.buttonsWithIconsAndLabel": "أزرار بأيقونات وملصقات",
   "component.iconButtons": "أزرار الرمز",
   "component.complexButtons": "أزرار معقدة",
   "component.bottomNavigation": "التنقل السفلي",
   "component.bottomNavigationWithNoLabel": "التنقل السفلي بدون تسمية",
   "component.outlinedChip": "رقاقة المخطط التفصيلي",
   "component.chipArray": "صفيف رقاقة",
   "component.simpleList": "قائمة بسيطة",
   "component.nesteList": "قائمة متداخلة",
   "component.alignListItems": "محاذاة عناصر القائمة",
   "component.listWithCheckBox": "قائمة مع خانة اختيار",
   "component.listWithSwitch": "قائمة مع التبديل",
   "component.simpleModal": "مشروط بسيط",
   "component.transition": "انتقال",
   "component.aggridTable": "جدول Ag-Grid",
   "component.megaMenu": "القائمة الضخمة",
   "horizontalMenu.general": "جنرال لواء",
   "horizontalMenu.modules": "الوحدات",
   "horizontalMenu.components": "مكونات",
   "horizontalMenu.applications": "التطبيقات",
   "component.upgradeYourPlanNow": "قم بترقية خطتك الآن",
   "component.addYourDebitCreditCard" : "أضف بطاقة الخصم أو الائتمان الخاصة بك",
   "component.termsandconditions" : "الأحكام والشروط",
    "components.paymentGateways" : "بوابات الدفع",
   "components.connectPaymentGateways" : "ربط بوابة الدفع",
   "components.tutorials": "قائمة تدقيق",
	"components.checklist": "قائمة مراجعة البرنامج التعليمي",
   "components.welcomeToHulk" : "أهلا وسهلا بك إلى Hulk",
   "components.advancedTable" : "جدول متقدم",
   "components.chatPanel" : "لوحة الدردشة",
   "components.signaturePad": "لوحة التوقيع",
   "components.help" : "مساعدة",
   "components.clicktoknowmore" : "انقر لمعرفة المزيد",
   "components.clickhereifyouneedanyhelp" : "انقر هنا إذا كنت بحاجة إلى أي مساعدة"
}