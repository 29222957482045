import firebase from "firebase";

import { ScriptScene } from "kre8tv/model/script-scene.model";
import { Superscript } from "kre8tv/model/super-script.model";
import { Recipe } from "kre8tv/model/VLRecipe";
import {
  saveOrUpdateSuperScript,
  generateScene,
  generateScript,
  duplicateScript,
  saveOrUpdateSuperScriptAndScenes,
  fetchFullScript,
  deleteScript,
  saveForkedScript,
} from "services/superscripts.service";
import { store } from "store";
import { cleanObject } from "util/SCUtils";
import { DB_ORGANIZATIONS, DB_SUPERSCRIPTS } from "./DBConstants";
import { ScriptSceneManager } from "./VLScriptSceneManager";
import { Organization } from "kre8tv/model";

export class OrganizationManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  /**
   * Fetches the most recently updated teams created by all users
   * @param limit The limit to fetch of teams created by all users
   * @returns
   */
  async fetchRecentlyUpdatedOrganizations(limit = 10): Promise<Organization[]> {
    try {
      let db = this.firestore.collection(DB_ORGANIZATIONS);
      const snapshot = await db
        .orderBy("last_updated", "desc")
        .limit(limit)
        .get();
      let teams: Organization[] = [];
      const docs = snapshot.docs;
      docs.forEach((doc) => {
        const team = new Organization(doc.data());
        teams.push(team);
      });
      return teams;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getOrganizationById(id: string): Promise<Organization | null> {
    try {
      let db = this.firestore.collection(DB_ORGANIZATIONS);
      const doc = await db.doc(id).get();
      if (doc.exists) {
        return new Organization(doc.data());
      }
      return null;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
