import { IEntity } from "./interfaces/entity.interface";

export class Recommendation implements IEntity {
  id: string = "";
  user_id: string = "";
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  source: string = "";
  source_url?: string;

  title: string = "";
  type?: RecommendationType = undefined;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    const {
      id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      user_id,
      source,
      source_url,
      title,
      type,
    } = obj;

    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.user_id = user_id;

    this.source = source;
    this.source_url = source_url;
    this.title = title;
    this.type = type;
  }

  static iconColorFromType(type: string): string {
    switch (type) {
      case "idea":
        return "badge-warning";
      case "trend":
        return "badge-success";
      case "action":
        return "badge-danger";
      default:
        return "";
    }
  }

  static prettyTitleFromType(type: string): string {
    switch (type) {
      case "idea":
        return "Idea";
      case "trend":
        return "Trend";
      case "action":
        return "Action";
      default:
        return "";
    }
  }

  static iconNameFromType(type: string): string {
    switch (type) {
      case "idea":
        return "emoji_objects";
      case "trend":
        return "trending_up";
      case "action":
        return "post_add";
      default:
        return "";
    }
  }
}
