import firebase from "firebase";
import { ShortFormVideo } from "kre8tv/model";

import { ScriptScene } from "kre8tv/model/script-scene.model";
import { Superscript } from "kre8tv/model/super-script.model";
import { Recipe } from "kre8tv/model/VLRecipe";
import {
  saveOrUpdateSuperScript,
  generateScene,
  generateScript,
  duplicateScript,
} from "services/superscripts.service";
import { store } from "store";
import { cleanObject } from "util/SCUtils";
import { DB_SHORT_FORM_VIDEOS } from "./DBConstants";
import { ScriptSceneManager } from "./VLScriptSceneManager";

export class ShortFormVideoManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  /**
   * Fetche the most recently updated users
   * @param limit The limit to fetch of users
   * @returns
   */
  async fetchRecentlyCreatedShortFormVideos(
    limit = 10
  ): Promise<ShortFormVideo[]> {
    //validate current user is admin before allowing to run this method
    try {
      let db = this.firestore.collection(DB_SHORT_FORM_VIDEOS);
      const snapshot = await db
        .orderBy("last_updated", "desc")
        .limit(limit)
        .get();
      let videos: ShortFormVideo[] = [];
      const docs = snapshot.docs;
      docs.forEach((doc) => {
        //only store if generations is a valid object
        const user = new ShortFormVideo(doc.data());
        videos.push(user);
      });
      return videos;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
