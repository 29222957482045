/**
 * Abilities Reducer
 */
//action types
import { FETCHED_GENERATIONS, FETCH_MY_GENERATIONS } from "actions/Types";
import { AvatarGeneration, ScriptGenerationFolder } from "kre8tv/model";
import { getScriptFromId } from "../actions/SuperscriptActions";

const parseCached = () => {
  try {
    let cached = JSON.parse(localStorage.getItem("generations") ?? "[]")?.map(
      (o) => {
        // console.log(o);
        return new AvatarGeneration(o);
      }
    );
    return cached.filter((generation) => generation.is_deleted !== true);
  } catch (error) {
    return [];
  }
};

const sortGenerationsIntoFolders = (generations, scripts) => {
  const script_generations_map = {};
  generations.forEach((gen) => {
    if (script_generations_map[gen.script_id]) {
      script_generations_map[gen.script_id].push(gen);
    } else {
      script_generations_map[gen.script_id] = [];
      script_generations_map[gen.script_id].push(gen);
    }
  });

  let script_generations = [];
  Object.keys(script_generations_map).forEach((key) => {
    const scriptId = key;
    const script = getScriptFromId(scriptId, scripts);
    const script_generation = new ScriptGenerationFolder(
      scriptId,
      script ? script.title : null
    );
    script_generation.generations = script_generations_map[key];
    script_generations.push(script_generation);
  });

  script_generations = script_generations.sort(
    (a, b) => b.lastUpdated() - a.lastUpdated()
  );
  return script_generations;
};

const INITIAL_STATE = {
  generated: parseCached(),
  scriptGenerations: null,
  isFetchingGenerations: true, //start fetching until completed
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MY_GENERATIONS:
      return { ...state, isFetchingGenerations: true };
    case FETCHED_GENERATIONS:
      const generations = action.payload.generations;
      const scripts = action.payload.scripts;
      //save to local store
      localStorage.setItem("generations", JSON.stringify(generations));

      //here we transform and bucket generations by script id

      const script_generations = sortGenerationsIntoFolders(
        generations,
        scripts
      );

      return {
        ...state,
        scriptGenerations: script_generations,
        generations: generations,
        isFetchingGenerations: false,
      };
    default:
      return { ...state };
  }
};
