import { baseShareURL } from "util/SCUtils";
import { IEntity } from "./interfaces";

export class ResourcePermissionInvitation implements IEntity {
  id: string = "";
  user_id = ""; //the user who created the invitation

  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  permission: ResourcePermission = "viewer";
  scope: ResourceScope = "folder";
  resource_id = "";

  method: "email" | "phone" = "email";
  email?: string;
  phone?: string;

  notes?: string;
  for_user_id?: string;
  resource_name?: string;

  constructor(obj?: any) {
    if (!obj || !obj.id || !obj.user_id || !obj.resource_id) {
      return;
    }

    const {
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      permission,
      scope,
      resource_id,
      method,
    } = obj;

    this.id = id;
    this.user_id = user_id;
    this.resource_id = resource_id;

    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.permission = permission ?? "viewer";
    this.scope = scope ?? "folder";
    this.method = method ?? "email";
    this.email = obj.email;
    this.phone = obj.phone;

    this.notes = obj.notes;
    this.for_user_id = obj.for_user_id;
    this.resource_name = obj.resource_name;
  }

  toResourceURL() {
    switch (this.scope) {
      case "folder":
        return `/folder/${this.resource_id}`;
      case "script":
        return `/story?id=${this.resource_id}`;
      case "team":
        return `/project/${this.resource_id}`;
      default:
        return null;
    }
  }

  toShareURL() {
    const url = `${baseShareURL()}invitation?id=${this.id}&scope=${
      this.scope
    }&resource_id=${this.resource_id}`;

    return url;
  }

  isLinkInvite(): boolean {
    return (
      (!this.email || this.email.length === 0) &&
      (!this.for_user_id || this.for_user_id.length === 0)
    );
  }
}
