/**
 * Forget Password
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { getAllUrlParams } from "util/Parsers";
import { ReactComponent as BigBlueCheckmark } from "assets/Images/sc_checkmark_blue_circle.svg";

// redux action
import { confirmUserFirebaseEmailLink } from "actions";
import SCButton from "components/Buttons/SCButton";

//analytics
import { analytics } from "analytics/analytics";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmingEmail: false,
    };
  }

  componentDidMount() {
    const queryParams = getAllUrlParams();
    if (queryParams.mode && queryParams.mode.toLowerCase() === "signin") {
      analytics.logEvent("web - flow - sign in - did show confirm email");
      this.setState({ isConfirmingEmail: true });
      this.props.confirmUserFirebaseEmailLink(this.props.history);
    }
  }

  onContactUsClick = () => {
    console.log("Contact Us Clicked");
    //open mailto
    window.open("mailto:team@supercreator.ai", "_blank");
  };

  render() {
    // console.log("ConfirmEmailNew props", this.props);
    const user_email = localStorage.getItem("emailForSignIn");
    const user = this.props.user;
    const isAuthed = user !== null;

    return (
      <>
        {!isAuthed && (
          <WaitingForAuthView
            user_email={user_email}
            onBackClick={this.props.onBackClick}
            onContactUsClick={this.onContactUsClick}
          />
        )}
        {isAuthed && <FinishedAuthView />}
      </>
    );
  }
}

const FinishedAuthView = ({ user_email, onCloseWindowClicked }) => {
  return (
    <Box backgroundColor="white" p={3} height="100vh">
      <Box
        height={1}
        style={{ background: "#f2f3f8", borderRadius: "30px" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent="center"
        alignItems={"center"}
        textAlign={"center"}
        position="relative"
      >
        <BigBlueCheckmark
          sx={{
            width: "125px",
            height: "125px",
          }}
          alt="checkmark"
        />
        <Box
          fontSize="18px"
          fontWeight={"bold"}
          sx={{ marginTop: "50px" }}
          display="flex"
          alignItems="center"
        >
          Email Confirmed
        </Box>
        <Box mt={3} maxWidth="550px" fontSize="14px">
          You can close this window and continue in the tab from where you
          signed in.
        </Box>

        <Box position="absolute" bottom={45} width={1} textAlign="center">
          <TermsAndPrivacy />
        </Box>
      </Box>
    </Box>
  );
};

const WaitingForAuthView = ({ user_email, onContactUsClick, onBackClick }) => {
  return (
    <Box backgroundColor="white" p={3} height="100vh">
      <Box
        height={1}
        style={{ background: "#f2f3f8", borderRadius: "30px" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent="center"
        alignItems={"center"}
        textAlign={"center"}
        position="relative"
      >
        <BigBlueCheckmark
          sx={{
            width: "125px",
            height: "125px",
          }}
          alt="checkmark"
        />
        <Box
          fontSize="18px"
          fontWeight={"bold"}
          sx={{ marginTop: "50px" }}
          display="flex"
          alignItems="center"
        >
          Confirm your email
        </Box>
        <Box>
          <CircularProgress
            thickness={5}
            size={20}
            sx={{
              marginTop: "20px",
            }}
          />
        </Box>
        <Box mt={3} maxWidth="550px" fontSize="14px">
          We just sent a confirmation email to <b>{user_email}</b> Please, check
          your email and click on the confirmation link to confirm your email
          and activate your account.
        </Box>
        <Box mt={3} maxWidth="550px" fontSize="14px">
          Didn't receive an email? Please, check your spam folder or{" "}
          <Box
            onClick={onContactUsClick}
            className="linkPrimary"
            component="span"
            sx={{
              cursor: "pointer",
            }}
          >
            <b>contact us.</b>
          </Box>
        </Box>
        <SCButton
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#0C1DB8" }}
          sx={{
            marginTop: "30px",
            height: "52px",
            width: "240px",
            borderRadius: "20px",
            color: "#fff",
          }}
          className="btn-block"
          onClick={() => onBackClick()}
        >
          Back
        </SCButton>

        <Box position="absolute" bottom={45} width={1} textAlign="center">
          <TermsAndPrivacy />
        </Box>
      </Box>
    </Box>
  );
};

const TermsAndPrivacy = () => {
  return (
    <Box textAlign="center" fontSize="12px">
      By using Supercreator.ai you agree to our <br />
      <a href="/terms-of-service" className="linkPrimary">
        {" "}
        Terms of Service{" "}
      </a>
      and
      <a href="/privacy-policy" className="linkPrimary">
        {" "}
        Privacy Policy
      </a>
    </Box>
  );
};

const mapStateToProps = ({ authUser, settings }) => {
  const { email, error, user } = authUser;
  return { email, error, user };
};

export default connect(mapStateToProps, { confirmUserFirebaseEmailLink })(
  ConfirmEmail
);
