export enum ErrorCode {
  GENERIC = 0,
  UNHANDLED_ERROR = 1,
  MISSING_TOKEN = 2,
  COULD_NOT_COMPLETE_OPERATION = 3,
  FAILED_REGISTERING_TO_TOPIC = 4,
  BAD_INPUT = 5,
  MUST_UPGRADE_MEMBERSHIP = 1337,
  MUST_UPGRADE_MEMBERSHIP_SILVER = 1338,
  MUST_UPGRADE_MEMBERSHIP_GOLD = 1339,
  NO_ACCESS_TO_RESOURCE = 1340,
  NEED_INVITE_USER_TO_ORG = 1341,
}

export class VLError {
  code: ErrorCode = 0;
  message: string;

  constructor(code = 0, message: string) {
    this.code = code;
    this.message = message;
  }

  static parseError(obj: any, fallbackMessage?: string) {
    let fallback = new VLError(
      0,
      fallbackMessage ?? "Could not complete request"
    );
    return fallback;
  }
}

export function parseErrorMessage(error: any): string | null {
  return error?.response?.data?.error?.message;
}

export function parseVLError(error: any): VLError | null {
  if (error?.response?.data?.error) {
    return new VLError(
      error.response.data.error.code,
      error.response.data.error.message
    );
  } else {
    return null;
  }
}
