/**
 * Chat App Actions
 */
import {
  FETCHED_ACCOUNT_DETAILS,
  FETCHED_USER_PERMISSIONS,
  RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE,
  HIDE_INVITATION_CODE_BLOCKER,
} from "actions/Types";
import { UserManager } from "kre8tv/managers";
import { NotificationManager } from "react-notifications";
import { submitInvitationCode } from "services";
import {
  getUserHomePermissions,
  revokeInvitationToResource,
} from "services/resource-permission-scope.service";

export function fetchCurrentUserAccountDetails() {
  return async (dispatch) => {
    try {
      const userManager = new UserManager();
      const accountDetails = await userManager.fetchCurrentUserAccountDetails();
      dispatch({
        type: FETCHED_ACCOUNT_DETAILS,
        payload: accountDetails,
      });
      return true;
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error fetching account details"
      );
      return null;
    }
  };
}

export function fetchCurrentUserResourcePermissions() {
  return async (dispatch) => {
    try {
      const result = await getUserHomePermissions();
      if (!result.resource_permission_scopes) {
        return null;
      }
      dispatch({
        type: FETCHED_USER_PERMISSIONS,
        payload: result.resource_permission_scopes,
      });
      return true;
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error fetching user permissions"
      );
      return null;
    }
  };
}

export const addUserAccountDetailsListener = () => {
  //call listenToCurrentUserAccountDetails from UserManager
  return (dispatch) => {
    // console.log("addUserAccountDetailsListener called");
    try {
      const userManager = new UserManager();
      userManager.listenToCurrentUserAccountDetails((accountDetails) => {
        // console.log("accountDetails listener response:", accountDetails);
        dispatch({
          type: FETCHED_ACCOUNT_DETAILS,
          payload: accountDetails,
        });
      });
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error adding user account details listener"
      );
      return null;
    }
  };
};

export const hideInvitationCodeBlocker = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_INVITATION_CODE_BLOCKER,
    });
  };
};

export const removeUserAccountDetailsListener = () => {
  //call listenToCurrentUserAccountDetails from UserManager
  return (dispatch) => {
    // console.log("removeUserAccountDetailsListener called");
    try {
      const userManager = new UserManager();
      userManager.removeCurrentUserAccountDetailsListener();
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error removing user account details listener"
      );
      return null;
    }
  };
};

export const reloadAccountDetailsPaywallState = () => {
  return (dispatch) => {
    dispatch({
      type: RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE,
    });
  };
};
