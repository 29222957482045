import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "5px",
    borderRadius: "2.5px",
    overflow: "hidden",
  },
  bar: {
    backgroundColor: "#C0C3DC",
    borderRadius: "4px",
    backgroundImage:
      "linear-gradient(90.64deg, #FCC5E4 0.55%, #FDA34B 16.95%, #FF7882 34.36%, #BB5C91 51.26%, #7046AA 67.65%, #0C1DB8 83.53%, #020F75 98.9%)",
  },
}));

const GradientProgressBar = ({ isAnimated, maxProgress, loop }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  const progressLimit = maxProgress || 100;
  useEffect(() => {
    let timer;

    if (isAnimated) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= progressLimit ? progressLimit : prevProgress + 1
        );
      }, 5);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isAnimated]);

  return (
    <div className={classes.root}>
      <LinearProgress
        variant={loop ? "indeterminate" : "determinate"}
        value={progress}
        classes={{ bar: classes.bar }}
      />
    </div>
  );
};

export default GradientProgressBar;
