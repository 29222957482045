import { IEntity } from "./interfaces";

export class ResourcePermissionScope implements IEntity {
  id: string = "";
  user_id = "";

  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  permission: ResourcePermission = "viewer";
  scope: ResourceScope = "folder";
  resource_id = "";

  constructor(obj?: any) {
    if (!obj || !obj.id || !obj.user_id || !obj.resource_id) {
      return;
    }

    const {
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      permission,
      scope,
      resource_id,
    } = obj;

    this.id = id;
    this.user_id = user_id;

    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.permission = permission ?? "viewer";
    this.scope = scope ?? "folder";
    this.resource_id = resource_id ?? "";
  }
}
