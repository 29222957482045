/**
 * OrganizationActions.js
 */

import firebase from "@firebase/app";

// jwt

//utils

import { NotificationManager } from "react-notifications";
import {
  DID_FETCH_ORGANIZATION,
  DID_UPDATE_ORGANIZATION,
  FETCHED_USER_ORGANIZATIONS,
  UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS,
} from "./Types";
import {
  changeUserOrganization,
  getUserOrganizations,
  updateMembershipStatus,
} from "services";
import { OrganizationManager } from "kre8tv/managers/VLOrganizationManager";
const organizationManager = new OrganizationManager();
export const didFetchOrganization =
  (organization, user_resource_permission_scopes, invitations) =>
  (dispatch) => {
    dispatch({
      type: DID_FETCH_ORGANIZATION,
      payload: {
        organization,
        user_resource_permission_scopes,
        invitations,
      },
    });
  };

export const fetchUserOrganizations = () => async (dispatch) => {
  const get = await getUserOrganizations();
  const organizations = get.organizations;
  dispatch({
    type: FETCHED_USER_ORGANIZATIONS,
    payload: organizations,
  });
  return organizations;
};

export const didUpdateOrganizationName = (organization) => (dispatch) => {
  dispatch({
    type: DID_UPDATE_ORGANIZATION,
    payload: {
      organization,
    },
  });
};

export const changeCurrentUserOrganization =
  (organizationId) => async (dispatch) => {
    try {
      const changeOrgResult = await changeUserOrganization(organizationId);
      dispatch({
        type: DID_UPDATE_ORGANIZATION,
        payload: {
          organization: changeOrgResult,
        },
      });
      return changeOrgResult;
    } catch (e) {
      console.error("error changing team", e);
      return null;
    }
  };

/**
 * Fetch all recently created scripts
 */
export const getRecentlyUpdatedTeams = () => {
  return async (dispatch) => {
    try {
      let teams = await organizationManager.fetchRecentlyUpdatedOrganizations();
      if (teams) {
        dispatch({
          type: UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS,
          payload: teams,
        });
      } else {
        throw new Error("No teams found");
      }
    } catch (error) {
      NotificationManager.error(error.message);
      return null;
    }
  };
};

export const updateAccountMembershipStatusFromWebApp = () => {
  return async (dispatch) => {
    try {
      await updateMembershipStatus();
    } catch (error) {
      return null;
    }
  };
};
