import firebase from "firebase";
import BaseTracker from "./base.tracker";

const TAG = "FirebaseTracker: ";

export class FirebaseTracker extends BaseTracker {
  init() {
    return this;
  }

  event(name, properties = {}) {
    // return new Promise((resolve, reject) => {
    //we need to convert event name to something firebase likes
    let eventName = name
      .toLowerCase()
      .replaceAll(/ /g, "_")
      .replaceAll("-", "_");
    let props = {};

    if (properties instanceof Array) {
      properties.map((property) => {
        for (let key in property) props[key] = property[key];
      });
    } else {
      props = properties;
    }

    // console.log(
    //   TAG,
    //   'tracker - firebase - sending event [',
    //   eventName,
    //   ' ] ',
    //   props
    // );
    // resolve(firebase.analytics().logEvent(eventName));
    firebase.analytics().logEvent(eventName);
    // });
  }

  setUserId = (userId) => {
    firebase.analytics().setUserId(userId);
    firebase.analytics().setUserProperties({ userId: userId });
  };
  setFullName = (value) =>
    firebase.analytics().setUserProperties({ fullName: value });
  setFirstName = (value) =>
    firebase.analytics().setUserProperties({ firstName: value });
  setLastName = (value) =>
    firebase.analytics().setUserProperties({ lastName: value });
  setEmail = (value) =>
    firebase.analytics().setUserProperties({ email: value });
}
