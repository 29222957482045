import { AvatarGeneration, ContentAsset } from ".";

export class ScriptGenerationFolder {
  /**
   * The ID of the script
   */
  id = "";
  /**
   * The title of the script
   */
  title?: string;
  /**
   *
   */
  generations: AvatarGeneration[];
  /**
   *
   */

  constructor(id: string, title?: string) {
    this.id = id;
    this.title = title;
    this.generations = [];
  }

  /**
   * Get the timestamp of the most recently updated generation
   * @returns
   */
  lastUpdated(): number {
    if (this.generations.length === 0) return 0;

    const last_updated = this.generations.sort(
      (a, b) => b.last_updated - a.last_updated
    )[0].last_updated;
    return last_updated;
  }

  /**
   * Get the timestamp of the most recently updated generation
   * @returns
   */
  status(): AvatarGenerationStatus {
    let completed = 0;
    let inProgress = 0;

    this.generations.forEach((generation) => {
      if (generation.status === "in_progress") {
        inProgress++;
      } else if (generation.status === "complete") {
        completed++;
      }
    });
    return inProgress > 0
      ? "in_progress"
      : completed > 0
      ? "complete"
      : "complete";
  }
}
