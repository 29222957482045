import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { ISceneCallback } from "kre8tv/model/interfaces";

import { ScriptScene } from "kre8tv/model/script-scene.model";

/**
 * Saves or updates a superscript on the backend
 * @param script The superscript to save or update
 * @returns object.success = true if succesfull, object.success = false if failed
 */
export const generateVoiceForScene = async (
  sceneId: string,
  creatorIds: Record<string, boolean>,
  additionalProperties: any
): Promise<ISceneCallback> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  if (!token) {
    throw new Error("No auth token available");
  }

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `scriptScenes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let payload = {
      scene_id: sceneId,
      action: "generate_voice",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 45000,
    };

    const fetchResult = await axios.post(url, payload, config);
    // console.log("fetch result = ", fetchResult);

    const { data, status } = fetchResult;
    let success = status === 200;

    const scene = new ScriptScene(data.scene);

    return {
      scene: scene,
      error: success ? undefined : "Could not generate voice for scene",
    };
  } catch (error: any) {
    console.error("error generating voice for scene= ", error);
    // throw new Error(error);

    return {
      scene: undefined,
      error: error.message,
    };
  }
};
