/**
 * Abilities Reducer
 */
//action types
import {
  GET_INSPIRATION_SOURCES,
  FETCHED_INSPIRATIONS_SOURCES,
  REMOVED_INSPIRATION_SOURCE,
  GET_INSPIRATION_SOURCE_ITEMS,
  FETCHED_INSPIRATIONS_SOURCE_ITEMS,
  ADDED_INSPIRATION_SOURCE,
} from "actions/Types";

import { InspirationSourceItem } from "kre8tv/model";

const cachedInspirationSourceItems = () => {
  try {
    return (
      localStorage.getItem("inspiration_items") &&
      JSON.parse(localStorage.getItem("inspiration_items") ?? "[]")?.map(
        (o) => {
          return new InspirationSourceItem(o);
        }
      )
    );
  } catch (e) {
    localStorage.setItem("inspiration_items", undefined); //clean
    return undefined;
  }
};

const INITIAL_STATE = {
  shouldFetchAfterAddingNewSource: false,
  inspirationSources: undefined,
  inspirationItems: cachedInspirationSourceItems(),
  fetching: false,
  fetchingSourceItems: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INSPIRATION_SOURCES:
      return { ...state, fetching: true };
    case FETCHED_INSPIRATIONS_SOURCES:
      return {
        ...state,
        shouldFetchAfterAddingNewSource: false,
        inspirationSources: action.payload.sources,
        fetching: false,
      };
    case ADDED_INSPIRATION_SOURCE: {
      let existing = state.inspirationSources;
      existing.push(action.payload);
      return {
        ...state,
        shouldFetchAfterAddingNewSource: true,
        inspirationSources: existing,
      };
    }
    case REMOVED_INSPIRATION_SOURCE:
      let existing = state.inspirationSources;
      const source_id_to_remove = action.payload.source_id;
      existing = existing.filter((source) => source.id !== source_id_to_remove);
      return {
        ...state,
        inspirationSources: existing,
      };

    case GET_INSPIRATION_SOURCE_ITEMS:
      return {
        ...state,
        fetchingSourceItems: true,
      };
    case FETCHED_INSPIRATIONS_SOURCE_ITEMS:
      const inspirationItems = action.payload.items;
      localStorage.setItem(
        "inspiration_items",
        JSON.stringify(inspirationItems)
      );
      return {
        ...state,
        fetchingSourceItems: false,
        inspirationItems: inspirationItems,
      };
    default:
      return { ...state };
  }
};
