import { capitalizeFirstLetter } from "util/SCUtils";

export class RecipeIngredient {
  id = "";
  title?: string;
  definition: string;
  additional_info: string;
  required: boolean = true;
  default_value?: string;
  value?: string;
  min_chars?: number;
  max_chars?: number;
  primary: boolean = false;
  secondary: boolean = false;

  constructor(data: any) {
    //id
    this.id = data["id"];

    //info
    this.definition = data["definition"] ?? "";
    this.additional_info = data["additional_info"];
    this.title = data["title"] ?? "";

    //props
    this.required = data["required"] ?? this.required;
    this.default_value = data["default_value"] ?? this.default_value;
    this.min_chars = data["min_chars"] ?? this.min_chars;
    this.max_chars = data["max_chars"] ?? this.max_chars;

    this.value = data["value"] ?? this.value;

    this.primary = data["primary"] === "true";
    this.secondary = data["secondary"] === "true";
  }

  previewTitle() {
    return this.definition.length > 0
      ? capitalizeFirstLetter(this.definition)
      : this.id;
  }

  tableTitle() {
    let str =
      this.title && this.title.length > 0
        ? this.title
        : this.id.replaceAll("_", " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  editorTitle() {
    return `Token`;
  }

  isValid() {
    return this.value && this.value.length > 0;
  }
}
