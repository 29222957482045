/**
 * Affiliates
 *
 * This component is used to determine the referral flow:
 * 1. If device is ios, we reroute to the app store link
 * 2. If app is web, we show a QR code for the user to scan
 * 3. If app is android, we show alert stating that the app is not available on android, and a sign up button
 *
 * In all cases, we must register a click/visit to the affiliate link before redirecting.
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  TextField,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { logAffiliateLinkClick } from "actions"; // Assuming you have a registerClick action
import { isMobile, isIOS } from "react-device-detect";
import QRCode from "qrcode.react";
import { Redirect, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { NotificationManager } from "react-notifications";
import GradientProgressBar from "components/GlobalComponents/HulkLoader/GradientProgressBar";

const redirectLink =
  "https://supercreator.onelink.me/1uho?ref=referralCode&af_xp=referral&pid=tapfiliate&sc_ref=referralCode&campaign_name=referralCode";
const fallBackLink = "https://supercreator.ai";

const useStyles = makeStyles((theme) => ({
  bottomButton: {
    width: "160px",
    height: "52px",
    borderRadius: "20px",
    fontWeight: "bold",
    fontSize: "16px",
    boxShadow: "none",
    background: theme.palette.primary.main,
    color: "#fff",
  },
}));

const Affiliates = (props) => {
  const classes = useStyles();

  // get the query params from the URL
  const queryParams = new URLSearchParams(props.location.search);
  const referralCode = queryParams.get("sc_ref");

  const updatedLink = redirectLink.replace(/referralCode/g, referralCode);
  console.log("Loading Affiliates page with referral code: ", referralCode);
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = React.useState(true);

  // You can get state from Redux using useSelector
  // const someState = useSelector((state) => state.someState);

  // useEffect hook can be used to perform side effects, such as registering a click
  useEffect(() => {
    logClick();
  }, [dispatch]); // Pass dispatch as a dependency to avoid unnecessary re-renders

  const logClick = async () => {
    setShowLoading(true);
    const link = window.location.href; // Get the current URL
    const logClick = await dispatch(logAffiliateLinkClick(link, referralCode)); // Dispatch the action to register a click
    setShowLoading(false);
    if (logClick) {
      console.log("Successfully logged click");
    } else {
      console.log("Failed to log click");
    }
  };

  // You should write your conditional rendering based on the device's OS here

  const redirectToFallback = () => {
    //get all query params and add them to the fallback link
    const queryParams = new URLSearchParams(props.location.search);
    const params = queryParams.toString();
    const finalLink = `${fallBackLink}?${params}`;
    console.log("Redirecting to fallback link: ", finalLink);
    window.location.replace(finalLink);
    return null;
  };

  if (!referralCode || referralCode === "") {
    console.log("No referral code found in URL");
    //redirect to home page
    return <Redirect to="/" />;
  }

  const AndroidScreen = () => {
    useEffect(() => {
      redirectToFallback();
    }, []);
    return <Box>Transferring...</Box>;
  };

  const QRCodeScreen = () => {
    const copyLinkPressed = (text) => {
      navigator.clipboard.writeText(updatedLink);
      NotificationManager.success("Download link copied to clipboard");
    };

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        position="relative"
        height="100%"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position={"relative"}
        >
          <img
            className="img-fluid"
            alt="img"
            width="315"
            src={require(`assets/Images/logo_mixed.svg`).default}
          />
          <Typography variant="h4" align="center" fontWeight={600}>
            Scan the QR code below on your phone to download the app
          </Typography>
          <Box m={3}>
            <QRCode
              value={updatedLink}
              style={{ width: "256px", height: "256px" }}
            />
          </Box>
          <Box>
            <Typography variant="body1" align="center">
              Or
            </Typography>
          </Box>
          <Button
            className={`${classes.bottomButton}`}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={() => copyLinkPressed()}
          >
            Copy Link
          </Button>
        </Box>
        <Box
          position={"absolute"}
          bottom={0}
          width="100%"
          textAlign="center"
          fontSize="14px"
          fontWeight={600}
        >
          <Box
            onClick={() => {
              redirectToFallback();
            }}
          >
            I don't have an iPhone
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      backgroundColor="white"
      p={3}
      height="100vh"
      position="absolute"
      top={0}
      left={0}
      display={"flex"}
      flexDirection={"column"}
      justifyContent="center"
      alignItems={"center"}
      width="100%"
    >
      <Box
        width="100%"
        height="100%"
        style={{ background: "#f2f3f8", borderRadius: "30px" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent="space-around"
        alignItems={"center"}
        position={"relative"}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={3}
          height="100%"
        >
          {showLoading ? (
            <div className="text-center">
              <Box mb={3}>
                <img
                  alt="site-logo"
                  width="306"
                  height="80"
                  src={require(`assets/Images/logo_mixed.svg`).default}
                />
              </Box>
              <GradientProgressBar
                maxProgress={100}
                isAnimated={true}
                loop={true}
              />
            </div>
          ) : isIOS ? (
            <RenderiOSScreen updatedLink={updatedLink} />
          ) : isMobile ? (
            <AndroidScreen />
          ) : (
            <QRCodeScreen />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const RenderiOSScreen = ({ updatedLink }) => {
  console.log("Rendering iOS screen");

  const redirectToTargetLink = () => {
    window.location.replace(updatedLink);
    return null;
  };

  useEffect(() => {
    redirectToTargetLink();
  }, []);

  //2. show temporary screen
  return (
    <Box textAlign="center">
      <Typography variant="h5" align="center">
        Redirecting to the App Store...
      </Typography>
      <Typography variant="button" align="center">
        If you are not redirected, please{" "}
        <Box
          onClick={() => {
            redirectToTargetLink();
          }}
          style={{
            color: "#0C1DB8",
          }}
        >
          Click here
        </Box>
      </Typography>
    </Box>
  );
};

export default Affiliates;
