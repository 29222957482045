/**
 * Contact Reducer
 */
//action types
import {
  ADD_NEW_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  UPDATE_MOST_RECENTLY_CONNECTED_USERS,
  UPDATE_MOST_RECENTLY_CREATED_SCRIPTS,
  UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS,
  UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS,
} from "actions/Types";

import contacts from "assets/Data/Contacts.json";
import { User } from "kre8tv/model";

const INITIAL_STATE = {
  usersData: [],
  creatorsData: [],
  recentlyConnectedUsers: [],
  recentlyCreatedScripts: [],
  recentlyCreatedVideos: [],
  recentlyUpdatedOrganizations: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_MOST_RECENTLY_CONNECTED_USERS:
      //save the users to local store
      localStorage.setItem(
        UPDATE_MOST_RECENTLY_CONNECTED_USERS,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        recentlyConnectedUsers: action.payload,
      };
    case UPDATE_MOST_RECENTLY_CREATED_SCRIPTS:
      //save the scripts to local store
      localStorage.setItem(
        UPDATE_MOST_RECENTLY_CREATED_SCRIPTS,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        recentlyCreatedScripts: action.payload,
      };
    case UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS:
      //save the videos to local store
      localStorage.setItem(
        UPDATE_MOST_RECENTLY_CREATED_SHORT_FORM_VIDEOS,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        recentlyCreatedVideos: action.payload,
      };
    case UPDATE_MOST_RECENTLY_UPDATED_ORGANIZATIONS:
      return { ...state, recentlyUpdatedOrganizations: action.payload };
    default:
      return { ...state };
  }
};
