import firebase from "firebase";

import { ScriptScene } from "kre8tv/model/script-scene.model";
import { Superscript } from "kre8tv/model/super-script.model";
import { Recipe } from "kre8tv/model/VLRecipe";
import {
  saveOrUpdateSuperScript,
  generateScene,
  generateScript,
  duplicateScript,
  saveOrUpdateSuperScriptAndScenes,
  fetchFullScript,
  deleteScript,
  saveForkedScript,
  mergeScriptsIntoOneAnother,
  importScriptIntoAnother,
} from "services/superscripts.service";
import { store } from "store";
import { cleanObject } from "util/SCUtils";
import { DB_SUPERSCRIPTS } from "./DBConstants";
import { ScriptSceneManager } from "./VLScriptSceneManager";

export class SuperscriptManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  /**
   *
   * @param script
   */
  async fetchMyScripts() {}

  getScript = (scriptId: string) => {
    let filtered = store
      .getState()
      .superscriptsReducer.superscripts.filter(
        (superscript: Superscript) => superscript.id === scriptId
      );
    return filtered.length > 0 ? filtered[0] : null;
  };

  /**
   * Saves and updates the script
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async saveScript(
    script: Superscript,
    scenes: ScriptScene[] = []
  ): Promise<Superscript> {
    try {
      const result = await saveOrUpdateSuperScriptAndScenes(script, scenes);
      //now save all of the scenes..
      const result_script = result.results;
      if (result_script) {
        // console.log("save script success - ", result_script);
        return result_script;
      } else {
        throw new Error("Could not save script");
      }
    } catch (error: any) {
      console.error("save script error - ", error);
      throw new Error(error);
    }
  }

  /**
   * Saves and updates the script
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async forkScript(
    script: Superscript,
    scenes: ScriptScene[] = []
  ): Promise<Superscript> {
    try {
      const result = await saveForkedScript(script, scenes);
      //now save all of the scenes..
      const result_script = result.results;
      if (result_script) {
        // console.log("fork script success - ", result_script);
        return result_script;
      } else {
        throw new Error("Could not fork script");
      }
    } catch (error: any) {
      console.error("fork script error - ", error);
      throw new Error(error);
    }
  }

  /**
   * Deletes the superscript for the user
   * @param script
   * Note - will throw error if unsuccesfull
   */
  async deleteScript(script: Superscript) {
    try {
      let attemptDelete = await deleteScript(script.id);
      if (attemptDelete.success) {
        return { script: script };
      } else {
        throw new Error("Could not delete script");
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Update the script's permissions
   * @param script The script to update
   * @param email Email of user to update permissions for
   * @param permission Permissions to set for user
   * @returns
   */
  async updateScriptPermissions(
    script: Superscript,
    email: string | string[],
    permission: ScriptPermissions
  ) {
    const permissions = script.permissions;
    if (Array.isArray(email)) {
      email.map((id) => {
        permissions[id] = permission;
      });
    } else {
      permissions[email] = permission;
    }
    try {
      let doc = this.firestore.collection(DB_SUPERSCRIPTS).doc(script.id);
      const update = await doc.set(
        { permissions: permissions },
        { merge: true }
      );
      // console.log("update script permission - ", script.id);
      return { script: script };
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Generates a scene for a script
   * @param scene The scene to generate
   * @param script The script to generate the scene for
   * @param clearExistingInputs Override the existing inputs in the scene
   * @returns
   */
  async generateScene(
    scene: ScriptScene,
    script: Superscript,
    clearExistingInputs: boolean = false
  ) {
    try {
      const result = await generateScene(scene, script, clearExistingInputs);
      return result;
    } catch (error: any) {
      return null;
    }
  }

  /**
   * Generates a script from a source url (article,blog)
   * @param sourceUrl The url to fetch the data from
   * @returns
   */
  async generateScript(sourceUrl: string, sourceType: string) {
    try {
      const result = await generateScript(sourceUrl, sourceType);
      return result;
    } catch (error: any) {
      return null;
    }
  }

  /**
   * Generates a script from a source url (article,blog)
   * @param sourceUrl The url to fetch the data from
   * @returns
   */
  async duplicateScript(scriptId: string) {
    try {
      const result = await duplicateScript(scriptId);
      return result;
    } catch (error: any) {
      return null;
    }
  }

  /**
   * Merges two scripts into one another
   * @param mergeScriptId The script to merge from
   * @param toScriptId The script to merge into
   * @returns
   */
  async mergeScripts(scriptToMergeFrom: string, scriptToMergeInto: string) {
    try {
      const result = await mergeScriptsIntoOneAnother(
        scriptToMergeFrom,
        scriptToMergeInto
      );
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Imports a script into another script
   * @param sourceScriptId The ID of the script to import from
   * @param targetScriptId The ID of the script to import into
   * @returns
   */
  async importScript(sourceScriptId: string, targetScriptId: string) {
    try {
      const result = await importScriptIntoAnother(
        sourceScriptId,
        targetScriptId
      );
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Saves the scene
   * @param scene The scene to save
   * @param script The script to save the scene to
   * @returns Scene object if success, false if not
   */
  async saveScene(scene: ScriptScene, script?: Superscript) {
    let sceneManager = new ScriptSceneManager();
    if (script) scene.script_id = script.id;
    // const execute = await sceneManager.saveOrUpdateScriptScene(scene);
    return scene;
  }

  /**
   * Deletes the scene (mark's the scene as is_delete prop)
   * @param scene The scene to delete
   * @param script
   * @returns Scene object if deleted, false if not
   */
  async deleteScene(scene: ScriptScene, script: Superscript) {
    let sceneManager = new ScriptSceneManager();
    const execute = await sceneManager.deleteScene(scene);
    return execute;
  }

  /**
   * Fetches a script
   * @param scriptId The script id to fetch
   * @param callback (script, error?: any) => void
   * @returns
   */
  async fetchScript(scriptId: string, callback: any) {
    try {
      let fetchFull = await fetchFullScript(scriptId);
      if (fetchFull && fetchFull.results) {
        callback(fetchFull.results, null);
      } else {
        callback(null, "Could not fetch script");
      }
    } catch (error: any) {
      callback(null, error);
    }
  }

  /**
   * Fetches the scenes for a specific script
   * @param script The script to fetch scenes for
   * @returns
   */
  async fetchScenesForScript(script: Superscript) {
    let sceneManager = new ScriptSceneManager();

    try {
      const scenes = await sceneManager.fetchScenesForScript(script);
      if (scenes) script.scenes = scenes;
      return script;
    } catch (error: any) {
      return null;
    }
  }

  /**
   * Fetches the most recently updated scripts created by all users
   * @param limit The limit to fetch of scripts created by all users
   * @returns
   */
  async fetchRecentlyCreatedScripts(limit = 10): Promise<Superscript[]> {
    //validate current user is admin before allowing to run this method
    try {
      let db = this.firestore.collection(DB_SUPERSCRIPTS);
      const snapshot = await db
        .orderBy("last_updated", "desc")
        .limit(limit)
        .get();
      let scripts: Superscript[] = [];
      const docs = snapshot.docs;
      docs.forEach((doc) => {
        //only store if generations is a valid object
        const user = new Superscript(doc.data());
        scripts.push(user);
      });
      return scripts;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /***
   * Creates a unique id for a script
   */
  uniqueScriptId() {
    return this.firestore.collection(DB_SUPERSCRIPTS).doc().id;
  }
}
