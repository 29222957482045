export class Supercreator {
  /**
   * The id of the supercreator
   */
  id = "";
  /**
   * Full name - elon husk
   */
  fullName: string;
  /**
   * The title of supercreator (World philospher)
   */
  title: string;
  /**
   * Description of this supercreator
   */
  description: string;
  /**
   * last updated date
   */
  last_updated: number;
  /**
   * created on date
   */
  created_on: number;

  /**
   * profile picture url
   */
  profile_picture_url?: string;

  /**
   * The supercreator's tone
   */
  tone?: string;

  constructor(data?: any) {
    this.id = data["id"] ?? "";
    this.title = data["title"] ?? "";
    this.fullName = data["avatar_name"] ?? data["fullName"] ?? "";
    this.description = data["avatar_description"] ?? data["description"] ?? "";
    this.last_updated = data["last_updated"] ?? new Date(0).getTime();
    this.created_on = data["created_on"] ?? new Date(0).getTime();
    this.profile_picture_url = data["profile_picture_url"] ?? "";
    this.tone = data["tone"];
  }

  matchesQuery(query: string) {
    return (
      this.fullName.toLowerCase().indexOf(query) > -1 ||
      this.title.toLowerCase().indexOf(query) > -1 ||
      this.description.toLowerCase().indexOf(query) > -1
    );
  }
}
