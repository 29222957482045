import { Superhero, Superscript } from "kre8tv/model";
import { AIAbility } from "kre8tv/model/AIAbility";
import { Mission } from "kre8tv/model/VLMission";
import { Recipe } from "kre8tv/model/VLRecipe";
import { Sequence } from "kre8tv/model/VLSequence";
import { Supercreator } from "kre8tv/model/VLSupercreator";

import { NewsItem } from "kre8tv/model/news-item.model";
import { isProduction } from "util/SCUtils";
import { CreatorRow } from "../mappers/VLDataMapper";

var Airtable = require("airtable");
var _ = require("lodash");

export class AirtableManager {
  base?: any;

  AIRTABLE_TABLE_SUPERPOWERS = "Superpowers";
  AIRTABLE_VIEW_SUPERPOWERS_PRODUCTION = "Production view";
  AIRTABLE_VIEW_SUPERPOWERS_STAGING = "Staging view";

  superpowersAirtableView() {
    return isProduction()
      ? this.AIRTABLE_VIEW_SUPERPOWERS_PRODUCTION
      : this.AIRTABLE_VIEW_SUPERPOWERS_STAGING;
  }

  AIRTABLE_TABLE_MISSIONS = "Missions";
  AIRTABLE_TABLE_RECIPES = "Recipes";
  AIRTABLE_TABLE_SEQUENCES = "Sequences";
  AIRTABLE_TABLE_SCRIPTS = "Scripts";

  AIRTABLE_TABLE_SUPERCREATORS = "Supercreators";
  AIRTABLE_VIEW_SUPERCREATORS_PRODUCTION = "Production view";
  AIRTABLE_VIEW_SUPERCREATORS_STAGING = "Staging view";

  constructor() {
    this.base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base("appbpulKmYzfMl2OZ");
  }

  /**
   * Fetches all active creators
   * @param callback
   */
  fetchCreators(callback: (rows: CreatorRow[], errorMsg?: string) => void) {
    this.base!("Users-Master")
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 350,
        view: "Creators",
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: CreatorRow[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved user id:", record.get("id"));
            // console.log("Retrieved user profile id:", record.get("profile_id"));
            rows.push(new CreatorRow(record));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all active creators
   * @param callback
   */
  fetchUserInfo(callback: (result?: CreatorRow, error?: any) => void) {
    this.base!("Users-Master")
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: "Creators",
      })
      .filterByFormula()
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: CreatorRow[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved user id:", record.get("id"));
            // console.log("Retrieved user profile id:", record.get("profile_id"));
            rows.push(new CreatorRow(record));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows.length > 0 ? rows[0] : undefined, "No good");
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  base_supercreator = "appbpulKmYzfMl2OZ";
  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  fetchAbilities(callback: (rows: AIAbility[], error?: any) => void) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);
    base!(this.AIRTABLE_TABLE_SUPERPOWERS)
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 100,
        view: this.superpowersAirtableView(),
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: AIAbility[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved ability", record.get("id"));
            let fields = record.fields;
            fields.airtable_record_id = record.id;
            rows.push(new AIAbility(fields));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  fetchMissions(callback: (rows: Mission[], error?: any) => void) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);
    base!(this.AIRTABLE_TABLE_MISSIONS)
      .select({
        // Selecting the first 100 records in Base view:
        maxRecords: 100,
        view: "Preview view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: Mission[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved ability", record.get("id"));
            rows.push(new Mission(record));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  fetchFeaturedScripts(callback: (rows: Superscript[], error?: any) => void) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);
    base!(this.AIRTABLE_TABLE_SCRIPTS)
      .select({
        // Selecting the first 100 records in Base view:
        maxRecords: 100,
        view: "Preview view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: Superscript[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved ability", record.get("id"));
            rows.push(new Superscript(record.fields));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  fetchSequences(
    sequenceIds: string[],
    callback: (rows: Sequence[], error?: any) => void
  ) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);
    base!(this.AIRTABLE_TABLE_SEQUENCES)
      .select({
        // Selecting the first 100 records in Base view:
        maxRecords: 100,
        view: "Preview view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: Sequence[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved ability", record.get("id"));
            rows.push(new Sequence(record));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          if (sequenceIds) {
            rows = rows.filter((row) => sequenceIds.indexOf(row.id) > -1);
          }

          //sort by existing order
          rows = _.sortBy(rows, function (item: Sequence) {
            return sequenceIds.indexOf(item.id);
          });

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  fetchSuperheroes(callback: (rows: Supercreator[], error?: any) => void) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);
    base!(this.AIRTABLE_TABLE_SUPERCREATORS)
      .select({
        // Selecting the first 100 records in Base view:
        maxRecords: 100,
        view: this.AIRTABLE_VIEW_SUPERCREATORS_PRODUCTION,
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          let rows: Supercreator[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved ability", record.get("id"));
            rows.push(new Superhero(record.fields));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback([], err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  async saveRecipe(
    recipe: Recipe,
    callback: (result?: Recipe, error?: any) => void
  ) {
    let sanitized = {
      id: String(recipe.id),
      title: recipe.title,
      description: recipe.description,
      prompt: recipe.prompt,
    };

    this.getAirtableRecordId(
      this.AIRTABLE_TABLE_RECIPES,
      "Base view",
      "id",
      sanitized.id,
      (record_id, error) => {
        if (!record_id) {
          callback(undefined, "Record id not found");
          return;
        }

        const recordToSave = {
          id: record_id, //airtable_id
          fields: sanitized, //actual values
        };

        const records = [recordToSave];

        this.base!(this.AIRTABLE_TABLE_RECIPES).update(
          records,
          function (err: any, records: any[]) {
            if (err) {
              console.error(err);
              callback(undefined, err);
              return;
            } else if (records.length > 0) {
              let result = new Recipe(records[0].fields);
              callback(result, undefined);
            } else {
              callback(undefined, "No results found in response");
            }
          }
        );
      }
    );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  async createRecipe(
    recipe: Recipe,
    callback: (result?: Recipe, error?: any) => void
  ) {
    let sanitized = {
      id: String(recipe.id),
      title: recipe.title,
      description: recipe.description,
      prompt: recipe.prompt,
    };

    this.base!("Recipes").create(
      [
        {
          fields: sanitized,
        },
      ],
      function (err: any, records: any[]) {
        if (err) {
          console.error(err);
          callback(undefined, err);
          return;
        } else if (records.length > 0) {
          let result = new Recipe(records[0].fields);
          callback(result, undefined);
        } else {
          callback(undefined, "No results found in response");
        }
      }
    );
  }

  /**
   * Fetches the template from an id
   * @param callback
   */
  fetchSuperpower(
    id: string,
    callback: (result?: AIAbility, error?: any) => void
  ) {
    this.base!(this.AIRTABLE_TABLE_SUPERPOWERS)
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: this.superpowersAirtableView(),
        filterByFormula: `FIND("${id}",id)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            let fields = json.fields;
            fields.airtable_record_id = json.id;
            const template = new AIAbility(fields);
            callback(template, template ? undefined : "No good");
          } else {
            callback(undefined, "No good");
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Fetches the template from an id
   * @param callback
   */
  fetchSequence(
    id: string,
    callback: (result?: Sequence, error?: any) => void
  ) {
    this.base!(this.AIRTABLE_TABLE_SEQUENCES)
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: "Preview view",
        filterByFormula: `FIND("${id}",id)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.

          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            const sequence = new Sequence(json);
            callback(sequence, sequence ? undefined : "No good");
          } else {
            callback(undefined, "No good");
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Fetches the template from an id
   * @param callback
   */
  fetchRecipe(
    recipe_id: string,
    callback: (result?: Recipe, error?: any) => void
  ) {
    this.base!(this.AIRTABLE_TABLE_RECIPES)
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: "Preview view",
        filterByFormula: `FIND("${recipe_id}",id)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            const recipe = new Recipe(json.fields);
            callback(recipe, recipe ? undefined : "No good");
          } else {
            callback(undefined, "No good");
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Fetches all home screen categories with creators
   * @param callback
   */
  deployRecipe(
    recipe: Recipe,
    toSuperpower: AIAbility,
    callback: (success: boolean, error?: any) => void
  ) {
    let sanitized = {
      recipe_record_id: recipe.title,
    };
    this.base!(this.AIRTABLE_TABLE_SUPERPOWERS).update(
      [
        {
          id: toSuperpower.id,
          fields: sanitized,
        },
      ],
      function (err: any, records: any[]) {
        if (err) {
          console.error(err);
          callback(false, err);
          return;
        } else if (records.length > 0) {
          let result = new Recipe(records[0].fields);
          callback(true, undefined);
        } else {
          callback(false, "No results found in response");
        }
      }
    );
  }

  /**
   * Fetches the mission from id
   * @param callback
   */
  fetchMission(
    mission_id: string,
    callback: (result?: Mission, error?: any) => void
  ) {
    const manager = this;
    manager.base!(manager.AIRTABLE_TABLE_MISSIONS)
      .select({
        // Selecting the first 20 records in Base view:
        maxRecords: 1,
        view: "Preview view",
        filterByFormula: `FIND("${mission_id}",id)`,
      })
      .eachPage(
        function page(records: any[], fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          const json = records.length > 0 ? records[0] : undefined;
          if (json) {
            const mission = new Mission(json);
            //now let's fetch sequences
            if (mission.sequence_ids.length > 0) {
              manager.fetchSequences(
                mission.sequence_ids,
                (sequences, error) => {
                  mission.sequences = sequences;
                  callback(mission, undefined);
                }
              );
            } else {
              callback(mission, mission ? undefined : "No good");
            }
          } else {
            callback(undefined, "No good");
          }
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            callback(undefined, err);
            return;
          }
        }
      );
  }

  /**
   * Helper function to get the actual airtable record id (usually styled as rec+some_id)
   * @param tableName The table to get from
   * @param viewName The view to get from
   * @param key The key to check against
   * @param value The value to check against
   * @returns
   */
  getAirtableRecordId(
    tableName: string,
    viewName: String,
    key: string,
    value: any,
    callback: (record_id?: string, error?: any) => void
  ) {
    const formula = `${key} = "${value}"`;
    return this.base!(tableName)
      .select({
        maxRecords: 1,
        view: viewName,
        filterByFormula: formula,
      })
      .firstPage(async function (err: any, records: any[]) {
        console.log(`getAirtableRecordId result returned`);
        if (err) {
          console.error(err);
          callback(undefined, err);
          return undefined;
        }
        callback(records.length > 0 ? records[0].id : undefined, err);
      });
  }

  /**
   * Fetches all active creators
   * @param callback
   */
  fetchNewsItems(callback: (rows: NewsItem[], errorMsg?: string) => void) {
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(this.base_supercreator);

    base!("News")
      .select({
        // Selecting the first 10 records in Base view:
        maxRecords: 10,
        view: "Production view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          let rows: NewsItem[] = [];
          records.forEach(function (record: any) {
            // console.log("Retrieved user id:", record.get("id"));
            // console.log("Retrieved user profile id:", record.get("profile_id"));
            rows.push(new NewsItem(record.fields));
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

          callback(rows, undefined);
        },
        function done(err: any) {
          if (err) {
            console.error("error fetching news items:", err);
            callback([], err);
            return;
          }
        }
      );
  }

  fetchKRE8TVCreators(callback?: (regions?: any, error?: any) => void) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base("appjlLQJ6BVoh7oQ7");

    let creators: ICreator[] = [];

    let queryOptions = {
      // Selecting the first 20 records in Base view:
      fields: [
        "profile_id",
        "full_name",
        "title",
        "profile_picture_url",
        "profile_video_url",
        "category_ids",
        "followers_instagram",
        "followers_youtube",
        "followers_tiktok",
      ],
      maxRecords: 400,
      view: "Live view",
    };

    base("Creators")
      .select(queryOptions)
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedCreator = {
              id: fields.profile_id,
              title: fields.full_name, //see this thing
              detail: fields.title, //see this thing
              image_url: fields.profile_picture_url,
              thumbnail_image_url: fields.profile_picture_url,
              video_url: fields.profile_video_url,
              info: fields.info,
              price: fields.price,
              icon_url: fields.icon_url,
              category_ids: fields.category_ids,
              followers_instagram: fields.followers_instagram,
              followers_youtube: fields.followers_youtube,
              followers_tiktok: fields.followers_tiktok,
              deep_link_url: `/${fields.profile_id}`,
              deep_link_url_secondary: `/${fields.profile_id}?goto=subscribe`,
            };
            creators.push(parsedCreator);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            if (callback) callback(undefined, err);
            return;
          } else {
            //sort by followers count
            creators = _.orderBy(
              creators,
              ["followers_tiktok", "followers_instagram", "followers_youtube"],
              ["desc", "desc", "desc"]
            );
            if (callback) callback(creators, undefined);
          }
        }
      );
  }

  fetchKRE8TVSupercreators(callback?: (regions?: any, error?: any) => void) {
    //1. Fetch regions
    let base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base("appjlLQJ6BVoh7oQ7");

    let creators: ICreator[] = [];

    let queryOptions = {
      maxRecords: 400,
      view: "Live view",
    };

    base("Supercreators")
      .select(queryOptions)
      .eachPage(
        function page(records: any, fetchNextPage: Function) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record: any) {
            const { fields } = record;

            let parsedCreator = {
              id: fields.profile_id,
              title: fields.full_name, //see this thing
              detail: fields.title, //see this thing
              image_url: fields.profile_picture_url,
              thumbnail_image_url: fields.profile_picture_url,
              video_url: fields.profile_video_url,
              info: fields.info,
              price: fields.price,
              icon_url: fields.icon_url,
              category_ids: fields.category_ids,
              followers_instagram: fields.followers_instagram,
              followers_youtube: fields.followers_youtube,
              followers_tiktok: fields.followers_tiktok,
              deep_link_url: `/${fields.profile_id}`,
              deep_link_url_secondary: `/${fields.profile_id}?goto=create&supercreator=true`,
            };
            creators.push(parsedCreator);
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            if (callback) callback(undefined, err);
            return;
          } else {
            //sort by followers count
            creators = _.orderBy(
              creators,
              ["followers_tiktok", "followers_instagram", "followers_youtube"],
              ["desc", "desc", "desc"]
            );
            if (callback) callback(creators, undefined);
          }
        }
      );
  }
}
