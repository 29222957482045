/**
 * Abilities Reducer
 */
//action types
import { GET_ABILITIES } from "actions/Types";
import { AIAbility } from "kre8tv/model/AIAbility";

const INITIAL_STATE = {
  abilities: JSON.parse(localStorage.getItem("abilities") ?? "[]").map((o) => {
    // console.log(o);
    return new AIAbility(o);
  }),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ABILITIES:
      //save to local store
      localStorage.setItem("abilities", JSON.stringify(action.payload));
      return { ...state, abilities: action.payload };
    default:
      return { ...state };
  }
};
