import firebase, { storage } from "firebase";

class UploadManager {
  pendingUploads = [];

  async uploadAudioFile(folderName, file, callback) {
    const new_name = `${new Date().getTime()}_scene_audio.wav`;
    var new_file = new File([file], new_name);
    let metadata = {
      contentType: "audio/mpeg",
    };
    return this.uploadFile(folderName, new_file, callback, metadata);
  }

  async uploadProfilePicture(file, callback) {
    const new_name = `${new Date().getTime()}_profile_picture.png`;
    var new_file = new File([file], new_name);
    let metadata = {
      contentType: "image/png",
    };
    return this.uploadFile("profile_pictures", new_file, callback, metadata);
  }

  /**
   *
   * @param {*} folderName
   * @param {*} file The file to upload
   * @param {*} callback downloadURL?: string, progress?: double, error?: any
   */
  async uploadFile(folderName, file, callback, metadata) {
    console.log("upload file..", file);
    const storage = firebase.storage();
    const ref = storage.ref();
    const ts = new Date().getTime() / 1000;
    let originalFilename = file.name;
    let extension = originalFilename.split(".").pop();
    var fileName = `${ts}.${extension}`;
    var path = `${folderName}/${fileName}`;

    const fileMetadata = metadata ?? {};

    var storageRef = ref.child(path);

    var uploadTask = storageRef.put(file, fileMetadata);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        callback(null, progress, null);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        console.log("🚨error uploading session file -".error);
        callback(null, null, error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("✅ File available at", downloadURL);
          callback(downloadURL, 100, null);
        });
      }
    );
    // push to pending uploads
    this.pendingUploads.push({ file: file, uploadTask: uploadTask });
  }

  async cancelUpload(file) {
    console.log("cancel upload", file);
    var uploadTask = this.pendingUploads.find((item) => item.file === file);
    if (uploadTask) {
      uploadTask.uploadTask.cancel();
      this.pendingUploads = this.pendingUploads.filter(
        (item) => item.file !== file
      );
    }
  }
}

var uploadManager = new UploadManager();
export default uploadManager;
