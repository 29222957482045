import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import ChatAppReducer from "./ChatAppReducer";
import ContactReducer from "./ContactReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import EcommerceReducer from "./EcommerceReducer";
import EmailAppReducer from "./EmailAppReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import UsersReducer from "./UsersReducer";
import HomepageCategoriesReducer from "./HomepageCategoriesReducer";
import AbilitiesReducer from "./AbilitiesReducer";
import CreationsReducer from "./CreationsReducer";
import MissionsReducer from "./MissionsReducer";
import SupercreatorReducer from "./SupercreatorReducer";
import SuperheroReducer from "./SuperheroReducer";
import EditorReducer from "./EditorReducer";
import SuperscriptsReducer from "./SuperscriptsReducer";
import NewsItemsReducer from "./NewsItemsReducer";
import CreatorsReducer from "./CreatorsReducer";
import GenerationsReducer from "./GenerationsReducer";
import AccountDetailsReducer from "./AccountDetailsReducer";
import InspirationSourcesReducer from "./InspirationSourcesReducer";
import RecommendationsReducer from "./RecommendationsReducer";
import ContentAssetsReducer from "./ContentAssetsReducer";
import OrganizationsReducer from "./OrganizationsReducer";

const reducers = combineReducers({
  authUser: AuthUserReducer,
  chatAppReducer: ChatAppReducer,
  settings: AppSettingsReducer,
  ecommerce: EcommerceReducer,
  emailApp: EmailAppReducer,
  ContactReducer: ContactReducer,
  userSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  homepageCategoriesReducer: HomepageCategoriesReducer,
  usersReducer: UsersReducer,
  abilitiesReducer: AbilitiesReducer,
  creationsReducer: CreationsReducer,
  missionsReducer: MissionsReducer,
  supercreatorReducer: SupercreatorReducer,
  superheroReducer: SuperheroReducer,
  editorReducer: EditorReducer,
  superscriptsReducer: SuperscriptsReducer,
  newsItemsReducer: NewsItemsReducer,
  creatorsReducer: CreatorsReducer,
  generationsReducer: GenerationsReducer,
  accountDetailsReducer: AccountDetailsReducer,
  inspirationSourcesReducer: InspirationSourcesReducer,
  recommendationsReducer: RecommendationsReducer,
  contentAssetsReducer: ContentAssetsReducer,
  organizationsReducer: OrganizationsReducer,
});

export default reducers;
