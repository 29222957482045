import { ContentAsset } from "./content-asset.model";
import { SuperpostSoundMetaData } from "./super-post-sound-metadata.model";

/**
 * Represents a section of a superpost
 * Caption, hashtags, sounds, etc
 */
export class SuperpostSection {
  id = "";
  title = "";
  type: "texts" | "assets" | "sounds" = "texts";
  info?: string;

  assets?: ContentAsset[];
  texts?: string[];
  sounds?: SuperpostSoundMetaData[] = [];
  show_collapsed = false;
  platform = "all";

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.title = obj.title;
    this.info = obj.info;
    this.type = obj.type;

    this.assets = obj.assets;
    this.texts = obj.texts;
    this.sounds = obj.sounds;

    //extras - ui
    this.show_collapsed = obj.show_collapsed;
    this.platform = obj.platform;
  }

  // Convenience methods

  sectionType(): "texts" | "assets" | "sounds" {
    return this.type;
  }

  hasChangedText(): boolean {
    return this.mainText() !== this.texts?.[0];
  }

  valueToCopy(): ContentAsset | string | SuperpostSoundMetaData | null {
    switch (this.sectionType()) {
      case "texts":
        return this.texts?.length ? this.texts[0] : null;
      case "assets":
        return this.assets?.length ? this.assets[0] : null;
      case "sounds":
        return this.sounds?.length ? this.sounds[0] : null;
      default:
        return null;
    }
  }

  mainText(): string | null {
    return this.texts && this.texts.length > 0 ? this.texts[0] : null;
  }

  isEmpty(): boolean {
    switch (this.sectionType()) {
      case "texts":
        return !this.texts || this.texts.length === 0;
      case "assets":
        return !this.assets || this.assets.length === 0;
      case "sounds":
        return !this.sounds || this.sounds.length === 0;
      default:
        return true;
    }
  }
}
