import { IEntity } from "./interfaces/entity.interface";

export class ShortFormVideo implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * title of script
   */
  title = "";
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";

  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;

  /**
   * id of format (optional)
   */
  format_id?: string;

  /**
   * name of format (optional)
   */
  format_name?: string;

  /**
   * Type of script - normal, viral, evergreen
   */
  type?: string;

  /**
   * Thumbnail url
   */
  thumbnail_url?: string;

  /**
   * Path to take to on click
   */
  path?: string;

  /**
   * Niche (category)
   */
  niche?: string;

  /**
   * Audience (who its targeted for more specifically)
   */
  audience?: string;

  /**
   * Topic its about
   */
  topic?: string;

  /**
   * The name of the style of the script
   */
  style?: string;

  /**
   * Schedule for timestamp
   */
  scheduled_for?: number;

  /**
   * Is script deleted
   */
  is_deleted = false;
  /**
   * Is script deleted
   */
  number_of_scenes?: number;

  /**
   * Script permissions
   */
  permissions: Record<string, ScriptPermissions> = {};

  /**
   * The script recipe id it was created from
   */
  created_from_script_recipe_id?: string;

  /**
   * Is script featured
   */
  featured = false;

  /**
   * Is script featured
   */
  source_url?: string;

  /**
   * tags
   */
  tags: string[] = [];

  /**
   * The name of the author of the script
   */
  author_username?: string;
  /**
   * The id of the script (if one was used)
   */
  script_id?: string;
  /**
   * Whether this vdeo was archived or not
   */
  archived = false;
  /**
   * A textual transcription of the script that was used
   */
  script_transcription?: string;
  /**
   *  Audio transcrition (of the actual audio in the video)
   */
  audio_transcription?: string;

  /**
   * Whether to prompt the user for upload of a new video
   */
  prompt_for_upload = false;
  /**
   * Upload quality
   */
  requested_upload_quality: "compressed" | "hd" = "compressed";

  /**
   * Keep a list of uploaded versions (first is original)
   */
  uploaded_versions: { user_id: string; url: string; created_on: number }[] =
    [];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.title = obj.title;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //details
    this.format_id = obj.format_id;
    this.format_name = obj.format_name;
    this.type = obj.type;
    this.thumbnail_url = obj.thumbnail_url;
    this.path = obj.path;
    this.is_deleted = obj.is_deleted ?? false;

    this.niche = obj.niche;
    this.audience = obj.audience;
    this.topic = obj.topic;
    this.style = obj.style;
    this.is_deleted = obj.is_deleted ?? false;
    this.number_of_scenes = obj.number_of_scenes;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
    this.last_updated_by_user_id = obj.user_id;
    this.scheduled_for = obj.scheduled_for;

    //scene related
    this.permissions = obj.permissions ?? this.permissions;

    //extra
    this.created_from_script_recipe_id = obj.created_from_script_recipe_id;
    this.source_url = obj.source_url;
    this.tags = obj.tags;

    this.author_username = obj.author_username;
    this.script_id = obj.script_id;
    this.archived = obj.archived && obj.archived === true ? true : false;
    this.script_transcription = obj.script_transcription;
    this.audio_transcription = obj.audio_transcription;
    this.prompt_for_upload = obj.prompt_for_upload ?? false;
    this.requested_upload_quality =
      obj.requested_upload_quality ?? "compressed";
    this.uploaded_versions = obj.uploaded_versions ?? [];
  }

  createdOnString(): string {
    let date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    let date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  isSaved(): boolean {
    return this.id?.length > 0;
  }

  /**
   * The date (in ms) of the last uploaded version
   * @returns
   */
  lastUploadedVersionDate(): number {
    if (this.uploaded_versions.length > 0) {
      return this.uploaded_versions[this.uploaded_versions.length - 1]
        .created_on;
    } else {
      return this.created_on;
    }
  }
}
