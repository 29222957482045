import { EditorManager } from "kre8tv/managers/VLEditorManager";
import { RecipeIngredient } from "./VLRecipeIngredient";

export class Recipe {
  id = "";
  title: string;
  description: string;
  status: RecipeStatus = "none";
  last_updated: number;
  created_on: number;

  //details
  prompt = "";
  ingredients: RecipeIngredient[] = [];

  properties: any = {};

  airtable_record_id: undefined;

  constructor(data?: any) {
    this.airtable_record_id = data?.id;
    this.id = data?.id ?? "";
    this.title = data?.title ?? "";
    this.description = data?.description ?? "";
    this.status = data?.status ?? "none";
    this.last_updated = data?.last_updated ?? 0;
    this.created_on = data?.created_on ?? 0;

    //details
    this.prompt = data.prompt;
    this.properties = data.properties;

    this.createIngredients();
  }

  private createIngredients() {
    const editorManager = new EditorManager();
    const tokenElements = editorManager.getTokensFromInput(this.prompt ?? "");

    const ingredients = tokenElements.map((tokenElement) => {
      return new RecipeIngredient(tokenElement);
    });

    this.ingredients = ingredients;
  }

  updateWithPrimaryValue(value?: any) {
    if (!value) {
      return;
    }
    for (let i = 0; i < this.ingredients.length; i++) {
      if (this.ingredients[i].primary || this.ingredients.length === 1) {
        this.ingredients[i].value = value;
      }
    }
  }

  updateWithSecondaryValue(value?: any) {
    if (!value) {
      return;
    }
    for (let i = 0; i < this.ingredients.length; i++) {
      if (this.ingredients[i].secondary || this.ingredients.length === 1) {
        this.ingredients[i].value = value;
      }
    }
  }
}
