/**
 * Abilities Reducer
 */
//action types
import { GET_SUPERCREATORS } from "actions/Types";
import { Supercreator } from "kre8tv/model/VLSupercreator";

const parseCached = () => {
  try {
    let cached = JSON.parse(localStorage.getItem("supercreators") ?? "[]").map(
      (o) => {
        // console.log(o);
        return new Supercreator(o);
      }
    );
    return cached;
  } catch (error) {
    return [];
  }
};

const INITIAL_STATE = {
  supercreators: parseCached(),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUPERCREATORS:
      //save to local store
      localStorage.setItem("supercreators", JSON.stringify(action.payload));
      return { ...state, supercreators: action.payload };
    default:
      return { ...state };
  }
};
