/**
 * Contact Reducer
 */
//action types
import {
  GET_HOME_SCREEN_FEED,
  HOME_SCREEN_FEED_FETCH_SUCCESS,
  HOME_SCREEN_FEED_FETCH_FAILURE,
} from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";

const INITIAL_STATE = {
  categories: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOME_SCREEN_FEED:
      return { ...state };
    case HOME_SCREEN_FEED_FETCH_SUCCESS:
      return { ...state, categories: action.payload };
    case GET_HOME_SCREEN_FEED:
      return { ...state };
    default:
      return { ...state };
  }
};
