/**
 * News items Actions
 */
import { GET_NEWS_ITEMS } from "actions/Types";
import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
const airtable = new AirtableManager();
var _ = require("lodash/core");

/**
 * Fetch all news items
 */
export const getNewsItems = () => (dispatch) => {
  airtable.fetchNewsItems((items, error) => {
    if (items) {
      dispatch({
        type: GET_NEWS_ITEMS,
        payload: items,
      });
    } else {
      dispatch({
        type: GET_NEWS_ITEMS,
        payload: error,
      });
    }
  });
};
