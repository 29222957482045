import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { InspirationSource, InspirationSourceItem } from "kre8tv/model";
import {
  IInspirationSourceCallbackResult,
  IInspirationSourcesCallbackResult,
  IInspirationSourcesAndItemsCallbackResult,
} from "kre8tv/model/interfaces";

/**
 * Search for inspiration sources
 * @param query
 * @param platform
 * @returns
 */
export const fetchSourcesAndItems =
  async (): Promise<IInspirationSourcesAndItemsCallbackResult> => {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    try {
      let url = API_BASE_URL + `inspirationSources`;

      const headers = {
        Authorization: `Bearer ${token}`,
        platform: `web`,
      };

      const params = {
        action: "fetch",
      };

      const config: AxiosRequestConfig = {
        headers: headers,
        timeout: 10000,
        params: params,
      };

      const saveResult = await axios.get(url, config);

      console.log("save result = ", saveResult);

      const { data } = saveResult;
      const results = data.results.map(
        (result: any) => new InspirationSource(result)
      );

      const items = data.items.map(
        (result: any) => new InspirationSourceItem(result)
      );

      return { sources: results, items: items, error: undefined };
    } catch (error: any) {
      console.error("error submitInspirationSource= ", error);
      // throw new Error(error);
      return { sources: undefined, items: undefined, error: error.message };
    }
  };

/**
 * Search for inspiration sources
 * @param query
 * @param platform
 * @returns
 */
export const searchForAccounts = async (
  query: string,
  platform: string
): Promise<IInspirationSourcesCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `inspirationSources`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const params = {
      action: "search",
      query: query,
      platform: platform,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 10000,
      params: params,
    };

    const saveResult = await axios.get(url, config);

    console.log("save result = ", saveResult);

    const { data } = saveResult;
    const results = data.results.map(
      (result: any) => new InspirationSource(result)
    );

    return { sources: results, error: undefined };
  } catch (error: any) {
    console.error("error submitInspirationSource= ", error);
    // throw new Error(error);
    return { sources: undefined, error: error.message };
  }
};

/**
 * Add an inspiration source to the user
 * @param sourceName The name of the source (i.e Cristiano for example)
 * @param sourceUrl The source url (i.e instagram.com/cristiano)
 * @param platform The platform (i.e instagram)
 * @returns
 */
export const addInspirationSource = async (
  username: string,
  displayName: string,
  publicUrl: string,
  platform: string,
  platform_user_id?: string
): Promise<IInspirationSourceCallbackResult> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `inspirationSources`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "add",
      platform: platform,
      username: username,
      display_name: displayName,
      url: publicUrl,
      platform_user_id: platform_user_id,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const saveResult = await axios.post(url, payload, config);

    console.log("save result = ", saveResult);

    const { data } = saveResult;

    return { source: new InspirationSource(data), error: undefined };
  } catch (error: any) {
    console.error("error add inspiration source= ", error);
    // throw new Error(error);
    return { source: undefined, error: error.message };
  }
};

/**
 * Updates the inspiration source
 * @param sourceId The id of the source
 * @param remove True if to remove, false if to bring back
 * @returns
 */
export const updateInspirationSource = async (
  sourceId: string,
  remove: boolean
): Promise<boolean> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `inspirationSources`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const payload = {
      action: "update",
      source_id: sourceId,
      remove: remove,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const saveResult = await axios.post(url, payload, config);

    return true;
  } catch (error: any) {
    console.error("error update inspiration source= ", error);
    // throw new Error(error);
    return false;
  }
};
