export class ContentAsset {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * The user that uploaded it
   */
  user_id?: string;
  /**
   * PREVIEW URL - The publicly-accessible direct URL for this asset to PREVIEW
   */
  url: string;
  /**
   * DOWNLOAD URL - The publicly-accessible direct URL to DOWNLOAD this file
   */
  source_url: string;
  /**
   * The size of this GIF in bytes.
   */
  size: string;
  /**
   * gif | image | video | audio
   */
  type: string;

  /**
   * gif | image | video | audio
   */
  layer: "foreground" | "background" | "audio" | "none";

  /**
   * The scene id this is related to
   */
  scene_id?: string;

  /**
   * The phrase of words this is related to
   */
  phrase?: string;

  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";

  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;

  /**
   * Created on date
   */
  user_uploaded: boolean = false;
  /**
   * The dimension type of this asset
   */
  dimension_type: "square" | "portrait" | "landscape";

  /**
   * Optional thumbnail URL for preview
   */
  thumbnail_url?: string;

  constructor(
    id: string,
    type: string,
    url: string,
    size: string,
    source_url: string,
    scene_id: string,
    phrase: string,
    user_id: string,
    user_uploaded: boolean = false,
    dimension_type: "square" | "portrait" | "landscape" = "portrait",
    thumbnail_url?: string
  ) {
    this.id = id;
    this.url = url;
    this.size = size;
    this.type = type;
    this.source_url = source_url;
    this.scene_id = scene_id;
    this.phrase = phrase;
    this.user_id = user_id;
    this.layer = "none";
    this.user_uploaded = user_uploaded;
    this.dimension_type = dimension_type;
    this.thumbnail_url = thumbnail_url;
  }

  static fromJSON(json: any) {
    let asset = new ContentAsset(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      "square"
    );
    try {
      if (!json.id) throw new Error("No id found");
      if (!json.source_url) throw new Error("No source_url found");
      asset.id = json.id;
      asset.url = json.url;
      asset.size = json.size;
      asset.type = json.type;
      asset.source_url = json.source_url;
      asset.scene_id = json.scene_id;
      asset.phrase = json.phrase;
      asset.user_id = json.user_id;
      asset.layer = json.layer;
      asset.user_uploaded = json.user_uploaded ?? false;
      asset.dimension_type = json.dimension_type ?? asset.dimension_type;
      asset.thumbnail_url = json.thumbnail_url;
      return asset;
    } catch (error) {
      return null;
    }
  }

  toJSON(): any {
    var json: any = {};

    json["id"] = this.id;
    json["user_id"] = this.user_id;
    json["created_on"] = this.created_on;
    json["last_updated"] = this.last_updated;
    json["url"] = this.url;
    json["source_url"] = this.source_url;
    json["type"] = this.type;
    json["dimension_type"] = this.dimension_type;
    json["layer"] = this.layer;
    json["user_uploaded"] = this.user_uploaded;
    json["phrase"] = this.phrase;
    json["thumbnail_url"] = this.thumbnail_url;

    return json;
  }
}
