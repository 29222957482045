import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonSpecial: {
    height: "52px",
    borderRadius: "20px",
    padding: theme.spacing(0, 2), // Adjust the padding as needed
    boxShadow: "none",
    color: "#fff",
    fontWeight: "bold",
    //remove box shadow on hover
    // "&:hover": {
    //   boxShadow: "none",
    // },
  },
}));

const SCButton = ({ className, showLoading, ...props }) => {
  const classes = useStyles();

  // console.log("className", className);

  return (
    <Button className={`${classes.buttonSpecial} ${className}`} {...props}>
      {showLoading ? (
        <CircularProgress size={20} color="inherit" thickness={5} />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default SCButton;
