/**
 *  App.js :: contains main layout of APP.
 */
import { CssBaseline } from "@mui/material";
import { analytics } from "analytics/analytics";
import { Component, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import HulkThemeProvider from "./HulkThemeProvider";
// APP signin and signup imports
import AppLogout from "./Logout/Logout";
// import AppSignin from "./Signin/SigninFirebase";

//Affiliates
import Affiliates from "./Affiliates";

// deafult layout
import DefaultLayout from "./DefaultLayout";

// async components
import {
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  AsyncPreviewComponent,
} from "routes";

import Auth from "../Auth/Auth";

// callback component
import Callback from "components/Callback/Callback";
import ConfirmEmail from "./ConfirmEmailNew";

//intercom
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE } from "constants/app.consts";
import { IntercomProvider } from "react-use-intercom";
import OnlyiOSScreen from "routes/NewHome/OnlyiOSScreen";
import DeleteAccount from "./DeleteAccount";

//Auth0 Handle Authentication
const auth = new Auth();
const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};
// /**
//  * Initial Path To Check Whether User Is Logged In Or Not
//  */
// const InitialPath = ({ component: Component, authUser, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       authUser ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/signin",
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );

/**
 * Initial Path - Always show home
 */
const InitialPath = ({ component: Component, authUser, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const RedirectComponent = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, []);

  return null; // or you can render a loading indicator or a message while redirecting
};

class App extends Component {
  constructor(props) {
    super(props);
    // initialize intercom (don't forget to *update* when page changes)
    // window.intercomSettings = {
    //   app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    // };
  }

  componentDidMount() {
    //nop - do stuff here in the future
  }

  render() {
    const { location, match, user } = this.props;

    // console.log("🚨 re render Container/App.js");
    // console.log("🚨 re render app container");

    // log referral from query string
    logReferralFromQueryString(location);

    if (location.pathname === "/") {
      if (user === null) {
        // return <Redirect to="/signin" />;
        return <Redirect to="/home" />;
      } else {
        return <Redirect to="/home" />;
      }
    }

    // //if url has a redirect= then redirect them to the correct URL
    // if (location.search.includes("redirect=")) {
    //   const redirect = new URLSearchParams(location.search).get("redirect");
    //   let path = redirect.replace(window.location.origin, "");
    //   return <Redirect to={path} />;
    // }

    //if we have delete_account=true then redirect them to the correct URL
    if (location.search.includes("delete_account=true")) {
      return <Redirect to="/deleteAccount" />;
    }

    //check if url is /review_access
    if (location.pathname.includes("review_access")) {
      const invitation_id = new URLSearchParams(location.search).get(
        "invitation_id"
      );
      const resource_id = new URLSearchParams(location.search).get(
        "resource_id"
      );
      const scope = new URLSearchParams(location.search).get("scope");
      return (
        <Redirect
          to={`/home?review_access=true&invitation_id=${invitation_id}&resource_id=${resource_id}&scope=${scope}`}
        />
      );
    }

    //OLD if url has superscript?id= then redirect them to the correct URL
    if (
      location.pathname.includes("superscript") &&
      location.search.includes("id=")
    ) {
      logRedirectsFromQueryString(location);
      //use query string to get the id
      const id = new URLSearchParams(location.search).get("id");
      //add preview=true so we can log that this is a shared script
      return <Redirect to={`/home?story_id=${id}&preview=true`} />;
    }

    //NEW if url has story?id= then redirect them to the correct URL
    if (
      location.pathname.includes("story") &&
      location.search.includes("id=")
    ) {
      logRedirectsFromQueryString(location);
      //use query string to get the id
      const id = new URLSearchParams(location.search).get("id");
      //add preview=true so we can log that this is a shared script
      return <Redirect to={`/home?story_id=${id}&preview=true`} />;
    }

    //if url has invitation?id= then redirect them to the correct URL
    if (
      location.pathname.includes("invitation") &&
      location.search.includes("id=") &&
      location.search.includes("review_access") === false
    ) {
      const id = new URLSearchParams(location.search).get("id");
      const resource_id = new URLSearchParams(location.search).get(
        "resource_id"
      );
      const scope = new URLSearchParams(location.search).get("scope");
      return (
        <Redirect
          to={`/home?review_invite=true&invitation_id=${id}&resource_id=${resource_id}&scope=${scope}`}
        />
      );
    }

    //if url is terms-of-service then redirect them to the correct URL
    if (location.pathname.includes("terms-of-service")) {
      return <RedirectComponent url={TERMS_OF_SERVICE} />;
    }

    //if url is privacy-policy then redirect them to the correct URL
    if (location.pathname.includes("privacy-policy")) {
      return <RedirectComponent url={PRIVACY_POLICY_URL} />;
    }

    if (location.pathname.includes("superpostDetail")) {
      //get current url
      const url = window.location.href;
      return <RedirectComponent url={`/appOnly?source_url=${url}`} />;
    }

    // Handle createScript?url= redirect
    if (
      location.pathname.includes("createScript") &&
      location.search.includes("url=")
    ) {
      const sourceUrl = new URLSearchParams(location.search).get("url");
      if (sourceUrl) {
        return (
          <Redirect
            to={`/home?create_from_url=${encodeURIComponent(sourceUrl)}`}
          />
        );
      }
    }

    // Handle "earlyBird" query param
    if (location.pathname.startsWith("/earlyBird")) {
      return <Redirect to={`/home?earlyBird=true&show_pricing&show_auth`} />;
    }

    // const onHide = () => console.log("Intercom did hide the Messenger");
    // const onShow = () => console.log("Intercom did show the Messenger");

    const onHide = () => {};
    const onShow = () => {};

    const onUnreadCountChange = (amount) => {
      // console.log("Intercom has a new unread message");
      // console.log("onUnreadCountChange:", amount);
    };

    //if like is /invite then should not autoBoot intercom
    // const isInvite = location.pathname.includes("/invite");
    // const autoBoot = !isInvite;
    const autoBoot = true;

    return (
      <IntercomProvider
        onUnreadCountChange={onUnreadCountChange}
        onHide={onHide}
        onShow={onShow}
        initializeDelay={0}
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        autoBoot={autoBoot}
        options={{ hide_default_launcher: true }}
        autoBootProps={{ hideDefaultLauncher: true }}
      >
        <HulkThemeProvider>
          <CssBaseline />
          <NotificationContainer />
          {/* This InitialPath in charge for displaying the default layout with the side bars */}
          <InitialPath
            path={`${match.url}`}
            authUser={user}
            component={DefaultLayout}
          />

          <Route path="/preview" component={AsyncPreviewComponent} />
          <Route path="/invite" component={Affiliates} />
          <Route path="/logout" component={AppLogout} />
          <Route path="/confirm-email" component={ConfirmEmail} />
          <Route path="/error/404" component={AsyncErrorPage404Component} />
          <Route path="/error/500" component={AsyncErrorPage500Component} />
          <Route path="/deleteAccount" component={DeleteAccount} />
          <Route path="/appOnly" component={OnlyiOSScreen} />
          <Route
            path="/callback"
            render={(props) => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          {/* Not used components */}
          {/* <Route path="/signin" component={AppSignin} /> */}
          {/* <Route path="/signup" component={AppSignup} /> */}
          {/* <Route path="/forgot-password" component={AppForgotPassword} /> */}
          {/* <Route path="/login" component={Login} /> */}
          {/* <Route path="/register" component={Register} /> */}
          {/* <Route path="/forgot-password2" component={ForgotPassword2} /> */}
          {/* <Route path="/demos" component={AsyncDemosComponent} /> */}
        </HulkThemeProvider>
      </IntercomProvider>
    );
  }
}

const logRedirectsFromQueryString = (location) => {
  try {
    const urlParams = new URLSearchParams(location.search);
    const fromGPTApp = urlParams.get("from_gpt");
    const scriptId = urlParams.get("script_id") ?? urlParams.get("story_id");
    if (fromGPTApp) {
      let properties = {};
      properties["from_gpt"] = fromGPTApp;
      if (scriptId) {
        properties["script_id"] = scriptId;
      }
      analytics.logEvent("web - flow - new home - from gpt app", properties);
    }
  } catch (error) {
    console.error(error);
  }
};

// log referral from query string if present
const logReferralFromQueryString = (location) => {
  try {
    const urlParams = new URLSearchParams(location.search);
    const referral = urlParams.get("ref");
    if (referral) {
      analytics.logEvent("web - flow - new home - referral", {
        referral: referral,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

//map state to props
const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(App);
