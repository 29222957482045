/**
 * Chat App Actions
 */
import {
  GET_HOME_SCREEN_FEED,
  HOME_SCREEN_FEED_FETCH_SUCCESS,
  HOME_SCREEN_FEED_FETCH_FAILURE,
} from "actions/Types";

import { AirtableManager } from "kre8tv/managers/VLAirtableManager";
import { NotificationManager } from "react-notifications";

const airtable = new AirtableManager();

var _ = require("lodash/core");

/**
 * Fetch home screen feed
 */
export const getHomeScreenFeed = () => (dispatch) => {
  dispatch({ type: GET_HOME_SCREEN_FEED }); //show loading
  //1. Fetch all categories

  let categoryMap = {};

  airtable.fetchHomescreenCategories((categories, error) => {
    if (categories) {
      //now fetch all creators
      airtable.fetchCreators((creators, error) => {
        if (creators) {
          _.forEach(categories, (cat) => {
            categoryMap[cat.id] = cat;
          });

          //Here we attach creators to the specific sections
          _.forEach(creators, (creator) => {
            _.forEach(creator.categories, (cat) => {
              if (!categoryMap[cat]) {
                // nop;
              } else {
                try {
                  categoryMap[cat].creators.push(creator.toContactGridItem());
                } catch (e) {
                  //nop

                  console.error("parse get home screen error:", e);
                }
              }
            });
          });

          //filter out any empty categories
          const filtered = _.filter(
            categories,
            (cat) => cat.creators.length > 0
          );

          dispatch({
            type: HOME_SCREEN_FEED_FETCH_SUCCESS,
            payload: filtered,
          });
        } else {
          dispatch({ type: HOME_SCREEN_FEED_FETCH_FAILURE, payload: error });
          NotificationManager.error(error.message);
        }
      });
    } else {
      dispatch({ type: HOME_SCREEN_FEED_FETCH_FAILURE, payload: error });
      NotificationManager.error(error.message);
    }
  });
};
