import * as FullStory from "@fullstory/browser";

const TAG = "FullStory: ";

export class FullStoryRecorder {
  init(isProduction) {
    FullStory.init({
      orgId: "W4HQD",
      devMode: !isProduction,
    });
  }

  event(name, properties = []) {
    let props = {};

    if (properties instanceof Array)
      properties.map((property) => {
        for (let key in property) props[key] = property[key];
      });

    // console.log(TAG, "Sending [ ", name, " ] to FullStory");
    FullStory.event(name, props);
  }

  logout() {
    // console.log(TAG, "Make user anonymous.");
    FullStory.anonymize();
  }

  setUserId = (userId) => {
    FullStory.identify(userId);
    FullStory.setUserVars({ userId: userId });
  };
  setFullName = (fullName) => FullStory.setUserVars({ fullName: fullName });
  setFirstName = (firstName) => FullStory.setUserVars({ firstName: firstName });
  setLastName = (lastName) => FullStory.setUserVars({ lastName: lastName });
  setEmail = (email) => FullStory.setUserVars({ email: email });
}
