import firebase from "firebase";
import { Superscript } from "kre8tv/model/super-script.model";
import { Recipe } from "kre8tv/model/VLRecipe";
import { saveOrUpdateSuperScript } from "services/superscripts.service";
import { cleanObject } from "util/SCUtils";
import { DB_SCRIPT_SCENES, DB_SUPERSCRIPTS } from "./DBConstants";
import { sortBy } from "lodash";
import { ScriptScene } from "kre8tv/model/script-scene.model";
import { store } from "store";

export class ScriptSceneManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  duplicateScene(scene: ScriptScene) {
    const db = this.firestore.collection(DB_SCRIPT_SCENES);
    let newDoc = db.doc();
    let id = newDoc.id;
    let duplicated = new ScriptScene(JSON.parse(JSON.stringify(scene)));
    duplicated.id = id;
    return duplicated;
  }

  uniqueSceneId() {
    return this.firestore.collection(DB_SCRIPT_SCENES).doc().id;
  }

  /**
   *
   * @param script
   */
  async fetchScript(scriptId: string) {
    const db = this.firestore.collection(DB_SUPERSCRIPTS);
    try {
      const get = await db.doc(scriptId).get();
      const entity = get.exists ? get.data() : undefined;
      return new Superscript(entity);
    } catch (error) {
      console.log("Error getting script: ", error);
      return null;
    }
  }

  /**
   * Fetches all of the scenes for the given script
   * @param script The script to fetch scenes for
   */
  async fetchScenesForScript(script: Superscript) {
    const db = this.firestore.collection(DB_SCRIPT_SCENES);
    let id = script.id;

    try {
      let scenes: ScriptScene[] = [];
      const snapshot = await db.where("script_id", "==", id).get();
      snapshot.forEach((doc) => {
        let event = new ScriptScene(doc.data());
        if (event.id) scenes.push(event);
      });

      //remove deleted version
      scenes = scenes.filter((s) => s.is_deleted !== true);
      //sort by index
      scenes = sortBy(scenes, "scene_index");

      return scenes;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  }

  /**
   * Delete scene
   */
  async deleteScene(scene: ScriptScene) {
    //make sure to update properties

    scene.is_deleted = true;
    let cleaned = cleanObject(scene.jsonify());
    console.log("attempting delete scene - ", cleaned);
    let doc;

    //new scene, no existing id (add one)
    if (!scene.id) {
      return scene;
    } else {
      //existing id - update the document
      doc = this.firestore.collection(DB_SCRIPT_SCENES).doc(scene.id);
    }
    try {
      const update = await doc.set({ ...cleaned }, { merge: true });
      return scene;
    } catch (error) {
      console.error("delete scene error - ", error);
      return false;
    }
  }
}
