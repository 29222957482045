import JSZip from "jszip";
import saveAs from "jszip/vendor/FileSaver.js";
import { getUrlExtension } from "../../util/Primitives.util";

class DownloadManager {
  constructor() {
    this.onDownloadComplete = this.onDownloadComplete.bind(this);
  }

  downloadSingleFile(fileUrl, fileName, callback) {
    window.open(fileUrl, "_blank");
  }

  /**
   * Download the files
   * @param {*} fileURLs Array of urls
   * @param {*} onProgressUpdated Callback for each url progress
   * @param {*} onAllDownloadsCompleted Final callback when all files have been downloaded
   */
  downloadFiles(
    fileURLs,
    fileNames,
    onProgressUpdated,
    onAllDownloadsCompleted
  ) {
    let count = 0;
    let zip = new JSZip();
    const query = { fileURLs, fileNames, count, zip };
    this.downloadFile(
      query,
      this.onDownloadComplete,
      onProgressUpdated,
      onAllDownloadsCompleted
    );
  }

  /**
   * Prepare the internal xml http request to download the files
   * @param {*} query
   * @param {*} onDownloadComplete
   */
  downloadFile(
    query,
    onDownloadComplete,
    onProgressUpdated,
    onAllDownloadsCompleted
  ) {
    const { fileURLs, fileNames, count } = query;
    var xhr = new XMLHttpRequest();
    /**
     * Progress listener
     * @param {*} event
     */
    xhr.onprogress = (event) => {
      this.calculateAndUpdateProgress(
        event,
        onProgressUpdated,
        fileURLs[count]
      );
    };
    xhr.open("GET", fileURLs[count], true);
    xhr.responseType = "blob";
    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4) {
        if (onDownloadComplete)
          onDownloadComplete(
            query,
            fileNames[count],
            xhr.response,
            onProgressUpdated,
            onAllDownloadsCompleted
          );
      }
    };
    xhr.send();
  }
  /**
   * On download complete - downlodas each file one at a time, then completed saveas when all files are downloaded
   * @param {*} query
   * @param {*} blobData
   */
  onDownloadComplete(
    query,
    fileName,
    blobData,
    onProgressUpdated,
    onAllDownloadsCompleted
  ) {
    let { fileURLs, count, zip } = query;
    let file_prefix = Math.round(Date.now() / 1000);
    if (count < fileURLs.length) {
      const _this = this;

      this.blobToBase64(blobData, function (binaryData) {
        // add downloaded file to zip:
        const full_url = fileURLs[count];
        const extension = getUrlExtension(full_url);

        // convert the source file name to the file name to display
        var displayFileName = `${fileName}.${extension}`;

        zip.file(displayFileName, binaryData, { base64: true });
        if (count < fileURLs.length - 1) {
          count++;
          _this.downloadFile(
            { ...query, count },
            _this.onDownloadComplete,
            onProgressUpdated,
            onAllDownloadsCompleted
          );
        } else {
          // all files have been downloaded, create the zip
          zip.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            saveAs(content, `${file_prefix}.zip`);
            onAllDownloadsCompleted && onAllDownloadsCompleted(true);
          });
        }
      });
    }
  }
  /**
   * Convert blob to base 64 string that can be used to zip
   * @param {*} blob
   * @param {*} callback
   */
  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      var base64 = dataUrl.split(",")[1];
      callback(base64);
    };
    reader.readAsDataURL(blob);
  }
  /**
   * Calculate the progress of the download?
   * @param {*} evt
   */
  calculateAndUpdateProgress(evt, onProgressUpdated, fileUrl) {
    if (evt.lengthComputable) {
      console.log("evt: ", evt);
      let downloadPercent = evt.loaded / evt.total;
      const percent = parseInt((downloadPercent * 100).toFixed(0), 10);
      downloadPercent = percent + "%";
      console.log("download progress: ", downloadPercent);
      onProgressUpdated && onProgressUpdated(percent, fileUrl);
    }
  }
}

var downloadManager = new DownloadManager();
export default downloadManager;
