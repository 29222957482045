import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import {
  ContentAsset,
  Organization,
  ResourcePermissionInvitation,
  ResourcePermissionScope,
  TextToImageModel,
  User,
  VLError,
  parseErrorMessage,
} from "kre8tv/model";

export class UserToResourcePermissionScope {
  user: User;
  resource_permission_scope: ResourcePermissionScope;

  constructor(user: User, resource_permission_scope: ResourcePermissionScope) {
    this.user = user;
    this.resource_permission_scope = resource_permission_scope;
  }
}

export const getUserHomePermissions = async (): Promise<{
  resource_permission_scopes: ResourcePermissionScope[];
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const user_id = firebase.auth().currentUser?.uid;
    if (!user_id) {
      throw new Error("You must be logged in to update your profile");
    }

    let url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: { action: "get_user_resource_permission_scopes" },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getUserHomePermissions result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { resource_permission_scopes } = result;

    return {
      resource_permission_scopes:
        resource_permission_scopes &&
        resource_permission_scopes.map(
          (json: any) => new ResourcePermissionScope(json)
        ),
    };
  } catch (error: any) {
    console.error("error getUserHomePermissions= ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const createUserPermissionScope = async (
  for_user_id: string,
  resource_id: string,
  scope: string,
  permission: ResourcePermission
): Promise<{ resource_permission_scope: ResourcePermissionScope }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "create_organization",
      for_user_id: for_user_id,
      resource_permission_scope: {
        resource_id: resource_id,
        permission: permission,
        scope: scope,
      },
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("createOrganization fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const updatedResourcePermissionScope = new ResourcePermissionScope(
      result.resource_permission_scope
    );
    if (!updatedResourcePermissionScope || !updatedResourcePermissionScope.id) {
      throw new Error("error creating organization");
    }

    return { resource_permission_scope: updatedResourcePermissionScope };
  } catch (error: any) {
    console.error("error creating organization= ", error);
    throw error;
  }
};

/**
 * Saves a user asset to the server
 * @param asset The asset to save
 * @returns The saved asset
 */
export const updateUserPermissionScope = async (
  permission_scope_id: string,
  permission: ResourcePermission
): Promise<{ resource_permission_scope: ResourcePermissionScope }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "update_resource_permission_scope",
      resource_permission_scope: {
        id: permission_scope_id,
        permission: permission,
      },
    };

    // console.log("updateUserPermissionScope params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("updateUserPermissionScope fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const updatedResourcePermissionScope = new ResourcePermissionScope(
      result.resource_permission_scope
    );
    if (!updatedResourcePermissionScope || !updatedResourcePermissionScope.id) {
      throw new Error("error updating permission");
    }

    return { resource_permission_scope: updatedResourcePermissionScope };
  } catch (error: any) {
    console.error("error updating permission= ", error);
    throw error;
  }
};

/**
 * Gets all the user permission scopes for a given organization
 * @param organization_id The organization id
 * @returns All the user permission scopes for a given organization
 */
export const getAllOrganizationUserPermissionScopes = async (
  organization_id: string
): Promise<{
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  return await getUserToResourcePermissionScope(organization_id, "team");
};

export const getAllFolderUserPermissionScopes = async (
  folder_id: string
): Promise<{
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  return await getUserToResourcePermissionScope(folder_id, "folder");
};

export const getAllScriptUserPermissionScopes = async (
  script_id: string
): Promise<{
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  return await getUserToResourcePermissionScope(script_id, "script");
};

const getUserToResourcePermissionScope = async (
  resource_id: string,
  scope: ResourceScope
): Promise<{
  user_resource_permission_scopes: UserToResourcePermissionScope[];
  invitations: ResourcePermissionInvitation[];
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  let url = API_BASE_URL + `resourcePermissionScopes`;

  const headers = {
    Authorization: `Bearer ${token}`,
    platform: `web`,
    "Cache-Control": "no-cache", // Add cache control headers to disable caching
  };

  const config: AxiosRequestConfig = {
    headers: headers,
    timeout: 20000,
    params: {
      action: "get_user_to_resource_permission_scopes",
      resource_id: resource_id,
      scope: scope,
    },
  };

  try {
    const fetchResult = await axios.get(url, config);

    // console.log("getUserToResourcePermissionScope fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { user_resource_permission_scopes } = result;

    let userToResourcePermissionScope: UserToResourcePermissionScope[] = [];
    //attempt to parse each user and permission scope in the array
    user_resource_permission_scopes.forEach((item: any) => {
      const { user, resource_permission_scope } = item;
      const parsedUser = new User(user);
      const parsedResourcePermissionScope = new ResourcePermissionScope(
        resource_permission_scope
      );
      if (
        parsedUser.id.length > 0 &&
        parsedResourcePermissionScope.id.length > 0
      ) {
        userToResourcePermissionScope.push(
          new UserToResourcePermissionScope(
            parsedUser,
            parsedResourcePermissionScope
          )
        );
      }
    });

    // console.log(
    //   "userToResourcePermissionScope= ",
    //   userToResourcePermissionScope
    // );

    const invitations =
      result.invitations &&
      result.invitations.map(
        (invitation: any) => new ResourcePermissionInvitation(invitation)
      );

    //sort userToResourcePermissionScope by the created_on of the resource_permission_scope
    userToResourcePermissionScope.sort((a, b) => {
      return (
        a.resource_permission_scope.created_on -
        b.resource_permission_scope.created_on
      );
    });

    //sort invitations by the created_on of the invitation
    invitations.sort(
      (a: ResourcePermissionInvitation, b: ResourcePermissionInvitation) => {
        return a.created_on - b.created_on;
      }
    );

    return {
      user_resource_permission_scopes: userToResourcePermissionScope,
      invitations,
    };
  } catch (error: any) {
    console.error("error fetching user permission scopes config= ", config);
    console.error("error fetching user permission scopes error= ", error);
    throw error;
  }
};

export const sendInvitation = async (
  email: string,
  resource_id: string,
  scope: ResourceScope,
  permission: ResourcePermission,
  for_user_id?: string
): Promise<{ success: boolean; invitation: ResourcePermissionInvitation }> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    //construct url
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "send_invitation",
      email: email,
      resource_id: resource_id,
      scope: scope,
      permission: permission,
      for_user_id: for_user_id,
    };

    // console.log("send invitation params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("send invitation fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error sending invitation");
    }

    const { invitation } = result;

    return {
      success: true,
      invitation: new ResourcePermissionInvitation(invitation),
    };
  } catch (error: any) {
    console.error("error sending invitation= ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const sendInvitationReminder = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "send_invitation_reminder",
      resource_id: resource_id,
      scope: scope,
      invitation_id: invitation_id,
    };

    // console.log("send invitation params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("send invitation fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error sending reminder");
    }

    return { success: true };
  } catch (error: any) {
    console.error("error sending reminder= ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const acceptInvitation = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope,
  permission: ResourcePermission,
  is_approval = false
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: is_approval ? "approve_invitation_request" : "accept_invitation",
      invitation_id: invitation_id,
      resource_id: resource_id,
      scope: scope,
      permission: permission,
    };

    // console.log("accept invitation params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("accept invitation fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error accepting invitation");
    }

    return { success: true };
  } catch (error: any) {
    console.error("error accepting invitation= ", error);
    throw error;
  }
};

export const declineInvitation = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope,
  permission: ResourcePermission
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "decline_invitation",
      invitation_id: invitation_id,
      resource_id: resource_id,
      scope: scope,
      permission: permission,
    };

    // console.log("decline invitation params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("decline invitation fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error declineing invitation");
    }

    return { success: true };
  } catch (error: any) {
    console.error("error declineing invitation= ", error);
    throw error;
  }
};

export const getInvitationInfo = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope
): Promise<ResourcePermissionInvitation> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
      params: {
        action: "get_invitation",
        invitation_id: invitation_id,
        resource_id: resource_id,
        scope: scope,
      },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getInvitationInfo fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { invitation } = result;

    return invitation;
  } catch (error: any) {
    console.error("error fetching invitation info= ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const getAccessRequestDetails = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope
): Promise<{ invitation: ResourcePermissionInvitation; user: User }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
      params: {
        action: "get_access_request_details",
        invitation_id: invitation_id,
        resource_id: resource_id,
        scope: scope,
      },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("getAccessRequestDetails fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { invitation, user } = result;

    //convert json to objects
    const rpi = new ResourcePermissionInvitation(invitation);
    const u = new User(user);

    return { invitation: rpi, user: u };
  } catch (error: any) {
    console.error("error fetching getAccessRequestDetails= ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const sendRequestAccessToResource = async (
  resource_id: string,
  scope: ResourceScope
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "request_access",
      resource_id: resource_id,
      scope: scope,
    };

    // console.log("request access params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("request access fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error requesting access");
    }

    return { success: true };
  } catch (error: any) {
    console.error("error requesting access = ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

/**
 * Revoke/aka remove an invitation to a resource
 * @param invitation_id The invitation id
 * @param resource_id The resource id of the invitation
 * @param scope Scope of the invitation
 * @returns Success or throws an error
 */
export const revokeInvitationToResource = async (
  invitation_id: string,
  resource_id: string,
  scope: ResourceScope
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };
    let params = {
      action: "remove_invitation",
      invitation_id: invitation_id,
      resource_id: resource_id,
      scope: scope,
    };
    // console.log("revoke invitation params= ", params);
    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };
    const fetchResult = await axios.post(url, params, config);

    // console.log("revoke invitation fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error revoking invitation");
    }
    return { success: true };
  } catch (error: any) {
    console.error("error revoking invitation = ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

/**
 * Revoke/aka remove an invitation to a resource
 * @param invitation_id The invitation id
 * @param resource_id The resource id of the invitation
 * @param scope Scope of the invitation
 * @returns Success or throws an error
 */
export const removeResourceAccess = async (
  resource_permission_scope_id: string
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };
    let params = {
      action: "delete_resource_permission_scope",
      resource_permission_scope: { id: resource_permission_scope_id },
    };
    // console.log("revoke resource permission scope params= ", params);
    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };
    const fetchResult = await axios.post(url, params, config);

    // console.log("removeResourceAccess fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { resource_permission_scope } = result;
    if (!resource_permission_scope) {
      throw new Error(result.error.message ?? "Error revoking access");
    }
    return { success: true };
  } catch (error: any) {
    console.error("error revoking resource permission scope = ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const isUserInTeam = async (
  user_id: string,
  team_id: string
): Promise<boolean> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };
    let params = {
      action: "is_user_in_team",
      user_id: user_id,
      team_id: team_id,
    };
    // console.log("isUserInTeam params= ", params);
    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };
    const fetchResult = await axios.get(url, { ...config, params });

    // console.log("isUserInTeam fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { is_user_in_team } = result;
    return is_user_in_team;
  } catch (error: any) {
    console.error("error checking if user is on team = ", error);
    throw error;
  }
};

export const sendRequestAccessToJoinTeam = async (
  team_leader_email: string
): Promise<{
  success: boolean;
  invitation: ResourcePermissionInvitation | null;
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "request_access_to_join_team",
      team_leader_email: team_leader_email,
    };

    // console.log("request access params= ", params);

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("request access fetchResult= ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const { success } = result;
    if (!success) {
      throw new Error(result.error.message ?? "Error requesting access");
    }

    const invitation = new ResourcePermissionInvitation(result.invitation);

    return { success: true, invitation: invitation.id ? invitation : null };
  } catch (error: any) {
    console.error("error requesting access = ", error);
    let customMessage = parseErrorMessage(error);
    if (customMessage) {
      throw new Error(customMessage);
    } else {
      throw error;
    }
  }
};

export const leaveTeam = async (
  team_id: string
): Promise<{ success: boolean }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);
  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;
  try {
    const url = API_BASE_URL + `resourcePermissionScopes`;
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };
    let params = {
      action: "leave_team",
      team_id: team_id,
    };
    // console.log("leaveTeam params= ", params);
    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
    };
    const fetchResult = await axios.post(url, params, config);

    // console.log("leaveTeam fetchResult= ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { success } = result;
    return { success };
  } catch (error: any) {
    console.error("error leaving team = ", error);
    throw error;
  }
};
