import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import { ContentAsset, TextToImageModel, User } from "kre8tv/model";

export const getUserAssets = async (): Promise<{ assets: ContentAsset[] }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: { action: "get_users_assets" },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const { results } = data;
    const assets = results.assets;

    const allAssets: ContentAsset[] = [];
    assets.forEach((assetData: any) => {
      try {
        let asset = ContentAsset.fromJSON(assetData);
        if (asset) {
          allAssets.push(asset);
        }
      } catch (error) {
        console.error("error creating asset= ", error);
      }
    });

    return { assets: allAssets };
  } catch (error: any) {
    console.error("error fetching assets= ", error);
    throw new Error(error);
  }
};

export const fetchRemoteTextToImageModels = async (): Promise<{
  models: TextToImageModel[];
}> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    let url = API_BASE_URL + `superscripts`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: { action: "get_remote_text_to_image_models" },
    };

    const fetchResult = await axios.get(url, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const allModels: TextToImageModel[] = [];
    result.forEach((modelData: any) => {
      try {
        let model = TextToImageModel.fromJSON(modelData);
        allModels.push(model);
      } catch (error) {
        console.error("error creating text to image model= ", error);
      }
    });

    return { models: allModels };
  } catch (error: any) {
    console.error("error fetching text to image models= ", error);
    throw new Error(error);
  }
};

/**
 * Saves a user asset to the server
 * @param asset The asset to save
 * @returns The saved asset
 */
export const saveAsset = async (
  asset: ContentAsset
): Promise<{ asset: ContentAsset }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "upload_user_asset",
      asset: asset.toJSON(),
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
    };

    const fetchResult = await axios.post(url, params, config);

    // console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const savedAsset = ContentAsset.fromJSON(result);
    if (!savedAsset || !savedAsset.id) {
      throw new Error("Error saving asset");
    }

    return { asset: savedAsset };
  } catch (error: any) {
    console.error("content assets service: error saving asset= ", error);
    throw new Error(error);
  }
};

/**
 * Deletes a user asset from the server
 * @param asset The asset to delete
 * @returns The deleted asset
 */
export const deleteContentAsset = async (
  asset: ContentAsset
): Promise<{ asset: ContentAsset }> => {
  //grab token
  const token = await firebase.auth().currentUser?.getIdToken(true);

  //setup axios session with token
  axios.defaults.headers.common["Authorization"] = token;

  try {
    const url = API_BASE_URL + `users`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "delete_user_asset",
      id: asset.id,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
    };

    const fetchResult = await axios.post(url, params, config);

    console.log("fetch result = ", fetchResult);

    const { data } = fetchResult;

    const { result } = data;

    const success = result.success;
    if (!success) {
      throw new Error("error deleting asset");
    }

    return { asset: asset };
  } catch (error: any) {
    console.error("error deleting asset= ", error);
    throw new Error(error);
  }
};
