import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";
import firebase from "firebase";
import {
  ContentFormat,
  ResourceFolder,
  ShortFormVideo,
  SocialAccount,
  Superscript,
  User,
  parseVLError,
} from "kre8tv/model";
import {
  IUserCallbackResult,
  IUserFullProfileCallbackResult,
  IUserMainCallbackResult,
} from "kre8tv/model/interfaces/user-callback-result.interface";

/**
 * Fetches all videos from the current user
 * @returns Object containing scripts, videos, and formats created by the current user
 */
export const fetchScriptVideos = async (
  scriptId: string
): Promise<{ videos: ShortFormVideo[] }> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `shortFormVideos`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
      params: { action: "fetch_videos_from_script_id", id: scriptId },
    };

    const fetchResult = await axios.get(url, config);

    console.log("fetchScriptVideos result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;
    const { videos } = result;

    return {
      videos: videos.map((json: any) => new ShortFormVideo(json)),
    };
  } catch (error: any) {
    console.error("error fetchScriptVideos= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};

//create for above as POST
export const requestShortFormVideoReupload = async (
  shortFormVideoId: string
): Promise<ShortFormVideo> => {
  try {
    //grab token
    const token = await firebase.auth().currentUser?.getIdToken(true);

    //setup axios session with token
    axios.defaults.headers.common["Authorization"] = token;

    let url = API_BASE_URL + `shortFormVideos`;

    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 30000,
    };

    const body = {
      action: "request_reupload",
      id: shortFormVideoId,
    };

    const fetchResult = await axios.post(url, body, config);

    console.log("requestShortFormVideoReupload result = ", fetchResult);

    const { data } = fetchResult;
    const { result } = data;

    return new ShortFormVideo(result);
  } catch (error: any) {
    console.error("error requestShortFormVideoReupload= ", error);
    let vlError = parseVLError(error);
    if (vlError) {
      throw vlError;
    } else {
      throw error;
    }
  }
};
