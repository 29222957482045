import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "constants/app.consts";

/**
 * Records an affiliate link click
 * @param full_link The full link that was clicked
 * @param sc_ref The sc_ref of the user who clicked the link
 * @returns
 */
export const sendAffiliateLinkClick = async (
  full_link: string,
  sc_ref: string
): Promise<boolean> => {
  try {
    let url = API_BASE_URL + `users`;

    const headers = {
      platform: `web`,
    };

    const params = {
      action: "record_affiliate_link_click",
      full_link: full_link,
      sc_ref: sc_ref,
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 15000,
      params: params,
    };

    console.log("sendAffiliateLinkClick url = ", url);

    const fetchResult = await axios.get(url, config);

    console.log("sendAffiliateLinkClick result = ", fetchResult);

    const { data } = fetchResult;

    return data.success;
  } catch (error: any) {
    console.error("error sendAffiliateLinkClick= ", error);
    throw new Error(error);
  }
};

export const getReferralDashboard = async (
  token: string
): Promise<{
  user_info: {
    first_name: string;
    last_name: string;
    full_name: string;
    username: string;
    profile_picture_url: string;
    user_id: string;
    refer_other_users_code: string;
  };
  referrals_info: {
    total_referrals: number;
    total_referrals_earnings: number;
    referrals: any[];
  };
}> => {
  try {
    let url = API_BASE_URL + `users`;
    axios.defaults.headers.common["Authorization"] = token;
    const headers = {
      Authorization: `Bearer ${token}`,
      platform: `web`,
    };

    let params = {
      action: "get_referral_dashboard",
    };

    const config: AxiosRequestConfig = {
      headers: headers,
      timeout: 20000,
      params: params,
    };

    const fetchResult = await axios.get(url, config);

    console.log("getReferralDashboard result = ", fetchResult);

    const { data } = fetchResult;

    return data.results;
  } catch (error: any) {
    console.error("error getReferralDashboard= ", error);
    throw new Error(error);
  }
};
