/**
 * Abilities Reducer
 */
//action types
import { GET_RECOMMENDATIONS, FETCHED_RECOMMENDATIONS } from "actions/Types";

const INITIAL_STATE = {
  isFetchingRecommendations: false,
  recommendations: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RECOMMENDATIONS:
      //save to local store
      return {
        ...state,
        isFetchingRecommendations: true,
      };
    case FETCHED_RECOMMENDATIONS:
      //save to local store
      return {
        ...state,
        recommendations: action.payload.recommendations,
        isFetchingRecommendations: false,
      };
    default:
      return { ...state };
  }
};
