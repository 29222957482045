import { IEntity } from "./interfaces/entity.interface";

export class InspirationSource implements IEntity {
  id: string = "";
  user_id: string = ""; //who added it
  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  username: string = "";
  display_name: string = "";
  url?: string;
  platform: string = "";
  removed = false;
  platform_user_id?: string;
  quantifiers?: any;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    const {
      id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      user_id,
      username,
      display_name,
      url,
      platform,
      removed,
      platform_user_id,
      quantifiers,
    } = obj;

    this.id = id;
    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;

    this.user_id = user_id;
    this.username = username;
    this.display_name = display_name;
    this.url = url;
    this.platform = platform;
    this.removed = removed ?? false;
    this.platform_user_id = platform_user_id;
    try {
      this.quantifiers = quantifiers;
    } catch (e) {
      this.quantifiers = JSON.parse(obj.quantifiers);
    }
  }

  usernamePrefix(): string {
    const prefix =
      this.platform === "instagram" ||
      this.platform === "twitter" ||
      this.platform === "tiktok"
        ? "@"
        : "";
    return prefix;
  }

  quanitiferString(): string | undefined {
    if (!this.quantifiers) return undefined;
    const { followers, following, posts, subscribers, shorts, likes } =
      this.quantifiers;
    switch (this.platform) {
      case "instagram":
        return `Followers ${followers} • Following ${following} • Posts ${posts}`;
      case "twitter":
        return `Followers ${followers} • Following ${following} • Tweets ${posts}`;
      case "tiktok":
        return `Followers ${followers} • Following ${following} • Posts ${posts} • Hearts ${likes}`;
      default:
        return undefined;
    }
  }
}
