/**
 * Firebase details, you need to add your firebase project details here
 */
import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import { isProduction } from "util/SCUtils";

// Your web app's Firebase configuration
const firebaseConfigDebug = {
  apiKey: "AIzaSyB0jqa_KJofVQ6P9kpHmsDs8Gwrj-blrGQ",
  authDomain: "supercreator-ai-staging.firebaseapp.com",
  projectId: "supercreator-ai-staging",
  storageBucket: "supercreator-ai-staging.appspot.com",
  messagingSenderId: "553536732120",
  appId: "1:553536732120:web:ad55a1b6895133a20790ad",
  measurementId: "G-GH8W05MXBX",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyAmhGEO4vBdLbYe5JbpT0NXtZn5wrxTN14",
  authDomain: "supercreator-ai.firebaseapp.com",
  projectId: "supercreator-ai",
  storageBucket: "supercreator-ai.appspot.com",
  messagingSenderId: "840302524557",
  appId: "1:840302524557:web:4ba0e6ecbe1d4df0278ac4",
  measurementId: "G-1T6W63X17P",
};

// Initialize Firebase
firebase.initializeApp(
  isProduction() ? firebaseConfigProduction : firebaseConfigDebug
);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = firebase.firestore();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  database,
};
