import { IEntity } from "./interfaces";

export class ResourceFolder implements IEntity {
  id: string = "";
  user_id = "";

  created_on: number = 0;
  last_updated: number = 0;
  last_updated_by_user_id: string = "";

  name: string = "";
  is_deleted = false;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    const {
      id,
      user_id,
      created_on,
      last_updated,
      last_updated_by_user_id,
      name,
      is_deleted,
    } = obj;

    this.id = id;
    this.user_id = user_id;

    this.created_on = created_on;
    this.last_updated = last_updated;
    this.last_updated_by_user_id = last_updated_by_user_id;
    this.name = name ?? "";
    this.is_deleted = is_deleted ?? false;
  }
}
