import { objToJSON } from "util/SCUtils";
import { FormModelAnswer } from "./form-model-answer.model";

//add enum to SCFormDependencyRule
export enum SCFormDependencyRule {
  is_true = "is_true",
  has_value = "has_value",
}
//add enum to SCFormQuestionType
export enum SCFormQuestionType {
  single_choice = "single_choice",
  single_choice_image_button = "single_choice_image_button",
  multi_choice = "multi_choice",
  text_input_short = "text_input_short",
  text_input_long = "text_input_long",
  image_input = "image_input",
  video_input = "video_input",
  tab_selection = "tab_selection",
  drop_down = "drop_down",
  toggle = "toggle",
  button = "button",
}

export class FormModelQuestion {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * question title
   */
  question = "";
  /**
   * info
   */
  info = "";
  /**
   * is required
   */
  is_required = false;
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id = "";
  /**
   * question type
   *  @see SCFormQuestionType
   */
  question_type: SCFormQuestionType = SCFormQuestionType.single_choice;
  /**
   * Dependency on question id
   *
   */
  dependency_on_question_id?: string;
  /**
   * Dependency rule
   * @see SCFormDependencyRule
   */
  dependency_rule?: SCFormDependencyRule;

  /**
   * Last updated date
   */
  last_updated = 0;
  /**
   * Created on date
   */
  created_on = 0;

  /**
   * placeholder string
   */
  placeholder?: string;

  /**
   * Is video deleted
   */
  is_deleted = false;

  /**
   * Is video archived
   */
  archived?: boolean;

  //Main data values
  options: FormModelAnswer[] = [];
  examples: FormModelAnswer[] = [];
  answer: FormModelAnswer[] = [];

  // additional data
  hide_title_header?: boolean;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    //general
    this.id = obj.id;
    this.user_id = obj.user_id;
    this.last_updated_by_user_id = obj.last_updated_by_user_id;

    //details
    this.question = obj.question;
    this.info = obj.info;
    this.is_required = obj.is_required;
    this.question_type = obj.question_type;
    this.placeholder = obj.placeholder;

    //dependency
    this.dependency_on_question_id = obj.dependency_on_question_id;
    this.dependency_rule = obj.dependency_rule;

    //dates
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;

    //state
    this.is_deleted = obj.is_deleted ?? false;
    this.archived = obj.archived;

    //primary data
    this.options = obj.options?.map((o: any) => new FormModelAnswer(o)) ?? [];
    this.examples = obj.examples?.map((o: any) => new FormModelAnswer(o)) ?? [];
    this.answer = obj.answer?.map((o: any) => new FormModelAnswer(o)) ?? [];

    //if its single_choice or single_choice_image_button, and we have options - set the first option as the answer
    if (
      (this.question_type === SCFormQuestionType.single_choice ||
        this.question_type === SCFormQuestionType.single_choice_image_button) &&
      this.options.length > 0
    ) {
      this.answer = [this.options[0]];
    }

    //additional data
    this.hide_title_header = obj.hide_title_header;
  }

  toJson() {
    const result = objToJSON(this);
    return result;
  }

  updateAnswers(answers: FormModelAnswer[]) {
    this.answer = answers;
  }

  getAPIAnswer(): FormModelAnswer | null {
    //if no answer, return empty
    if (!this.answer || this.answer.length === 0) {
      return null;
    }
    //grab first answer
    const answer = this.answer[0];
    //for now, return first answer
    //if this type is of multi choice, get other answers and append to answer (string)
    if (this.question_type === SCFormQuestionType.multi_choice) {
      //get other answers
      const otherAnswers = this.answer
        .filter((a) => a.id !== answer.id)
        .map((a) => a.value);
      //append to answer
      answer.value = answer.value + ", " + otherAnswers.join(", ");
    } else if (this.question_type === SCFormQuestionType.toggle) {
      //if toggle, return true/false
      answer.value = answer.value === true ? "true" : "false";
    }

    return answer;
  }
}
