/**
 * Mission Reducer
 */
//action types
import { GET_MISSIONS } from "actions/Types";
import { Mission } from "kre8tv/model/VLMission";

const INITIAL_STATE = {
  missions: JSON.parse(localStorage.getItem("missions") ?? "[]").map((o) => {
    // console.log(o);
    return new Mission(new Map(Object.entries(o)));
  }),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MISSIONS:
      //save to local store
      localStorage.setItem("missions", JSON.stringify(action.payload));
      return { ...state, missions: action.payload };
    default:
      return { ...state };
  }
};
