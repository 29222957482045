import BaseTracker from "./base.tracker";

export class IntercomTracker extends BaseTracker {
  init() {
    return this;
  }

  /**
   * Tracks an event with a given name and properties.
   * @param {string} name - The name of the event.
   * @param {Object} properties - The properties of the event.
   * @returns {Promise} - A promise that resolves when the event is tracked.
   */
  event(name, properties = {}) {
    if (!this.isIntercomLoaded()) return Promise.reject("Intercom not loaded");
    return new Promise((resolve) => {
      resolve(window.Intercom("trackEvent", name, properties));
    });
  }

  /**
   * Sets the user ID.
   * @param {string} userId - The user ID.
   */
  setUserId = (userId) => {
    if (this.isIntercomLoaded())
      window.Intercom("update", { userId: userId, user_id: userId });
  };

  /**
   * Sets the full name of the user.
   * @param {string} value - The full name of the user.
   */
  setFullName = (value) => {
    if (this.isIntercomLoaded())
      window.Intercom("update", { name: value, fullName: value });
  };

  /**
   * Sets the first name of the user.
   * @param {string} value - The first name of the user.
   */
  setFirstName = (value) => {
    if (this.isIntercomLoaded())
      window.Intercom("update", { firstName: value });
  };

  /**
   * Sets the last name of the user.
   * @param {string} value - The last name of the user.
   */
  setLastName = (value) => {
    if (this.isIntercomLoaded()) window.Intercom("update", { lastName: value });
  };

  /**
   * Sets the email of the user.
   * @param {string} email - The email of the user.
   */
  setEmail = (email) => {
    if (this.isIntercomLoaded()) window.Intercom("update", { email: email });
  };

  /**
   * Sets a custom user attribute.
   * @param {string} name - The name of the attribute.
   * @param {string} value - The value of the attribute.
   */
  setCustomUserAttribute = (name, value) => {
    if (!this.isIntercomLoaded()) return;
    const payload = {};
    payload[name] = value;
    window.Intercom("update", payload);
  };

  isIntercomLoaded() {
    return window.Intercom;
  }
}
